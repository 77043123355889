import React from 'react';

interface CompanyInitialProps {
  name: string;
  id: number
  color: string;
  onClick?: any
}

const CompanyInitial = (props: CompanyInitialProps): JSX.Element => {
  const { id, color, onClick } = props
  let { name } = props
  name = name && name.trim()
  let initial = ''

  if(name) {
    if(name.length == 1) {
      initial = name.toUpperCase()
    }else{
      const splitted = name.split(" ")
      if (splitted.length == 1) {
        initial = name[0].toUpperCase() + name[1].toUpperCase()
      } else {
        initial = splitted[0][0].toUpperCase() + splitted[1][0].toUpperCase()
      }

    }
  }

  return initial ? (
    <div onClick={onClick ? () => onClick(id): undefined} style={{ backgroundColor: color ? color : '#f00' }} className={`flex-shrink-0 w-8 h-8  relative flex justify-center items-center rounded-full text-white uppercase cursor-pointer shadow-2xl`}>{initial}</div>
  ):(
    <></>
  );
};

export default CompanyInitial;
