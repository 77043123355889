import React, { useEffect } from "react";
import IcSuccess from '../assets/ic-success.svg'
import IcError from '../assets/ic-error.svg'
import IcWarning from '../assets/ic-warning.svg'
import { generateUniqueID } from '../utils/miscellaneous'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { ExclamationIcon } from '@heroicons/react/outline'

export enum NOTIFICATION {
  SUCCESS,
  WARNING,
  ERROR,
}

interface AddNotification {
  title: string;
  message?: string;
  type: NOTIFICATION;
}

interface Notification {
    id: number,
    title: string,
    message?: string,
    icon: any
  }

const defaultApi = {
  notifications: [] as Notification[],
  setNotification: (notification: AddNotification) => {
    // method to add a new notification
  },
  deleteNotification: (id: number) => {
    // method to delete notification.
  }
};

const buildNotification = (type: NOTIFICATION, title: string, message?: string) => {
  let messageProperties = {} as Notification ;
  const id = generateUniqueID();

  switch (type) {
  case NOTIFICATION.ERROR:
    messageProperties = {
      id,
      title,
      message,
      icon: <ExclamationIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
    };
    break;
  case NOTIFICATION.WARNING:
    messageProperties = {
      id,
      title,
      message,
      icon: <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
    };
    break;
  default:
    messageProperties = {
      id,
      title,
      message,
      icon: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
    };
  }
  return messageProperties
};

export const NotificationsContext = React.createContext(defaultApi);

export function NotificationsProvider({ children }: any) {
  const { notificationShowTime } =  children[0].props

  const [notifications, setNotifications] = React.useState<Notification[]>(
    defaultApi.notifications
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (notifications.length) {
        deleteNotification(notifications[0].id);
      }
    }, notificationShowTime);

    return () => {
      clearInterval(interval);
    };
  }, [notificationShowTime, notifications]);

  const deleteNotification = (id: number) => {
    const listItemIndex = notifications.findIndex((notification) => notification.id === id);

    notifications.splice(listItemIndex, 1);
    setNotifications([...notifications]);
  };

  const setNotification = React.useCallback(
    (notification: AddNotification) => {
      const nextNotification = buildNotification(notification.type, notification.title, notification.message)
      setNotifications([...notifications, nextNotification]);
    },
    [notifications, setNotifications]
  );

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotification,
        deleteNotification
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
