import React from 'react'
import { Line } from 'react-chartjs-2';
import { formatNumber } from '../../utils/formatNumber';

interface ShareCapitalInterface {
  shareCapital: number,
  data: any
  options: any
}

const SnakeRadialBar = (props: ShareCapitalInterface) => {
  const { shareCapital, data, options } = props

  return (
    <div className="relative h-full">
      <div className="absolute top-0 flex flex-col pl-8 pt-8 bg-light-shiro font-hind font-bold md:text-xl text-left rounded-full">
        <h2>Selskapets verdi</h2>
        <h1 className='text-left my-2 md:text-6xl'>{formatNumber('nb-NO', shareCapital)}</h1>
        <h2>Aksjekapital</h2>
      </div>
      <Line type="line" data={data} options={options} />
    </div>
  )
}

export default SnakeRadialBar
