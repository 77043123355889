import React from 'react';

const Pill = ({
  className,
  variant,
  children,
}: {
  className?: string;
  variant?: 'default' | 'success' | 'warning' | 'danger';
  children: any;
}): JSX.Element => {
  return (
    <span className={`pill pill-${variant} ${className || ''}`}>
      {children}
    </span>
  );
};

export default Pill;
