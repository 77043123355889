import React from 'react';

export interface TxtBoxComponentProps {
  errorMessage?: string;
  handleTextChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  txtType: string;
  txtPlaceholder?: string;
  id?: string;
  value?: string;
  presetStyle?: string;
  presetTxtBoxStyle?: string;
}

const TextBoxComponent = ({
  errorMessage,
  handleTextChange,
  txtType,
  txtPlaceholder,
  id,
  value,
  presetStyle,
  presetTxtBoxStyle,
}: TxtBoxComponentProps): JSX.Element => {
  return (
    <div className={`form-group ${presetStyle} ${errorMessage && 'has-error'}`}>
      <input
        id={id}
        type={txtType}
        className={`form-control ${presetTxtBoxStyle}`}
        placeholder={txtPlaceholder}
        onChange={handleTextChange}
        defaultValue={value}
      />
      {errorMessage && <p className="form-error">{errorMessage}</p>}
    </div>
  );
};

export default TextBoxComponent;
