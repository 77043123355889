import React, { useEffect, useState } from 'react';
import { BoardMeetingAgenda, BoardMeetingAgendas } from '../../model/boardMeetingAgenda'
import _ from 'lodash'
import BoardMeetingAgendaCard from '../../components/boardMeetingAgenda/card/boardMeetingAgendaCard';
import BoardMeetingAgendaForm from '../../components/boardMeetingAgenda/form/boardMeetingAgendaForm';
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import {
  CREATE_BOARD_MEETING_AGENDA,
  UPDATE_BOARD_MEETING_AGENDA,
  DELETE_BOARD_MEETING_AGENDA,
  GET_BOARD_MEETING_AGENDAS,
  ORDER_BOARD_MEETING_AGENDAS,
  ADD_STANDARD_AGENDA
} from '../../services/boardMeetingAgenda'
import { useMutation, ApolloError, useQuery, gql } from '@apollo/client';
import { useParams, useHistory } from 'react-router'
import { NOTIFICATION } from '../../context/notificationContext';
import { useNotifications } from "../../hooks/notification";
import { Modal } from '../../components/modal';
import { Alert } from '../../components/alert'
import { EmptyIcon } from '../../components/icons';
import { ActionButton } from '../../components'
import ReactLoading from 'react-loading'

const BoardMeetingAgendasPage = (): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<{id: string }>();
  const [selectedBoardMeetingAgenda, setSelectedBoardMeetingAgenda] = useState<BoardMeetingAgenda | undefined>(undefined)

  const [createBoardMeetingAgenda] = useMutation(CREATE_BOARD_MEETING_AGENDA)
  const [updateBoardMeetingAgenda] = useMutation(UPDATE_BOARD_MEETING_AGENDA)
  const [deleteBoardMeetingAgenda] = useMutation(DELETE_BOARD_MEETING_AGENDA)
  const [orderBoardMeetingAgendas] = useMutation(ORDER_BOARD_MEETING_AGENDAS)
  const [addStandardAgenda] = useMutation(ADD_STANDARD_AGENDA, {
    update(cache, { data: { addStandardAgenda } }) {
      cache.modify({
        fields: {
          boardMeetingAgendas(existingBoardMeetingAgendas = []) {
            const newAgendasRef = addStandardAgenda.map((item: any) => {
              return cache.writeFragment({
                data: item,
                fragment: gql`
                  fragment NewBoardMeetingAgenda on BoardMeetingAgenda {
                    id
                  }
                `
              });
            })

            return [...newAgendasRef];
          }
        }
      });
    }
  })

  const { setNotification } = useNotifications();
  const [boardMeetingAgendaModalVisibility, setBoardMeetingAgendaModalVisibility] = useState(false)
  const [deleteConfirmationModalVisibility, setDeleteConfirmationModalVisibility] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { data: boardMeetingAgendas } = useQuery<BoardMeetingAgendas>(GET_BOARD_MEETING_AGENDAS, {
    variables: {
      boardMeetingId: id
    }
  })

  const [agendas, setAgendas] = useState([] as BoardMeetingAgenda[])

  useEffect(() => {
    if (boardMeetingAgendas) {
      setAgendas(boardMeetingAgendas.boardMeetingAgendas)
    }
  }, [boardMeetingAgendas])

  const onAddNewAgenda = () => {
    setBoardMeetingAgendaModalVisibility(true)
  }

  const onDeleteAgenda = (index: number) => {
    setSelectedBoardMeetingAgenda(agendas[index])
    setDeleteConfirmationModalVisibility(true)
  }

  const removeItem = (id: string) => {

    deleteBoardMeetingAgenda({
      variables: {
        id: id
      }
    })
      .then((res) => {
        setNotification({ title: 'Slettet', type: NOTIFICATION.SUCCESS })
        // if the last item is the one to delete
        setAgendas(agendas?.filter((agenda) => agenda.id !== id))
        setDeleteConfirmationModalVisibility(false)
        setSelectedBoardMeetingAgenda(undefined)
      })
      .catch((err: ApolloError) => {
        setNotification({ title: 'Noe gikk galt, prøv igjen', type: NOTIFICATION.ERROR })
      })
  }

  const onCancelEdit = () => {
    setBoardMeetingAgendaModalVisibility(false)
    setSelectedBoardMeetingAgenda(undefined)
  }

  const onCancelDelete = () => {
    setDeleteConfirmationModalVisibility(false)
    setSelectedBoardMeetingAgenda(undefined)
  }

  const onClickEdit = (index: number) => {
    setSelectedBoardMeetingAgenda(agendas[index])
    setBoardMeetingAgendaModalVisibility(true)
  }

  const onClickSaveAgenda = (name: string, description: string, isNewItem?: boolean) => {
    if (!isNewItem) {
      const agenda = { ...selectedBoardMeetingAgenda, name, description } as BoardMeetingAgenda

      updateBoardMeetingAgenda({
        variables: {
          attributes: {
            id: agenda.id,
            name,
            description
          }
        }
      })
        .then((res) => {
          // After creating update id for the board meeting
          const indexOfAgenda = _.findIndex(agendas, selectedBoardMeetingAgenda)

          const copy = [...agendas]
          copy.splice(indexOfAgenda, 1, { ...agenda, name, description } as BoardMeetingAgenda)
          setAgendas(copy)
          setBoardMeetingAgendaModalVisibility(false)
          setNotification({ title: 'Lagret', type: NOTIFICATION.SUCCESS })
        })
        .catch((err: ApolloError) => {
          setNotification({ title: 'Kan ikke oppdatere agendaen', type: NOTIFICATION.ERROR })
        })
    } else {
      const lastItem = _.last(agendas)
      const position = lastItem ? lastItem.position + 1 : 0

      createBoardMeetingAgenda({
        variables: {
          attributes: {
            boardMeetingId: id,
            name,
            description,
            position
          }
        }
      })
        .then((res) => {
          const id = res.data.createBoardMeetingAgenda.id

          setAgendas([...agendas, { name, description, id, position }])
          setBoardMeetingAgendaModalVisibility(false)
          setNotification({ title: 'Lagret', type: NOTIFICATION.SUCCESS })
        })
        .catch((err: ApolloError) => {
          setNotification({ title: 'Kunne ikke lagre - prøv igjen', type: NOTIFICATION.ERROR })
        })
    }

  }

  const onDragEnd = (result: any) => {
    const { destination, source: { index: sourceIndex } } = result

    if (!destination || destination.index === sourceIndex) {
      return
    }

    const destinationIndex = destination.index
    const copy = [...agendas]
    const [movedItem] = copy.splice(sourceIndex, 1)
    copy.splice(destinationIndex, 0, movedItem)

    const ids = copy.map((item) => item.id)

    setAgendas(copy)
    orderBoardMeetingAgendas({
      variables: {
        ids: ids
      }
    }).then((res) => {
      setAgendas(res.data.orderBoardMeetingAgendas)
    })
      .catch((err: ApolloError) => {
        setNotification({ title: 'Noe gikk galt, prøv igjen', type: NOTIFICATION.ERROR })
      })
  }

  const onClickAddStandardAgenda = () => {
    setSubmitting(true)
    addStandardAgenda({
      variables: {
        boardMeetingId: id
      }
    })
      .then((res) => {
        setSubmitting(false)
        setNotification({ title: 'Vellykket lagt til', type: NOTIFICATION.SUCCESS })
      })
      .catch((err: ApolloError) => {
        setSubmitting(false)
        setNotification({ title: 'Noe gikk galt, prøv igjen', type: NOTIFICATION.ERROR })
      })
  }

  return (
    <div className="flex flex-col p-8 h-full">
      <Modal
        visible={boardMeetingAgendaModalVisibility}
        onClose={() => setBoardMeetingAgendaModalVisibility(false)}
        presetStyle={'md:max-w-none md:w-2/4'}>
        <BoardMeetingAgendaForm
          boardMeetingAgenda={selectedBoardMeetingAgenda}
          onSaveAgenda={onClickSaveAgenda}
          onCancel={onCancelEdit} />
      </Modal>

      <Alert
        visible={deleteConfirmationModalVisibility}
        title={'Slett sakspunkt'}
        message={'Er du sikker på at du vil slette sakspunktet?'}
        onCancel={onCancelDelete}
        okText="Slett"
        onOk={() => { removeItem(selectedBoardMeetingAgenda!.id) }} />
      <div className="mb-4 p-4 flex flex-wrap">
        <div className="flex flex-1 mb-4 mr-4">
          <div className="flex flex-col">
            <h2 className="font-hind font-bold text-5xl">Agenda</h2>
          </div>
        </div>
        <div className="flex relative items-center justify-center" >
          <button className="btn outline-none bg-orange-light text-white text-sm" onClick={onClickAddStandardAgenda}>
            <p className={` ext-white ${submitting ? 'invisible' : 'visible'}`}>Legg til standard agenda</p>
          </button>
          <ReactLoading
            type="bars"
            height={'30px'}
            width={'30px'}
            color="#4B2A58"
            className={`absolute ${submitting ? 'visible' : 'invisible'}`}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex flex-col flex-1" >

          {
            agendas.length !== 0 ?
              <Droppable droppableId={"agendasDroppable"}>
                {(provided: any) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {
                      agendas.map((agenda, index) => {
                        return <BoardMeetingAgendaCard key={index} boardMeetingAgenda={agenda} index={index}
                          onSaveAgenda={onClickSaveAgenda}
                          onDelete={onDeleteAgenda}
                          onEdit={onClickEdit}
                          {...provided.droppableProps} />
                      })
                    }
                    {provided.placeholder}
                  </div>
                )
                }
              </Droppable>
              :
              <div className="flex flex-col items-center">
                <EmptyIcon />
                <p className="text-md font-bold">Ingen sakspunkter, legg til her</p>
              </div>
          }
          <button className="btn btn-primary btn-block outline-none bg-orange-light my-8 text-white" onClick={onAddNewAgenda}>Legg til sakspunkt</button>
        </div>

      </DragDropContext>
    </div>
  )
}

export default BoardMeetingAgendasPage;
