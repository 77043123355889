import React from 'react';

const SecondaryEmptyIcon = (): JSX.Element => {
  return (
    <svg
      width="244"
      height="195"
      viewBox="0 0 244 195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.0977 131.625H171.931C175.696 131.625 178.749 128.569 178.749 124.8C178.749 121.031 175.696 117.975 171.931 117.975C171.931 117.975 166.088 114.919 166.088 111.15C166.088 107.381 169.936 104.325 174.684 104.325H184.592C188.357 104.325 191.409 101.269 191.409 97.5C191.409 93.7307 188.357 90.675 184.592 90.675H163.166C166.931 90.675 169.983 87.6193 169.983 83.85C169.983 80.0807 166.931 77.025 163.166 77.025H202.122C205.887 77.025 208.939 73.9693 208.939 70.2C208.939 66.4307 205.887 63.375 202.122 63.375H106.68C102.915 63.375 99.8627 66.4307 99.8627 70.2C99.8627 73.9693 102.915 77.025 106.68 77.025H67.7241C63.959 77.025 60.9068 80.0807 60.9068 83.85C60.9068 87.6193 63.959 90.675 67.7241 90.675H92.0716C95.8366 90.675 98.8888 93.7307 98.8888 97.5C98.8888 101.269 95.8366 104.325 92.0716 104.325H53.1156C49.3505 104.325 46.2983 107.381 46.2983 111.15C46.2983 114.919 49.3505 117.975 53.1156 117.975H91.0977C87.3326 117.975 84.2804 121.031 84.2804 124.8C84.2804 128.569 87.3326 131.625 91.0977 131.625ZM195.305 97.5001C195.305 101.269 198.357 104.325 202.122 104.325C205.887 104.325 208.939 101.269 208.939 97.5001C208.939 93.7307 205.887 90.6751 202.122 90.6751C198.357 90.6751 195.305 93.7307 195.305 97.5001Z"
        fill="#F3F5FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.195 62.4001L159.247 128.54L160.06 135.175C160.322 137.313 158.803 139.259 156.667 139.522L99.6272 146.539C97.4915 146.802 95.5478 145.282 95.2858 143.144L86.5138 71.5599C86.3828 70.4911 87.1423 69.5181 88.2102 69.3868C88.2169 69.3859 88.2237 69.3851 88.2305 69.3844L92.9627 68.8524M93.3228 68.8418L101.255 67.9137L93.3228 68.8418Z"
        fill="white"
      />
      <path
        d="M151.56 62.213C151.456 61.4595 150.762 60.9324 150.008 61.0357C149.255 61.1391 148.728 61.8337 148.831 62.5872L151.56 62.213ZM159.247 128.54L160.613 128.372L160.612 128.362L160.611 128.353L159.247 128.54ZM160.06 135.175L161.427 135.008L160.06 135.175ZM156.667 139.522L156.835 140.889L156.667 139.522ZM99.6272 146.539L99.795 147.906L99.6272 146.539ZM95.2858 143.144L96.6527 142.977L95.2858 143.144ZM86.5138 71.5599L87.8807 71.392L86.5138 71.5599ZM88.2102 69.3868L88.0424 68.0199L88.2102 69.3868ZM88.2305 69.3844L88.3839 70.7529L88.2305 69.3844ZM93.1161 70.221C93.8719 70.136 94.4159 69.4544 94.3312 68.6986C94.2464 67.9428 93.565 67.3989 92.8092 67.4839L93.1161 70.221ZM93.1631 67.474C92.4077 67.5624 91.8668 68.2464 91.955 69.0019C92.0432 69.7573 92.7271 70.2981 93.4825 70.2097L93.1631 67.474ZM101.415 69.2815C102.17 69.1931 102.711 68.5091 102.623 67.7536C102.535 66.9982 101.851 66.4575 101.095 66.5459L101.415 69.2815ZM148.831 62.5872L157.882 128.727L160.611 128.353L151.56 62.213L148.831 62.5872ZM157.88 128.708L158.693 135.343L161.427 135.008L160.613 128.372L157.88 128.708ZM158.693 135.343C158.862 136.727 157.879 137.985 156.499 138.155L156.835 140.889C159.726 140.533 161.781 137.899 161.427 135.008L158.693 135.343ZM156.499 138.155L99.4595 145.172L99.795 147.906L156.835 140.889L156.499 138.155ZM99.4595 145.172C98.0798 145.342 96.8223 144.36 96.6527 142.977L93.919 143.312C94.2733 146.204 96.9032 148.262 99.795 147.906L99.4595 145.172ZM96.6527 142.977L87.8807 71.392L85.147 71.7278L93.919 143.312L96.6527 142.977ZM87.8807 71.392C87.842 71.0769 88.0659 70.792 88.378 70.7536L88.0424 68.0199C86.2187 68.2443 84.9236 69.9052 85.147 71.7278L87.8807 71.392ZM88.378 70.7536C88.38 70.7534 88.3819 70.7531 88.3839 70.7529L88.077 68.0158C88.0655 68.0171 88.0539 68.0185 88.0424 68.0199L88.378 70.7536ZM88.3839 70.7529L93.1161 70.221L92.8092 67.4839L88.077 68.0158L88.3839 70.7529ZM93.4825 70.2097L101.415 69.2815L101.095 66.5459L93.1631 67.474L93.4825 70.2097Z"
        fill="#f5978a"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.72 66.5624L155.92 126.509L156.658 132.523C156.895 134.461 155.538 136.222 153.626 136.457L102.552 142.735C100.64 142.97 98.8971 141.59 98.6595 139.652L90.7531 75.1869C90.605 73.979 91.464 72.8795 92.6718 72.7311L98.7364 71.9856"
        fill="#F3F5FE"
      />
      <path
        d="M105.79 53.1568C105.79 51.4835 107.147 50.1271 108.82 50.1271H152.463C153.267 50.1271 154.038 50.4465 154.606 51.015L167.4 63.8159C167.968 64.384 168.287 65.1544 168.287 65.9576V126.243C168.287 127.916 166.931 129.273 165.257 129.273H108.82C107.147 129.273 105.79 127.916 105.79 126.243V53.1568Z"
        fill="white"
        stroke="#f5978a"
        strokeWidth="2.75424"
      />
      <path
        d="M153.108 51.0928V62.4C153.108 64.0155 154.416 65.325 156.03 65.325H163.756"
        stroke="#f5978a"
        strokeWidth="2.75424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SecondaryEmptyIcon;
