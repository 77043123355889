import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TextBox } from '../../textBox';
import ReactLoading from 'react-loading';
import { UserLoginErrors, SignedInUser } from '../../../model';
import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { USER_LOGIN, GET_SIGNED_IN_USER } from '../../../services/auth';
import { loginErrorMessages } from '../../../utils/constants';
import { GET_CALLBACK_LINK } from '../../../services/callBackLink';
import { setCallBackLink } from '../../../store/cache';
import { selectedCompanyVar } from '../../../store/cache';
import { useUrlBuilder } from '../../../hooks/urlBuilder';
import { useRouteCheck } from '../../../hooks/routeCheck';
import useUnsavedChangesWarning from '../../../hooks/unsavedChangesWarning';
import ClientSession from '../../../utils/clientSession'
import { setPreviousPath, setShowSetupCompany } from '../../../store/cache'
import _ from 'lodash'
import { Company } from '../../../model/user'

const LoginForm = (props: any): JSX.Element => {
  const { buildAdminUrl } = useUrlBuilder();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState({} as UserLoginErrors);

  const [userLogin] = useMutation(USER_LOGIN);
  const { data: callBackLink } = useQuery(GET_CALLBACK_LINK);

  const {
    setOffChanges,
    handleChangesListener,
  } = useUnsavedChangesWarning({});

  const { checkAccess } = useRouteCheck((checkAccess) => {
    // Redirect to the first allowed dashboard.
    const firstAllowedPath = Object.keys(checkAccess.checkAccess).find(
      (key) => checkAccess.checkAccess[key] === true,
    );
    if(firstAllowedPath) {
      setPreviousPath(firstAllowedPath)
      history.push(firstAllowedPath);
    }
  });

  const setInput = (setter: React.Dispatch<React.SetStateAction<string>>) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    handleChangesListener(event);
    setter(event.target.value);
    setValidationError({ email: '', password: '' });
  };

  const [getSignedInUser] = useLazyQuery<SignedInUser>(GET_SIGNED_IN_USER, {
    onCompleted: (signedInUser) => {
      if (signedInUser.signedInUser.companies.length === 0) {
        window.location.href = buildAdminUrl('/find-company', true)
      } else {
        const selectedCompany = signedInUser.signedInUser.companies[0];

        const managedCompany = _.find(signedInUser?.signedInUser.managedCompanies, {id: selectedCompany.id})
        if(managedCompany?.isEmpty)
          setShowSetupCompany(true)

        // set selected company
        selectedCompanyVar(selectedCompany);

        if (callBackLink.callBackLink) {
          history.push(callBackLink.callBackLink);
        } else {
          // Call CheckAccess query to determine where to redirect to
          checkAccess(selectedCompany.id, true);
        }

        setCallBackLink('');
      }
    },
  });

  useEffect(() => {
    if(ClientSession.isLoggedIn()) {
      getSignedInUser()
    }
  }, [])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (checkValidation()) {
      setOffChanges();
      setLoading(true);
      setErrorMessage('');
      userLogin({
        variables: {
          email,
          password,
        },
      })
        .then((res) => {
          setLoading(false);
          // history.push('/dashboard');
          // added in to refresh the whole application state instead of pushing
          // window.location.href = '/dashboard';
          getSignedInUser();
        })
        .catch((err: ApolloError) => {
          setLoading(false);
          setErrorMessage(err.message);
        });
    }
  };

  const checkValidation = () => {
    let response = true;
    const errors: UserLoginErrors = {
      email: '',
      password: '',
    };

    if (!email) {
      response = false;
      errors.email = loginErrorMessages.EMAIL_REQUIRED;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      response = false;
      errors.email = loginErrorMessages.INVALID_EMAIL;
    }

    if (!password) {
      response = false;
      errors.password = loginErrorMessages.PASSWORD_REQUIRED;
    } else if (password.length < 6) {
      response = false;
      errors.password = loginErrorMessages.INVALID_PASSWORD;
    }

    setValidationError(errors);
    return response;
  };

  return (
    <Fragment>
      <div className="register-form">
        <h1 className="page-title font-hind">Logg inn</h1>
        <form id="login-form" onSubmit={handleSubmit}>
          <TextBox
            errorMessage={validationError.email}
            handleTextChange={setInput(setEmail)}
            txtType="text"
            txtPlaceholder="din@epost.no"
            id="email"
            value={email}
          />
          <TextBox
            errorMessage={validationError.password}
            handleTextChange={setInput(setPassword)}
            txtType="password"
            txtPlaceholder="Passord"
            id="password"
            value={password}
          />
          <div className="form-group justify-center mt-8">
            {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
            <button
              className="btn btn-primary btn-block font-hind font-bold outline-none"
              // onClick={handleSubmit}
              id="login-btn"
              type="submit"
            >
              {loading ? (
                <ReactLoading
                  type="bars"
                  height={'30px'}
                  width={'30px'}
                  color="#4B2A58"
                />
              ) : (
                'Logg inn'
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="mt-12">
        <p className="text-center">
          Har du ikke konto?{' '}
          <Link to="/signup" className="underline">
            Registrer deg
          </Link>
        </p>
      </div>
    </Fragment>
  );
};

export default LoginForm;
