import React, { useState, useEffect } from 'react';
import {
  CreateUpdateFundingEventErrors,
  FundingEvent,
  FundingEventDetail,
} from '../../model/fundingEvent';
import {
  createUpdateFundingEventErrorMessages,
  GENERIC_FORM_ERROR_MESSAGE,
} from '../../utils/constants';
import { getFormData } from '../../utils/form';
import { useHistory } from 'react-router';
import { TextInput } from '../textInput';
import { DateTimePicker } from '../dateTimePicker';
import IcCalendar from '../../assets/icons/ic-calendar.svg';
import moment from 'moment';
import { TextArea } from '../textArea';
import { toLocaleDateTime } from '../../utils/formatTime';
import {
  CREATE_FUNDING_EVENT,
  UPDATE_FUNDING_EVENT,
} from '../../services/fundingEvent';
import { GET_SELECTED_COMPANY } from '../../services/company';
import {
  useMutation,
  useQuery,
  ApolloError,
  useLazyQuery,
} from '@apollo/client';
import { ManagedCompany, SingleFundingEventVar } from '../../model/company';
import { GET_MANAGED_COMPANY_SHAREHOLDERS_STOCK_CLASSES } from '../../services/transaction';
import { GET_FUNDING_EVENT_DETAIL } from '../../services/fundingEvent';
import ReactLoading from 'react-loading';
import { useNotifications } from '../../hooks/notification';
import { NOTIFICATION } from '../../context/notificationContext';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Checkbox } from '../../components/checkbox';
import { RadioButton } from '../../components/radioButton';
import { gql } from '@apollo/client';
import { SecondaryEmptyIcon } from '../../components/icons';
import { Message } from '../../components';
import { useUrlBuilder } from '../../hooks/urlBuilder';

const FundingEventForm = (): JSX.Element => {
  const history = useHistory();
  const { buildAdminUrl, selectedCompanyId } = useUrlBuilder();

  const { id } = useParams<{ id: string }>();
  const DESCRIPTION_ROW_LENGTH = 4;

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validationError, setValidationError] = useState(
    {} as CreateUpdateFundingEventErrors,
  );
  const [eventOn, setEventOn] = useState(moment().format());
  const [payableBy, setPayableBy] = useState(moment().format());
  const [reparation, setReparation] = useState(false);
  const [selectedShareClass, setSelectedShareClass] = useState('');
  const [capitalPerShare, setCapitalPerShare] = useState(0)
  const [pricePerShare, setPricePerShare] = useState(0)
  const [sharePremium, setSharePremium] = useState(0)

  const [fundingEvent, setFundingEvent] = useState<FundingEvent>(
    {} as FundingEvent,
  );

  // Queries and Mutation
  const [createFundingEvent] = useMutation(CREATE_FUNDING_EVENT, {
    update(cache, { data: { createFundingEvent } }) {
      cache.modify({
        fields: {
          fundingEvents(existingFundingEvents = []) {
            const newFundingEventRef = cache.writeFragment({
              data: createFundingEvent,
              fragment: gql`
                fragment NewFundingEvent on FundingEvent {
                  id
                }
              `,
            });
            return [...existingFundingEvents, newFundingEventRef];
          },
        },
      });
    },
  });
  const [updateFundingEvent] = useMutation(UPDATE_FUNDING_EVENT);
  const { setNotification } = useNotifications();

  // get funding event with the given Id
  const [
    getFundingEventDetail,
    { data: fundingEventDetail, error: fundingEventDetailError },
  ] = useLazyQuery<FundingEventDetail, SingleFundingEventVar>(
    GET_FUNDING_EVENT_DETAIL,
  );

  const { data } = useQuery<ManagedCompany>(
    GET_MANAGED_COMPANY_SHAREHOLDERS_STOCK_CLASSES,
    {
      variables: {
        id: selectedCompanyId,
      },
    },
  );
  const shareClasses = data ? data.managedCompany.shareClasses : [];

  useEffect(() => {
    // bind default share class only when the form is to create a new funding event,
    // if the form is for update only the previously selected share classes has to be checked
    if (data?.managedCompany && !id)
      setSelectedShareClass(data.managedCompany.defaultShareClass);
  }, [data]);

  useEffect(() => {
    if (id && !fundingEventDetailError) {
      getFundingEventDetail({
        variables: {
          id,
        },
      });
    }

    if (fundingEventDetail) {
      setFundingEvent(fundingEventDetail.fundingEvent);
      setPayableBy(fundingEventDetail.fundingEvent.payableBy);
      setEventOn(fundingEventDetail.fundingEvent.eventOn);
      setReparation(fundingEventDetail.fundingEvent.reparation);
      setSelectedShareClass(fundingEventDetail.fundingEvent.shareClasses[0]);
      setSharePremium(fundingEventDetail.fundingEvent.sharePremium)
      setPricePerShare(fundingEventDetail.fundingEvent.pricePerShare)
      setCapitalPerShare(fundingEventDetail.fundingEvent.capitalPerShare)
    }
  }, [fundingEventDetail, fundingEventDetailError, id]);

  const checkValidation = (
    name: string,
    amount: number,
    pricePerShare: number,
    sharePremium: number,
    eventOn: string,
    payableBy: string,
    referenceText: string
  ) => {
    let response = true;
    const errors: CreateUpdateFundingEventErrors = {};

    if (!name) {
      response = false;
      errors.name = createUpdateFundingEventErrorMessages.NAME_REQUIRED;
    }

    if (eventOn && payableBy && moment(payableBy) > moment(eventOn)) {
      response = false;
      errors.payableBy =
        createUpdateFundingEventErrorMessages.INVALID_PAYABLE_BY_DATE;
    }

    if (!amount) {
      response = false
      errors.amount = createUpdateFundingEventErrorMessages.AMOUNT_REQUIRED
    } else if (amount <= 0) {
      response = false
      errors.amount = createUpdateFundingEventErrorMessages.INVALID_AMOUNT
    }

    if (!pricePerShare) {
      response = false
      errors.pricePerShare = createUpdateFundingEventErrorMessages.PRICE_PER_SHARE_REQUIRED
    } else if (pricePerShare <= 0) {
      response = false
      errors.pricePerShare = createUpdateFundingEventErrorMessages.INVALID_PRICE_PER_SHARE
    }

    if (sharePremium && sharePremium < 0) {
      response = false
      errors.sharePremium = createUpdateFundingEventErrorMessages.INVALID_SHARE_PREMIUM
    }

    if( pricePerShare > 0 && sharePremium >= pricePerShare) {
      response = false
      errors.sharePremium = createUpdateFundingEventErrorMessages.INVALID_MSG_SHARE_PREMIUM_GREATER_THAN_SHARE_CAPITAL
    }

    setValidationError(errors);
    return response;
  };

  const onToggleShareClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedShareClass(event.target.id);
    }
  };

  const onChangePricePerShare = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    setPricePerShare(value)
    if(sharePremium >= 0) setCapitalPerShare(value - sharePremium)
  }

  const onChangeSharePremium = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    setSharePremium(value)
    if(value >= 0) setCapitalPerShare(pricePerShare - value)
  }

  const onSubmitForm = (event: any) => {
    event.preventDefault();
    const formData = getFormData(event.target.elements);
    const {
      name,
      description,
      amount,
      pricePerShare,
      sharePremium,
      capitalPerShare,
      referenceText
    } = formData;

    if (checkValidation(name, Number(amount), Number(pricePerShare), Number(sharePremium), eventOn, payableBy, referenceText)) {
      setErrorMessage('');
      setSubmitting(true);
      if (id) {
        // update funding event
        updateFundingEvent({
          variables: {
            attributes: {
              id,
              companyId: selectedCompanyId,
              name,
              description,
              amount: Number(amount),
              pricePerShare: Number(pricePerShare),
              sharePremium: Number(sharePremium),
              capitalPerShare: Number(capitalPerShare),
              shareClasses: [selectedShareClass],
              reparation,
              eventOn,
              payableBy,
              referenceText
            },
          },
        })
          .then((res) => {
            setSubmitting(false);
            setNotification({
              title: 'Emisjon oppdatert!',
              type: NOTIFICATION.SUCCESS,
            });
            history.push(
              buildAdminUrl(
                `/funding-events/${res.data.updateFundingEvent.id}`,
              ),
            );
          })
          .catch((err: ApolloError) => {
            setSubmitting(false);
            const apiValidationErrors: CreateUpdateFundingEventErrors = validationError;

            err.graphQLErrors.map((error) => {
              const path = error.path;
              const fieldName = path ? path[1].toString() : '';

              (apiValidationErrors as any)[fieldName] = error.message;
              setValidationError({ ...apiValidationErrors });
              setNotification({
                title: GENERIC_FORM_ERROR_MESSAGE,
                type: NOTIFICATION.ERROR,
              });
            });
          });
      } else {
        // create funding event
        createFundingEvent({
          variables: {
            attributes: {
              companyId: selectedCompanyId,
              name,
              description,
              amount: Number(amount),
              pricePerShare: Number(pricePerShare),
              sharePremium: Number(sharePremium),
              capitalPerShare: Number(capitalPerShare),
              shareClasses: [selectedShareClass],
              reparation,
              eventOn,
              payableBy,
              referenceText
            },
          },
        })
          .then((res) => {
            setSubmitting(false);
            setNotification({
              title: 'Emisjon opprettet og lagret!',
              type: NOTIFICATION.SUCCESS,
            });
            history.push(
              buildAdminUrl(
                `/funding-events/${res.data.createFundingEvent.id}`,
              ),
            );
          })
          .catch((err: ApolloError) => {
            setSubmitting(false);
            const apiValidationErrors: CreateUpdateFundingEventErrors = validationError;

            err.graphQLErrors.map((error) => {
              const path = error.path;
              const fieldName = path ? path[1].toString() : '';

              (apiValidationErrors as any)[fieldName] = error.message;
              setValidationError({ ...apiValidationErrors });
              setNotification({
                title: GENERIC_FORM_ERROR_MESSAGE,
                type: NOTIFICATION.ERROR,
              });
            });
          });
      }
    }
  };

  const toggleReparation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReparation(event.target.checked);
  };

  return fundingEventDetailError ? (
    <div className="bg-white p-6 rounded-3xl h-full flex justify-center items-center">
      <Message
        title="Funding Event ikke funnet"
        description="Beklager, vi kunne ikke finne siden du leter etter."
        toExecute={() => history.goBack()}
        icon={<SecondaryEmptyIcon />}
        btnLabel="Gå Tilbake"
      />
    </div>
  ) : (
    <form onSubmit={onSubmitForm}>
      <TextInput
        name="name"
        placeholder={'Navn'}
        type="text"
        errorMessage={validationError.name}
        defaultValue={fundingEvent.name}
        label={'Navn'}
      />

      <TextArea
        rows={DESCRIPTION_ROW_LENGTH}
        label="Beskrivelse"
        name="description"
        placeholder="Hvorfor skal det være en emisjon?"
        defaultValue={fundingEvent.description}
      />

      <div className="grid grid-cols-2 gap-2.5">
        <div className="col-span-2 sm:col-span-1">
          <TextInput
            name="amount"
            type="number"
            errorMessage={validationError.amount}
            defaultValue={fundingEvent.amount }
            label={'Antall aksjer'}
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <TextInput
            name="pricePerShare"
            type="number"
            errorMessage={validationError.pricePerShare}
            defaultValue={fundingEvent.pricePerShare }
            label={'Pris pr aksje'}
            onChange={ onChangePricePerShare }
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2.5">
        <div className="col-span-2 sm:col-span-1">
          <TextInput
            name="sharePremium"
            type="number"
            errorMessage={validationError.sharePremium}
            defaultValue={ fundingEvent.sharePremium ? fundingEvent.sharePremium : '' }
            label={'Aksjepremium'}
            onChange={ onChangeSharePremium }
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <TextInput
            name="capitalPerShare"
            type="number"
            errorMessage={validationError.capitalPerShare}
            defaultValue={fundingEvent.capitalPerShare }
            value={capitalPerShare > 0 ? capitalPerShare : '' }
            label={'Bokført verdi pr aksje'}
            disabled
          />
        </div>
      </div>
      <div className="mt-8">
        <Checkbox
          id={'reparation'}
          onChange={toggleReparation}
          name={'reparation'}
          checked={reparation}
          label="Reparasjon etter emisjon"
        />
      </div>
      <p className="block font-bold mt-8 mb-4 ml-2">Velg andelsklasse</p>
      <div className="grid grid-cols-4">
        <div className="grid grid-cols-2 col-span-4 sm:col-span-2 gap-2">
          {shareClasses.map((stockClass, index) => {
            return (
              <div className="col-span-2 sm:col-span-1" key={index}>
                <RadioButton
                  id={stockClass}
                  name="shareClass"
                  label={stockClass}
                  onChange={onToggleShareClass}
                  checked={stockClass === selectedShareClass}
                />
              </div>
            );
          })}
        </div>
        <div className="sm:col-span-2" />
      </div>

      <div className="grid grid-cols-2 gap-2.5">
        <div className="col-span-2 sm:col-span-1">
          <DateTimePicker
            presetStyle="flex rounded-full bg-text-input p-4 w-full"
            options={{
              enableTime: false,
              formatDate: () => toLocaleDateTime(eventOn, 'L'),
            }}
            icon={IcCalendar}
            defaultValue={moment(eventOn)}
            onChange={(date: any) => setEventOn(moment(date[0]).format())}
            errorMessage={validationError.eventOn}
            label={'Lukkedato'}
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <DateTimePicker
            presetStyle="flex rounded-full bg-text-input p-4 w-full"
            options={{
              enableTime: false,
              formatDate: () => toLocaleDateTime(payableBy, 'L'),
            }}
            icon={IcCalendar}
            defaultValue={moment(payableBy)}
            onChange={(date: any) => setPayableBy(moment(date[0]).format())}
            errorMessage={validationError.payableBy}
            label={'Innbetalingsfrist'}
          />
        </div>
      </div>
      <div>
        <TextInput
          name="referenceText"
          type="text"
          errorMessage={validationError.referenceText}
          defaultValue={fundingEvent.referenceText}
          label={'Betalingsreferanse'}
        />
      </div>
      <button
        className="btn btn-primary btn-block outline-none bg-orange-light"
        type="submit"
      >
        {submitting ? (
          <ReactLoading
            type="bars"
            height={'30px'}
            width={'30px'}
            color="#4B2A58"
          />
        ) : (
          <p className="text-white">Lagre endringer</p>
        )}
      </button>
      <div className="flex justify-center">
        {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
      </div>
    </form>
  );
};

export default FundingEventForm;
