import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { unsavedChangesDefaultMessage } from '../utils/constants';
import { GET_FORM_STATUS } from '../services/common';
import { useQuery } from '@apollo/client';
import { setIsFormDirty } from '../store/cache';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Modal } from '../components';

interface UnsavedChangesWarningProps {
  message?: string;
}

const useUnsavedChangesWarning = ({
  message = unsavedChangesDefaultMessage,
}: UnsavedChangesWarningProps) => {
  const { data: isFormDirty } = useQuery(GET_FORM_STATUS);

  useEffect(() => {
    window.addEventListener('onbeforeunload', handleUnsavedChanges);
    return () => {
      window.removeEventListener('onbeforeunload', handleUnsavedChanges);
    };
  }, [isFormDirty]);

  const handleUnsavedChanges = () => {
    if (isFormDirty.isFormDirty) return message;
  };

  const RouterPrompt = () => (
    <NavigationPrompt when={isFormDirty?.isFormDirty}>
      {({ onConfirm, onCancel }) => (
        <Modal
          visible={isFormDirty?.isFormDirty}
          onClose={onCancel}
          presetStyle="w-full sm:w-96"
        >
          <div>
            <div className="flex justify-between items-center border-gray-200 border-b-1">
              <div>
                <h2 className="font-hind font-bold text-xl">
                  Leave this page?
                </h2>
              </div>
              <button
                type="button"
                className="focus:outline-none"
                onClick={onCancel}
              >
                <i className="uc-icon text-2xl text-low-gray-color">&#xeb8e;</i>
              </button>
            </div>
            <div className="mt-4">
              <span className="font-roboto">{message}</span>
            </div>
            <div className="flex mt-8">
              <button
                className="btn btn-primary btn-block outline-none bg-white mr-2 border border-orange-color"
                type="button"
                onClick={onCancel}
              >
                <span className="text-seven-color">Cancel</span>
              </button>
              <button
                className="btn btn-primary btn-block outline-none bg-orange-color ml-2"
                type="button"
                onClick={() => {
                  onConfirm();
                  setOffChanges();
                }}
              >
                <span className="text-white">Confirm</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
    </NavigationPrompt>
  );

  const setOnChanges = () => setIsFormDirty(true);

  const setOffChanges = () => setIsFormDirty(false);

  const handleChangesListener = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => (ev.target.value ? setOnChanges() : setOffChanges());

  return {
    RouterPrompt,
    setOnChanges,
    setOffChanges,
    handleChangesListener,
  };
};

export default useUnsavedChangesWarning;
