import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { fundingEventsRoutePrefix } from '../utils/constants';

const IsFundingEvent = () => {
  const { pathname } = useLocation<{ pathname: string }>();

  return useMemo(() => {
    return pathname.includes(fundingEventsRoutePrefix);
  }, [pathname]);
};

export default IsFundingEvent;
