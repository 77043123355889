import React, { useState, useEffect } from 'react';
import { dashboardContainer } from '../../utils/constants';
import { NewBoardMeetingErrors, BoardMeeting } from '../../model';
import {
  TextArea,
  TextInput,
  SwitchButton,
  DateTimePicker,
  ActionButton,
  Skeleton,
} from '../../components';
import ReactLoading from 'react-loading';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toLocaleDateTime } from '../../utils/formatTime';
import IcCalendar from '../../assets/icons/ic-calendar.svg';
import moment from 'moment';
import {
  newBoardMeetingErrors,
  boardMeetingStatus,
  postCodeLength,
  invalidPostCode,
  boardMeetingEditRoute,
} from '../../utils/constants';
import {
  CREATE_BOARD_MEETING,
  GET_BOARD_MEETING,
  UPDATE_BOARD_MEETING,
} from '../../services/events';
import {
  ApolloError,
  useMutation,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import { isValidWebLink } from '../../utils/validators';
import { gql } from '@apollo/client';
import { useUrlBuilder } from '../../hooks/urlBuilder';
import useUnsavedChangesWarning from '../../hooks/unsavedChangesWarning';
import IsFundingEvent from '../../hooks/isFundingEvent';

const NewBoardMeeting = (): JSX.Element => {
  const { buildAdminUrl } = useUrlBuilder();

  const { eventId } = useParams<{ eventId: string }>();
  const isEdit = useRouteMatch(boardMeetingEditRoute);
  const isFundingEvent = IsFundingEvent();

  const { setOffChanges, handleChangesListener } = useUnsavedChangesWarning({});

  const [updateBoardMeeting, { data: boardMeetingUpdateDetails }] = useMutation(
    UPDATE_BOARD_MEETING,
  );

  const [createNewBoardMeeting, { data: newBoardMeetingDetails }] = useMutation(
    CREATE_BOARD_MEETING,
    {
      update(cache, { data: { createBoardMeeting } }) {
        cache.modify({
          id: cache.identify({ __typename: 'FundingEvent', id: eventId }),
          fields: {
            boardMeetings(existingBoardMeetings = []) {
              const newBoardMeetingRef = cache.writeFragment({
                data: createBoardMeeting,
                fragment: gql`
                  fragment NewBoardMeeting on BoardMeeting {
                    id
                  }
                `,
              });
              return [...existingBoardMeetings, newBoardMeetingRef];
            },
          },
        });
      },
    },
  );

  const history = useHistory();

  const [isVirtual, setIsVirtual] = useState(false);
  const [isPhysical, setIsPhysical] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [videoconferenceLink, setVideoconferenceLink] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [postCode, setPostCode] = useState('');
  const [city, setCity] = useState('');
  const [meetingAt, setMeetingAt] = useState('');
  const [status, setStatus] = useState('');
  const [boardMeetingId, setBoardMeetingId] = useState('');
  const [itemsToAgenda, setItemsToAgenda] = useState(false);

  const [validationError, setValidationError] = useState(
    {} as NewBoardMeetingErrors,
  );

  const [getBoardMeeting, { loading: isBoardMeetingLoading }] = useLazyQuery(
    GET_BOARD_MEETING,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => fillForm(data.boardMeeting),
    },
  );

  const fillForm = (boardMeetingDetails: BoardMeeting) => {
    setCity(boardMeetingDetails?.city);
    setDescription(boardMeetingDetails?.description);
    setName(boardMeetingDetails?.name);
    setIsPhysical(boardMeetingDetails?.offline);
    setIsVirtual(boardMeetingDetails?.online);
    setPostCode(boardMeetingDetails?.postCode);
    setStatus(boardMeetingDetails?.status);
    setStreetAddress(boardMeetingDetails?.streetAddress);
    setVideoconferenceLink(boardMeetingDetails?.videoconferenceLink);
    setMeetingAt(boardMeetingDetails?.meetingAt);
    setBoardMeetingId(boardMeetingDetails?.id);
  };

  useEffect(() => {
    if (isEdit)
      getBoardMeeting({
        variables: {
          id: eventId,
        },
      });
  }, []);

  const setInput = (setter: React.Dispatch<React.SetStateAction<string>>) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    handleChangesListener(event);
    setter(event.target.value);
    setValidationError({
      name: '',
      description: '',
      videoconferenceLink: '',
      streetAddress: '',
      postCode: '',
      city: '',
      venue: '',
      meetingAt: '',
    });
  };

  const onSubmitForm = (event: React.FormEvent) => {
    event.preventDefault();
    if (checkValidation()) {
      setOffChanges();
      setIsLoading(true);
      setErrorMessage('');
      if (!isEdit) {
        createNewBoardMeeting({
          variables: {
            attributes: {
              name,
              shareIssuanceEventId: eventId,
              description,
              status: boardMeetingStatus.initial,
              online: isVirtual,
              offline: isPhysical,
              videoconferenceLink,
              streetAddress,
              postCode,
              city,
              meetingAt,
            },
          },
        })
          .then((res) => {
            handleSaveUpdateEffect(res?.data?.createBoardMeeting?.id, eventId);
          })
          .catch((err: ApolloError) => {
            setIsLoading(false);
            setErrorMessage(err.message);
          });
      } else triggerUpdateBoardMeeting();
    }
  };

  const triggerUpdateBoardMeeting = () => {
    setOffChanges();
    updateBoardMeeting({
      variables: {
        attributes: {
          id: boardMeetingId,
          name,
          description,
          status,
          online: isVirtual,
          offline: isPhysical,
          videoconferenceLink: isVirtual ? videoconferenceLink : '',
          streetAddress: isPhysical ? streetAddress : '',
          postCode: isPhysical ? postCode : '',
          city: isPhysical ? city : '',
          meetingAt,
        },
      },
    })
      .then((res) => {
        handleSaveUpdateEffect(
          res?.data?.updateBoardMeeting?.id,
          res?.data?.updateBoardMeeting?.shareIssuanceEventId,
        );
      })
      .catch((err: ApolloError) => {
        setIsLoading(false);
        setErrorMessage(err.message);
      });
  };

  const handleSaveUpdateEffect = (boardMeetingId: string, eventId: string) => {
    setIsLoading(false);
    history.push(
      itemsToAgenda
        ? buildAdminUrl(`/board-meetings/${boardMeetingId}/agendas`)
        : buildAdminUrl(
          isFundingEvent
            ? `/funding-events/${eventId}?tab=board-meeting`
            : `/founding-events/${eventId}?tab=board-meeting`,
        ),
    );
    setItemsToAgenda(false);
  };

  const checkValidation = () => {
    let response = true;
    const errors: NewBoardMeetingErrors = {
      name: '',
      description: '',
      videoconferenceLink: '',
      streetAddress: '',
      postCode: '',
      city: '',
      venue: '',
      meetingAt: '',
    };

    if (!name) {
      response = false;
      errors.name = newBoardMeetingErrors.NAME_REQUIRED;
    }

    if (!description) {
      response = false;
      errors.description = newBoardMeetingErrors.DESCRIPTION_REQUIRED;
    }

    if (!isVirtual && !isPhysical) {
      response = false;
      errors.venue = newBoardMeetingErrors.VENUE_REQUIRED;
    }

    if (isVirtual && !videoconferenceLink) {
      response = false;
      errors.videoconferenceLink =
        newBoardMeetingErrors.CONFERENCE_LINK_REQUIRED;
    }

    if (videoconferenceLink && !isValidWebLink(videoconferenceLink)) {
      response = false;
      errors.videoconferenceLink = newBoardMeetingErrors.INVALID_LINK;
    }

    if (isPhysical && !streetAddress) {
      response = false;
      errors.streetAddress = newBoardMeetingErrors.STREET_ADDRESS_REQUIRED;
    }

    if (isPhysical && !postCode) {
      response = false;
      errors.postCode = newBoardMeetingErrors.POST_CODE_REQUIRED;
    }

    if (
      (postCode && postCode.length !== postCodeLength) ||
      postCode === invalidPostCode
    ) {
      response = false;
      errors.postCode = newBoardMeetingErrors.INVALID_POST_CODE;
    }

    if (isPhysical && !city) {
      response = false;
      errors.city = newBoardMeetingErrors.CITY_REQUIRED;
    }

    if (!meetingAt) {
      response = false;
      errors.meetingAt = newBoardMeetingErrors.MEETING_AT_REQUIRED;
    }

    setValidationError(errors);
    return response;
  };
  if (isEdit && isBoardMeetingLoading) return <Skeleton />;

  return (
    <div className={dashboardContainer}>
      <div className="mb-4 p-4 flex">
        <div className="flex flex-col">
          <h2 className="font-hind font-bold text-5xl">Generalforsamling</h2>
        </div>
      </div>
      <form onSubmit={onSubmitForm}>
        <TextInput
          name="name"
          placeholder="Navn"
          type="text"
          errorMessage={validationError.name}
          label="Navn"
          onChange={setInput(setName)}
          value={name}
        />
        <TextArea
          rows={4}
          label="Beskrivelse"
          name="description"
          placeholder="Hvorfor skal det være en Generalforsamling?"
          errorMessage={validationError.description}
          onChange={setInput(setDescription)}
          value={description}
        />
        <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-4 w-full items-center">
          <div>
            <div className="mb-1 font-bold block pl-4">Møtesteder</div>
            <div className="">
              <SwitchButton
                isChecked={isVirtual}
                label="Videomøte"
                onClick={() => {
                  setIsVirtual(!isVirtual);
                  setValidationError({
                    ...validationError,
                    venue: '',
                  });
                }}
              />
            </div>
            <div className="text-right pr-4 pt-2 form-group">
              {validationError.venue && (
                <p className="form-error">{validationError.venue}</p>
              )}
            </div>
          </div>
          {isVirtual && (
            <TextInput
              name="link"
              placeholder="lenke til videokonferanse"
              type="text"
              errorMessage={validationError.videoconferenceLink}
              label="Videolink"
              onChange={setInput(setVideoconferenceLink)}
              value={videoconferenceLink}
              autoFocus={isVirtual}
            />
          )}
        </div>
        <div className="mb-4">
          <SwitchButton
            isChecked={isPhysical}
            label="Fysisk møtested"
            onClick={() => {
              setIsPhysical(!isPhysical);
              setValidationError({
                ...validationError,
                venue: '',
              });
            }}
          />
        </div>
        {isPhysical && (
          <TextInput
            name="street"
            placeholder="gateadresse"
            type="text"
            errorMessage={validationError.streetAddress}
            label="Gateadresse"
            onChange={setInput(setStreetAddress)}
            value={streetAddress}
            autoFocus={isPhysical}
          />
        )}
        {isPhysical && (
          <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-4 w-full">
            <TextInput
              name="postCode"
              placeholder="Postnummer"
              type="text"
              errorMessage={validationError.postCode}
              label="Postnummer"
              onChange={setInput(setPostCode)}
              value={postCode}
            />
            <TextInput
              name="city"
              placeholder="Sted"
              type="text"
              errorMessage={validationError.city}
              label="Sted"
              onChange={setInput(setCity)}
              value={city}
            />
          </div>
        )}
        <div className="w-full">
          <DateTimePicker
            presetStyle="flex rounded-full bg-text-input p-4 w-full"
            options={{ enableTime: true, formatDate: toLocaleDateTime }}
            icon={IcCalendar}
            defaultValue={moment(meetingAt)}
            onChange={(date: any) => setMeetingAt(moment(date[0]).format())}
            errorMessage={validationError.meetingAt}
            label="Tid og sted"
            placeholder="Velg et tidspunkt for møtet"
          />
        </div>
        <div className="flex flex-col xl:flex-row">
          <button
            className="btn btn-primary btn-block outline-none bg-orange-light mr-0 xl:mr-4 mb-4 xl:mb-0"
            type="submit"
            onClick={() => setItemsToAgenda(false)}
          >
            {isLoading && !itemsToAgenda ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#4B2A58"
              />
            ) : (
              <p className="text-white">Lagre</p>
            )}
          </button>
          <button
            className="btn btn-secondary btn-block outline-none bg-seven-color ml-0 xl:ml-4"
            type="submit"
            onClick={() => setItemsToAgenda(true)}
          >
            {isLoading && itemsToAgenda ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#fff"
              />
            ) : (
              <p className="text-white">Lagre og rediger agenda</p>
            )}
          </button>
        </div>
        <div className="flex justify-center">
          {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
        </div>
      </form>
    </div>
  );
};

export default NewBoardMeeting;
