import React, { useState, useEffect } from 'react';
import IcStockClass from '../../../../assets/icons/ic-stock-class.svg';
import IcPerson2 from '../../../../assets/icons/ic-person-2.svg';
import IcCalendar from '../../../../assets/icons/ic-calendar.svg';
import { Select } from '../../../select';
import { TextInput } from '../../../textInput';
import { getFormData } from '../../../../utils/form';
import { ConvertTransaction, CreateConvertTransactionErrors } from '../../../../model/transaction';
import { ApiValidationError } from '../../../../model/common';
import { useHistory } from 'react-router';
import { createTransactionErrorMessages, transactionTypes } from '../../../../utils/constants';
import { DateTimePicker } from '../../../dateTimePicker';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../../utils/constants';
import { useUrlBuilder } from '../../../../hooks/urlBuilder';
import { useQuery } from '@apollo/client';
import { ManagedCompany } from '../../../../model/company';
import { GET_MANAGED_COMPANY_SHAREHOLDERS_STOCK_CLASSES } from '../../../../services/transaction';
import { Share, Shareholder } from 'src/model';

interface ConvertTransactionForm {
  formData: ConvertTransaction;
  apiErrorsMessages: ApiValidationError[];
}

const ConvertTransactionForm = (props: ConvertTransactionForm): JSX.Element => {
  const history = useHistory();
  const { buildAdminUrl, selectedCompanyId } = useUrlBuilder();
  const [errorMessage, setErrorMessage] = useState('');
  const [validationError, setValidationError] = useState({} as CreateConvertTransactionErrors);
  const [selectedDateTime, setSelectedDateTime] = useState(moment().format(DATE_TIME_FORMAT));
  const [selectedFromShareClass, setSelectedFromShareClass] = useState('');
  const [selectedToShareClass, setSelectedToShareClass] = useState('');
  const [amount, setAmount] = useState(0);
  const [selectedShareholder, setSelectedShareholder] = useState({} as Shareholder);
  const [fromShareClasses, setFromShareClasses] = useState([] as string[]);

  const { data } = useQuery<ManagedCompany>(GET_MANAGED_COMPANY_SHAREHOLDERS_STOCK_CLASSES, {
    variables: {
      id: selectedCompanyId
    }
  });

  const shareholders = data ? data.managedCompany.shareholders.collection : [];
  const shareClasses = data ? data.managedCompany.shareClasses : [];

  const { fromShareholder } = props.formData;

  useEffect(() => {
    if (props.formData.dateTime && selectedDateTime !== props.formData.dateTime) {
      setSelectedDateTime(props.formData.dateTime);
    }

    const apiErrorMessages = props.apiErrorsMessages;
    const apiValidationErrors: CreateConvertTransactionErrors = validationError;

    if (apiErrorMessages) {
      apiErrorMessages.forEach((error: ApiValidationError) => {
        (apiValidationErrors as any)[error.fieldName] = error.message;
      });
      setValidationError({ ...apiValidationErrors });
    }
  }, []);

  const onChangeFromShareClass = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAmount(selectedShareholder.shares?.find((share: Share) => share.shareClass === e.target.value)?.amount || 0);
    setSelectedFromShareClass(e.target.value);
  };

  const onChangeToShareClass = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedToShareClass(e.target.value);
  };

  const onChangeShareholder = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const shareholder = shareholders.find((itm: Shareholder) => itm.id === e.target.value) || {} as Shareholder;
    setSelectedShareholder(shareholder);
    const shareClasses = shareholder?.shares?.map((share: Share) => { return share.shareClass; }) ?? [];
    setFromShareClasses(shareClasses);
    setAmount(shareholder.shares?.find((share: Share) => share.shareClass === shareClasses[0])?.amount || 0);
  };

  const onSubmitForm = (event: any) => {
    event.preventDefault();
    const formData = getFormData(event.target.elements);
    const { amount, fromShareClass, toShareClass } = formData;
    formData['amount'] = Number(amount);
    formData['transactionType'] = transactionTypes[2];
    formData['dateTime'] = selectedDateTime;
    formData['fromShareClass'] = fromShareClass;
    formData['toShareClass'] = toShareClass;
    formData['fromShareholder'] = selectedShareholder;

    if (checkValidation(amount, selectedDateTime, selectedShareholder, fromShareClass, toShareClass)) {
      setErrorMessage('');
      history.push({
        pathname: buildAdminUrl('/confirm-transaction'),
        state: { formData }
      });
    }
  };

  const checkValidation = (amount: number, dateTime: string, fromShareholder: Shareholder, fromShareClass: string, toShareClass: string) => {
    let response = true;
    const errors: CreateConvertTransactionErrors = {
      amount: '',
      dateTime: '',
      fromShareholder: '',
      fromShareClass: '',
      toShareClass: ''
    };

    if (!amount) {
      response = false;
      errors.amount = createTransactionErrorMessages.SHARE_AMOUNT_REQUIRED;
    } else if (amount <= 0) {
      response = false;
      errors.amount = createTransactionErrorMessages.SHARE_AMOUNT_GREATER_THAN_ZERO;
    }

    if (!dateTime) {
      response = false;
      errors.dateTime = createTransactionErrorMessages.DATE_AND_TIME_REQUIRED;
    }

    if (!fromShareholder) {
      response = false;
      errors.fromShareholder = createTransactionErrorMessages.SENDING_SHAREHOLDER_REQUIRED;
    }

    if (fromShareClass == toShareClass) {
      response = false;
      errors.fromShareClass = createTransactionErrorMessages.SHARE_CLASS_EQUAL;
      errors.toShareClass = createTransactionErrorMessages.SHARE_CLASS_EQUAL;
    }

    const share = fromShareholder.shares?.find((share: Share) => share.shareClass === fromShareClass);
    if ((share?.amount || 0) < amount) {
      response = false;
      errors.amount = createTransactionErrorMessages.NOT_ENOUGH_SHARES;
    }

    setValidationError(errors);
    return response;
  };

  return (
    <form onSubmit={onSubmitForm}>
      <Select
        name="fromShareholder"
        presetStyle="formSelect"
        placeholder="Konverter fra"
        errorMessage={validationError.fromShareholder}
        defaultValue={fromShareholder?.id}
        icon={IcPerson2}
        emptyOptionsMessage={createTransactionErrorMessages.NO_SHAREHOLDERS_FOUND}
        handleChange={onChangeShareholder}
      >
        {shareholders.map((user: any, idx: number) => {
          return <option key={idx} value={user.id}>{user.name}</option>;
        })}
      </Select>

      <div className="grid grid-cols-2 gap-2.5">
        <div>
          <Select
            name="fromShareClass"
            presetStyle="formSelect"
            icon={IcStockClass}
            value={selectedFromShareClass}
            emptyOptionsMessage={createTransactionErrorMessages.NO_SHARE_CLASSES_FOUND}
            errorMessage={validationError.fromShareClass}
            handleChange={onChangeFromShareClass}
          >
            {fromShareClasses.map((stockClass: any, idx: number) => {
              return <option key={idx}>{stockClass}</option>;
            })}
          </Select>
        </div>

        <div>
          <Select
            name="toShareClass"
            presetStyle="formSelect"
            icon={IcStockClass}
            value={selectedToShareClass}
            emptyOptionsMessage={createTransactionErrorMessages.NO_SHARE_CLASSES_FOUND}
            errorMessage={validationError.toShareClass}
            handleChange={onChangeToShareClass}
          >
            {shareClasses.map((stockClass: any, idx: number) => {
              return <option key={idx}>{stockClass}</option>;
            })}
          </Select>
        </div>
      </div>

      <TextInput
        name="amount"
        placeholder="Antall"
        type="number"
        errorMessage={validationError.amount}
        defaultValue={amount?.toString()}
      />

      <DateTimePicker
        presetStyle="flex rounded-full bg-text-input p-4 w-full"
        options={{ enableTime: true }}
        icon={IcCalendar}
        defaultValue={moment(selectedDateTime, DATE_TIME_FORMAT)}
        onChange={(date: any) => setSelectedDateTime(moment(date[0]).format(DATE_TIME_FORMAT))}
        errorMessage={validationError.dateTime}
      />

      <div className="form-group justify-center mt-8">
        {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
        <button
          className="btn btn-primary btn-block outline-none bg-orange-light"
          type="submit"
        >
          <p className="text-white">Lagre endringer</p>
        </button>
      </div>
    </form>
  );
};

export default ConvertTransactionForm;
