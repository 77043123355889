import _, { isEqual, xorWith } from 'lodash'

export const generateUniqueID = () => {
  return Math.floor(Math.random() * Date.now());
};

export const getAbsoluteHeight = (node: any) => {
  node = typeof node === 'string' ? document.querySelector(node) : node;

  if(node) {
    let height = node.offsetHeight

    if(height) return height

    const element = node.cloneNode(true);
    element.style.visibility = "hidden";
    document.body.appendChild(element);
    height = element.offsetHeight + 0;
    document.body.removeChild(element);
    element.style.visibility = "visible";
    return height;

  }

  return 0

};

export const isArrayEqual = (x: any, y: any) => _.isEmpty(xorWith(x, y, isEqual))
