import React from 'react';

const Skeleton = (): JSX.Element => {
  return (
    <div className="w-full p-6 flex">
      <div className="w-full">
        <div className="flex justify-between w-full pb-6">
          <div className="w-1/4">
            <div className="tile min-h-30 animated-background"></div>
          </div>
          <div className="w-1/5">
            <div className="tile min-h-30 animated-background"></div>
          </div>
        </div>
        <div className="w-full tile min-h-30 animated-background my-4" />
        <div className="w-full tile min-h-30 animated-background mb-4" />
        <div className="w-full tile min-h-30 animated-background mb-4" />
        <div className="w-full tile min-h-30 animated-background mb-4" />
      </div>
    </div>
  );
};

export default Skeleton;
