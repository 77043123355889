import React, { useState } from 'react';
import { useAsyncDebounce, Row } from 'react-table';

interface GlobalFilterProps {
  preGlobalFilteredRows: Row<Record<string, any>>[];
  globalFilter: string;
  setGlobalFilter: (globalFilter: string) => void;
  presetStyle: string;
  isMobile?: boolean;
  filterValue: any;
  setFilterValue: React.Dispatch<any>;
}

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  presetStyle,
  isMobile = false,
  filterValue,
  setFilterValue,
}: GlobalFilterProps): JSX.Element => {
  const count = preGlobalFilteredRows.length;
  // const [value, setValue] = useState(globalFilter);
  const [searchValue, setSearchValue] = useState('');
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        id="table-filter"
        value={filterValue || ''}
        onChange={(e) => {
          setFilterValue(e.target.value);
          onChange(e.target.value);
          setSearchValue(e.target.value);
        }}
        placeholder="type to search"
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
        className={`font-montserrat ${
          !isMobile && 'bg-seven-color absolute right-0 -bottom-10 focus:z-10'
        }  p-2 outline-none transition duration-700 text-base ${
          searchValue.length === 0 && 'opacity-0'
        } focus:opacity-100 ${presetStyle}`}
      />
    </span>
  );
};

export default GlobalFilter;
