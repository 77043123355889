import React from 'react';
import { GopherChart } from '../gopherChart';
import { normalizeValues } from '../../utils/math/math';

interface NumberOfSharesInterface {
  values: { value: number; label: string }[];
  title: string;
  subtitle: string;
}

const NumberOfShares = (props: NumberOfSharesInterface) => {
  const { values, title, subtitle } = props;

  const minBarHeight = 20;
  const maxBarHeight = 140;
  const gopherWidth = 50;

  const normalized = normalizeValues(
    values.map((item) => item.value),
    minBarHeight,
    maxBarHeight,
  );

  return (
    <div className="absolute bottom-0 flex flex-col inset-x-0">
      <div className="flex flex-col items-center">
        <div className="flex">
          {normalized.map((item, index) => {
            return (
              <GopherChart
                key={index}
                height={item}
                width={gopherWidth}
                label={values[index].label}
                style={`gopher-chart-${index}`}
              />
            );
          })}
        </div>
      </div>
      <div className="flex flex-col text-center font-hind font-bold text-xl mt-5">
        <h1 className="mb-0">{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </div>
  );
};

export default NumberOfShares;
