import { gql } from '@apollo/client';

export const GET_MANAGED_COMPANY_SHARE_ISSUANCE_EVENTS = gql`
query ManagedCompanyShareIssuanceEvents(
  $companyId: ID!
  $page: Int
  $limit: Int
  $sort: String
  $search: String
) {
  managedCompany(id: $companyId) {
    shareIssuanceEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
      collection {
        id
        amount
        capitalPerShare
        createdAt
        name
        pricePerShare
        shareClasses
        sharePremium
        eventOn
        type
        publicTransitionEvents {
          transition
          translation
        }
        status {
          background
          label
        }
      }
      metadata {
        totalPages
        totalCount
        currentPage
        limitValue
      }
    }
  }
}
`;

export const GET_OWNED_COMPANY_SHARE_ISSUANCE_EVENTS = gql`
  query OwnedCompanyShareIssuanceEvents(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    ownedCompany(id: $companyId) {
      shareIssuanceEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          capitalPerShare
          createdAt
          name
          pricePerShare
          shareClasses
          sharePremium
          eventOn
          type
          publicTransitionEvents {
            transition
            translation
          }
          status {
            background
            label
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_SUBSCRIBED_COMPANY_SHARE_ISSUANCE_EVENTS = gql`
  query SubscribedCompanyShareIssuanceEvents(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    subscribedCompany(id: $companyId) {
      shareIssuanceEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          capitalPerShare
          createdAt
          name
          pricePerShare
          shareClasses
          sharePremium
          eventOn
          type
          publicTransitionEvents {
            transition
            translation
          }
          status {
            background
            label
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;
