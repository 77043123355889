import { TextInput } from '../../components/textInput';
import React, { useState } from 'react';
import IcPerson from '../../assets/ic-person.svg'
import IcEmail from '../../assets/ic-email.svg'
import ReactLoading from 'react-loading';
import { SignedInUser, UserAccountSettingErrors } from '../../model'
import { isEmailValid } from '../../utils/validators';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_SIGNED_IN_USER, UPDATE_SIGNED_IN_USER } from '../../services/auth'
import { getFormData } from '../../utils/form';
import { dashboardContainer } from '../../utils/constants'

interface PersonalAccountSettingsProps {
  name?: string
  email?: string
}

const PersonalAccountSettings = (props: PersonalAccountSettingsProps):JSX.Element => {

  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setLoading] = useState(false);
  const [validationError, setValidationError] = useState({} as UserAccountSettingErrors)

  const { data } = useQuery<SignedInUser>(GET_SIGNED_IN_USER);

  const [updateUser] = useMutation(UPDATE_SIGNED_IN_USER);

  const onSaveChanges = (event: any) => {
    event.preventDefault()
    const formData = getFormData(event.target.elements)
    const { name, email } = formData
    if (checkValidation(name, email)) {
      setLoading(true)
      setErrorMessage('')
      setLoading(true);
      setErrorMessage('');
      updateUser({
        variables: {
          name
        },
      })
        .then((res) => {
          setLoading(false);
        })
        .catch((err: ApolloError) => {
          setLoading(false);
          setErrorMessage(err.message);
        });
    }
  }

  const checkValidation = (name:string, email: string) => {
    let response = true;
    const errors: UserAccountSettingErrors = {
      name: '',
      email: ''
    };

    if (!email) {
      response = false
      errors.email = 'Please provide email'
    } else if (!isEmailValid(email)) {
      response = false
      errors.email = 'Please provide a valid email'
    }

    if (!name) {
      response = false
      errors.name = 'Please provide name'
    }

    setValidationError(errors);
    return response;
  };

  return (
    <div className={`relative sm:overflow-hidden ${dashboardContainer}`}>
      <h1 className="ml-4 text-left text-4xl">{data?.signedInUser?.name }</h1>
      <form onSubmit={onSaveChanges}>
        <TextInput
          name={"name"}
          type={"text"}
          label={"Navn"}
          icon={IcPerson}
          placeholder={"Navn"}
          errorMessage={validationError.name}
          defaultValue={data?.signedInUser.name} />

        <TextInput
          name={"email"}
          type={"email"}
          label={"E-post adresse"}
          icon={IcEmail}
          placeholder={"E-post adresse"}
          errorMessage={validationError.email}
          defaultValue={data?.signedInUser.email}
          disabled={true}
        />

        <div className="form-group justify-center mt-8">
          {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
          <button
            className="btn btn-primary btn-block outline-none bg-orange-light"
            type="submit"
            id="login-btn"
          >
            {submitting ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#4B2A58"
              />
            ) : (
              <p className="text-white">Save changes</p>
            )}
          </button>
        </div>

      </form>

    </div>
  )
}

export default PersonalAccountSettings;
