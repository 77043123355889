import React, { useState, useEffect } from 'react';
import { Select } from '../../components/select';
import { transactionTypes } from '../../utils/constants'
import { TransferTransactionForm } from '../../components/transaction/create/transfer'
import { SplitTransactionForm } from '../../components/transaction/create/split'
import { ConvertTransactionForm } from '../../components/transaction/create/convert';
import { useHistory } from 'react-router-dom'

const CreateTransaction = (): JSX.Element => {
  const history = useHistory()

  const {
    location: { state },
  }: any = history;

  const formData = state?.formData ? state.formData : {}
  const apiErrorsMessages = state?.apiErrorsMessages ? state.apiErrorsMessages : []

  const [transactionType, setTransactionType] = useState(transactionTypes[0])

  const onChangeTransactionType = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setTransactionType(event.target.value)
  }

  useEffect(() => {
    if (state?.formData?.transactionType && state.formData.transactionType != transactionType) {
      setTransactionType(state.formData.transactionType)
    }
  }, [])

  const renderForm = () => {
    switch (transactionType) {
    case 'Aksjetransaksjon':
      return <TransferTransactionForm
        apiErrorsMessages={ apiErrorsMessages }
        formData={formData}/>
    case 'Splitt':
      return <SplitTransactionForm
        apiErrorsMessages={ apiErrorsMessages }
        formData={formData}/>
    case 'Konvertering':
      return <ConvertTransactionForm
        apiErrorsMessages={ apiErrorsMessages }
        formData={formData}/>
    }
  }

  return (
    <div className="p-8">
      <h1 className="ml-4 text-left text-4xl">Opprett transaksjon</h1>
      <Select
        name={'transactionType'}
        presetStyle="formSelect"
        handleChange={onChangeTransactionType}
        defaultValue={state?.formData ? state.formData.transactionType : transactionTypes[0]}
      >
        {
          transactionTypes.map((transactionType, idx) => {
            return <option key={idx} >{transactionType}</option>
          })
        }
      </Select>

      {renderForm()}

    </div>
  );
};

export default CreateTransaction;
