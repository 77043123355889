import React from 'react';
import IconEmail from '../../../assets/images/icon-email.png';
import { Link } from 'react-router-dom';

interface ConfirmInputProp {
  email: string;
}

const Confirm = ({ email }: ConfirmInputProp): JSX.Element => (
  <>
    <h1 className="page-title mb-4 font-hind">
      Sjekk eposten din for en bekreftelses link
    </h1>
    <p className="text-center">
      Vi har sendt en link til <b>{email}</b>
    </p>
    <p className="text-center">Klikk på linken for å bekrefte epost din</p>
    <div className="text-center my-4 flex justify-center items-center">
      <img src={IconEmail} alt="" />
    </div>
    <p className="text-center">
      Har du ikke mottatt epost med bekreftelsen{' '}
      <Link to="/" className="underline">
        klikk her
      </Link>
    </p>
  </>
);

export default Confirm;
