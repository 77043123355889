import React, { useEffect, useState } from 'react'
import { BoardMeetingAgenda } from '../../../model/boardMeetingAgenda'
import { Draggable } from 'react-beautiful-dnd'

interface BoardMeetingAgendaCardInterface {
  boardMeetingAgenda: BoardMeetingAgenda
  onEdit: (position: number) => void
  onDelete: (position: number) => void
  index: number
}
const BoardMeetingAgendaCard = (props: BoardMeetingAgendaCardInterface) => {
  const { boardMeetingAgenda, onEdit, onDelete, index } = props

  return (
    <Draggable draggableId={index.toString()} index={index}>
      {
        (provided: any) => (
          <div className="flex items-center bg-white p-4 rounded-xl my-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
            <i className="uc-icon text-first-text-color text-2xl leading-6 h-6 w-6 mr-2" >&#xeb48;</i>
            <div className="flex flex-col flex-1 ml-8">
              <p className="font-bold text-2xl mb-2">{boardMeetingAgenda.name}</p>
              <div>
                <pre id="content">{boardMeetingAgenda.description}</pre>
              </div>
            </div>
            <div className="flex gap-4">
              <div className="relative h-6 w-6">
                <button className="absolute h-6 w-6 z-20 inset-0 focus:outline-none" onClick={ () => { onEdit(index)}} ></button>
                <i className="uc-icon text-first-text-color text-2xl leading-5 h-6 w-6">&#xe973;</i>
              </div>
              <div className="relative">
                <button className="absolute inset-0 h-6 w-6 z-20 focus:outline-none" onClick={() => { onDelete(index) }} ></button>
                <i className="uc-icon text-red-500 text-2xl leading-5 h-6 w-6">&#xeb97;</i>
              </div>
            </div>
          </div>
        )
      }

    </Draggable>
  )
}

export default BoardMeetingAgendaCard
