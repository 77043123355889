/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { BackendTable, Button, Modal } from '../../components';
import { dashboardContainer, paginationElements } from '../../utils/constants';
import {
  ManagedCompany,
  ShareholderVar,
  Shareholder,
  ShareholderTypes,
  PagingMetadata,
  OwnedCompany,
  SubscribedCompany,
} from '../../model';
import { GET_MANAGED_COMPANY_SHAREHOLDERS, GET_OWNED_COMPANY_SHAREHOLDERS, GET_SUBSCRIBED_COMPANY_SHAREHOLDERS } from '../../services/company';
import { addRowNum } from '../../utils/formatData';
import { useUrlBuilder } from '../../hooks/urlBuilder';
import { Role } from '../../utils/constants';
import _ from 'lodash';
import { UserIcon } from '@heroicons/react/outline';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import UpdateIndividualShareholder from './updateIndividualShareholder';
import { onError } from '@apollo/client/link/error';

const ShareHolders = (): JSX.Element => {
  const { selectedCompanyId, getRole } = useUrlBuilder();

  const [pageMetaData, setPageMetaData] = useState({
    currentPage: paginationElements.INITIAL_PAGE, limitValue: paginationElements.LIMIT
  } as PagingMetadata);
  const [sort, setSort] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [columns, setColumns] = useState(null as any as any[]);
  const [tableData, setTableData] = useState([] as any[]);
  const [selectedShareholder, setSelectedShareholder] = useState(null as any);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialColumns = [
    {
      Header: 'Navn',
      accessor: 'name',
      disableSortBy: false,
      isExported: true,
      Cell: (props: any) => {
        return (
          <div className="flex items-center">
            {props.cell.row.original.shareholderType === ShareholderTypes.COMPANY ? (
              <OfficeBuildingIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            ) : (
              <UserIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            )}
            <span className="ml-2">{props.cell.row.original.name}</span>
          </div>
        );
      },
    },
    {
      Header: 'Epost',
      accessor: 'email',
      disableSortBy: false,
      isExported: true,
    },
    {
      Header: 'Telefon',
      accessor: 'phone',
      disableSortBy: false,
      isExported: true,
    },
    {
      Header: 'Andel',
      accessor: 'sharePercentage',
      disableSortBy: true,
      isExported: true,
    },
    {
      Header: 'City',
      accessor: 'city',
      disableSortBy: false,
      isExported: true,
      isHidden: true,
    },
    {
      Header: 'Street Address',
      accessor: 'streetAddress',
      disableSortBy: false,
      isExported: true,
      isHidden: true,
    },
    {
      Header: 'Post Code',
      accessor: 'postCode',
      disableSortBy: false,
      isExported: true,
      isHidden: true,
    },
  ];

  const createRow = (shareholder: Shareholder, totalShares: number, shareClasses: string[]) => {
    const shares: {
      [key: string]: number;
    } = {};
    let sharesInCompany = 0.0;
    shareClasses.forEach((shareClass) => {
      const share = shareholder.shares.find((share) => share.shareClass == shareClass);
      shares[shareClass] = share?.amount || 0;
      sharesInCompany += share?.amount || 0;
    });

    return {
      name: shareholder.name,
      email: shareholder.email,
      phone: shareholder.phone,
      streetAddress: shareholder.streetAddress,
      city: shareholder.city,
      postCode: shareholder.postCode,
      sharePercentage: ((sharesInCompany / totalShares) * 100).toFixed(2) + "%",
      shareholderType: shareholder.shareholderType,
      shareholder: shareholder,
      ...shares
    };
  };

  const [getManagedCompanyShareHolders, { error: managedCompanyShareholdersError, loading: isManagedLoading }] = useLazyQuery<
    ManagedCompany,
    ShareholderVar
  >(
    GET_MANAGED_COMPANY_SHAREHOLDERS,
    {
      fetchPolicy: 'network-only',

      onCompleted: (result) => {
        setPageMetaData(result.managedCompany.shareholders.metadata);
        const shareholders = addRowNum(result.managedCompany.shareholders.collection);

        const shareClasses = result.managedCompany.shareClasses;
        const columns = [...initialColumns];

        shareClasses.forEach((shareClass, index) => {
          columns.push({ Header: shareClass, accessor: shareClass, disableSortBy: true, isExported: true, });
        });

        const data: any[] = [];

        shareholders.forEach((shareholder: Shareholder) => {
          data.push(createRow(shareholder, result.managedCompany.totalShares, shareClasses));
        });

        setColumns(columns);
        setTableData(data);
      }
    },
  );

  const [getOwnedCompanyShareHolders, { error: ownedCompanyShareholdersError, loading: isOwnedLoading }] = useLazyQuery<
    OwnedCompany,
    ShareholderVar
  >(
    GET_OWNED_COMPANY_SHAREHOLDERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        setPageMetaData(result.ownedCompany.shareholders.metadata);
        const shareholders = addRowNum(result.ownedCompany.shareholders.collection);

        const shareClasses = result.ownedCompany.shareClasses;
        const columns = [...initialColumns];

        shareClasses.forEach((shareClass, index) => {
          columns.push({ Header: shareClass, accessor: shareClass, disableSortBy: true, isExported: true, });
        });

        const data: any[] = [];

        shareholders.forEach((shareholder: Shareholder) => {
          data.push(createRow(shareholder, result.ownedCompany.totalShares, shareClasses));
        });

        setColumns(columns);
        setTableData(data);
      }
    },
  );

  const [getSubscribedCompanyShareHolders, { error: subscribedCompanyShareholdersError, loading: isSubscribedLoading }] = useLazyQuery<
    SubscribedCompany,
    ShareholderVar
  >(
    GET_MANAGED_COMPANY_SHAREHOLDERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        setPageMetaData(result.subscribedCompany.shareholders.metadata);
        const shareholders = addRowNum(result.subscribedCompany.shareholders.collection);

        const shareClasses = result.subscribedCompany.shareClasses;
        const columns = [...initialColumns];

        shareClasses.forEach((shareClass, index) => {
          columns.push({ Header: shareClass, accessor: shareClass, disableSortBy: true, isExported: true, });
        });

        const data: any[] = [];

        shareholders.forEach((shareholder: Shareholder) => {
          data.push(createRow(shareholder, result.subscribedCompany.totalShares, shareClasses));
        });

        setColumns(columns);
        setTableData(data);
      }
    },
  );

  const getShareholders = () => {
    const role = getRole();

    switch (role) {
    case Role.ADMIN:
      getManagedCompanyShareHolders({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage,
          limit: pageMetaData.limitValue,
          sort,
          search: searchValue,
        },
      });
      break;
    case Role.SUBSCRIBER:
      getSubscribedCompanyShareHolders({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage,
          limit: pageMetaData.limitValue,
          sort,
          search: searchValue,
        },
      });
      break;
    case Role.SHAREHOLDER:
      getOwnedCompanyShareHolders({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage,
          limit: pageMetaData.limitValue,
          sort,
          search: searchValue,
        },
      });
      break;
    }
  };

  if (columns && getRole() == Role.ADMIN) {
    columns.push(
      {
        Header: '',
        accessor: 'actions',
        Cell: (props: any) => {
          return (
            <Button
              label={<i className="uc-icon text-grey">&#xe972;</i>}
              type="button-5"
              toExecute={() => {
                setSelectedShareholder(props.cell.row.original.shareholder);
                setIsModalOpen(true);
              }}
            />
          );
        },
      },
    );
  }

  useEffect(() => {
    setPageMetaData(
      {
        ...pageMetaData,
        currentPage: paginationElements.INITIAL_PAGE,
        limitValue: paginationElements.LIMIT
      }
    );
    if (selectedCompanyId && !managedCompanyShareholdersError && !ownedCompanyShareholdersError && !subscribedCompanyShareholdersError) {
      getShareholders();
    }
  }, [selectedCompanyId]);

  const onUpdateSuccess = (_: Shareholder) => {
    getShareholders();
  };

  return (
    <div className={dashboardContainer}>
      <div className="mb-4">
        <span className="font-hind font-bold text-5xl">Aksjonærer</span>
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="lg"
      >
        <UpdateIndividualShareholder shareholder={selectedShareholder} onUpdateSuccess={onUpdateSuccess} closeModal={() => setIsModalOpen(false)} />
      </Modal>
      {columns && tableData && (<BackendTable
        columns={columns}
        data={tableData}
        searchable
        paginate
        tableHeader
        onTableChange={() =>
          getShareholders()
        }
        setPageMetaData={setPageMetaData}
        pageMetaData={pageMetaData}
        setSortValue={setSort}
        sortValue={sort}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isLoading={isManagedLoading || isOwnedLoading || isSubscribedLoading}
        fileName="shareHolders"
      />)
      }
    </div>
  );
};

export default ShareHolders;
