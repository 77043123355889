import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ClientSession from '../utils/clientSession';
import { GET_PREVIOUS_PATH } from '../services/routing'
import { useQuery } from '@apollo/client'

interface ProtectedRoute {
  path: string;
  key: number;
  exact: boolean;
  render: (props: unknown) => JSX.Element;
}

const PublicRoute = ({ path, ...rest }: ProtectedRoute): JSX.Element => {
  const { data } = useQuery(GET_PREVIOUS_PATH)
  const guestRoutes = ['/login', '/signup', '/'];

  if(guestRoutes.includes(path) && ClientSession.isLoggedIn() && data.previousPath && data.previousPath !== '/' ) {
    return <Redirect to={data.previousPath} />
  }

  return <Route path={path} {...rest} />

};

export default PublicRoute;
