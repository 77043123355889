import React from 'react';

export interface SelectComponentProps {
  errorMessage?: string;
  emptyOptionsMessage?: string
  handleChange?: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
  txtPlaceholder?: string;
  id?: string;
  label?: string;
  icon?: string;
  name: string;
  value?: string;
  presetStyle?: string;
  children?: JSX.Element | any;
  placeholder?: string
  defaultValue?: string
}

const Select = ({
  errorMessage,
  emptyOptionsMessage,
  handleChange,
  id,
  name,
  label,
  icon,
  value,
  presetStyle,
  placeholder,
  children,
  defaultValue
}: SelectComponentProps): JSX.Element => {
  return (
    <div className="mt-8 relative">
      <div className="pl-4 mb-1 font-bold">{label}</div>
      <div className={`flex rounded-full bg-form-control p-4 w-full ${ errorMessage ? 'border-primary-red border-2': null}`}>
        <img src={icon} className="" />
        <select
          className={`bg-form-control flex flex-1 ml-1 focus:outline-none placeholder-default w-inherit ${presetStyle}`}
          value={value}
          onChange={handleChange}
          name={name}
          id={id}
          defaultValue={defaultValue}
        >
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>)}
          {
            children.length === 0
              ? <option disabled>{emptyOptionsMessage}</option>
              : children
          }
        </select>
      </div>
      <div className="text-right pr-4 pt-2 form-group">
        {errorMessage && <p className="form-error">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Select;
