import React, { useState } from 'react'
import { useParams, Route } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { RouteAccessResult, CheckAccessVar } from '../model/user'
import { CHECK_ACCESS } from '../services/auth'
import { menuItems } from '../utils/constants'
import { useLocation } from 'react-router-dom';
import { setIsCheckingAccessCompleted, setLoading } from '../store/cache'

export const useRouteCheck = (onComplete: (checkAccess: any) => void) => {
  const location = useLocation()

  const  [checkRouteAccess] = useLazyQuery<RouteAccessResult, CheckAccessVar>(CHECK_ACCESS, {
    fetchPolicy: 'network-only',
    onCompleted: (checkAccess: any) => {
      onComplete(checkAccess)
    },
    onError:(error)=>{
      setIsCheckingAccessCompleted(true)
      setLoading(false)
    }
  })

  const checkAccess = (companyId: string, skipCurrentRoute?: boolean) => {
    const routesToCheck = menuItems.flatMap((menuItem) => {
      return menuItem.menus.map((item) => {
        const route = item.route
        return route.replace(':companyId', companyId)
      })
    })

    checkRouteAccess({ variables: {
      // add the current location to the paths to be checked
      paths:  skipCurrentRoute ? routesToCheck : [location.pathname, ...routesToCheck]
    }})

  }

  const checkCurrentRoute = () => {
    checkRouteAccess({ variables: {
      paths: [location.pathname]
    }})

  }

  return { checkAccess, checkCurrentRoute }
}
