import React from 'react';
import { useParams } from 'react-router-dom';

import ImgInvestingBook from '../../../assets/images/investingbooks.png';
import { primaryContainer } from '../../../utils/constants';
import { useQuery } from '@apollo/client';
import { USER_CONFIRM_ACCOUNT } from '../../../services/auth';
import { LoginPage } from '../../../views/Auth/Login';

interface TokenParamTypes {
  token: string
}

interface ConfirmationData {
  userConfirmAccount: boolean
}

const ConfirmPage = (): JSX.Element => {
  const { token } = useParams<TokenParamTypes>();

  const { loading, error, data } = useQuery<ConfirmationData>(USER_CONFIRM_ACCOUNT, {
    variables: { confirmationToken: token },
  });

  if (loading) return (<>Loading...</>);
  if (data?.userConfirmAccount) return (<LoginPage notice="Gratulere, du kan nå logge inn!" />)

  const message = error || (<div>
    <p>Obs! Noe gikk galt.</p>
    <p>Kontakt support på <a href="mailto:support@investorbok.no">support@investorbok.no</a> for manuell bekreftelse.</p>
  </div>)

  return (
    <section className="h-screen relative">
      <div className="bg-eighth-color h-full overflow-y-auto">
        <div className={`${primaryContainer} py-36`}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="hidden lg:flex justify-center items-center">
              <img
                src={ImgInvestingBook}
                className="w-60 lg:w-96 mt-8 lg:mt-0"
                alt=""
              />
            </div>
            <div className="flex text-center">
              {message}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConfirmPage;
