import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useLayout } from '../../../hooks/layout';
import Tippy from '@tippyjs/react';
interface SidebarNavItemInterface {
  icon: any;
  label: string;
  route: string;
  separator?: any;
  onClickNavItem?: any;
  isMobile?: boolean;
}

const SidebarNavItem = (props: SidebarNavItemInterface): JSX.Element => {
  const history = useHistory();

  const { icon, label, route, onClickNavItem, isMobile = false } = props;
  const { sidebarCollapsed } = useLayout();
  let { separator } = props;
  const isSelected = history.location.pathname == route;

  separator = separator
    ? separator.position == 'TOP'
      ? 'border-t-2 border-menu-item-separator'
      : 'border-b-2 border-menu-item-separator'
    : 'border-none';

  return (
    <div
      className={`${separator}`}
      onClick={() => {
        onClickNavItem && onClickNavItem(label);
      }}
    >
      <NavLink to={route}>
        <Tippy
          content={label}
          placement="right"
          onShow={() => {
            if (!sidebarCollapsed) return false;
          }}
        >
          <div
            className={`items-center cursor-pointer flex my-2 py-2  ${
              isSelected ? 'md:bg-selected-nav' : null
            } ${
              isSelected && sidebarCollapsed && !isMobile ? 'md:px-2' : null
            } ${
              sidebarCollapsed && !isMobile
                ? 'rounded-full w-10 h-10 mx-auto'
                : 'rounded-full'
            } `}
          >
            <img
              src={icon}
              className={` h-6 w-6 ${
                sidebarCollapsed && !isMobile ? 'mx-auto' : 'mx-4'
              }`}
              alt={label}
            />
            <span
              className={`text-seven-color ${
                sidebarCollapsed && !isMobile ? 'hidden' : null
              }  `}
            >
              {label}
            </span>
          </div>
        </Tippy>
      </NavLink>
    </div>
  );
};

export default SidebarNavItem;
