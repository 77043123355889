import React from 'react';
import { Helmet } from 'react-helmet';

interface PageHeadProps {
  title?: string;
  description?: string;
  noFollow: boolean;
}

const PageHead = ({
  title,
  description,
  noFollow,
}: PageHeadProps): JSX.Element => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={noFollow ? 'nofollow' : description} />
      <meta
        property="og:description"
        content={noFollow ? 'nofollow' : description}
      />
      <meta
        property="twitter:description"
        content={noFollow ? 'nofollow' : description}
      />
    </Helmet>
  );
};

export default PageHead;
