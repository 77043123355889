import React from 'react'

interface CheckboxInterface {
  id?: string
  name: string
  label?: string
  onChange?: any
  checked?: boolean
}
const Checkbox = (props: CheckboxInterface) => {
  const { id, name, onChange, label, checked } = props

  return (
    <div>
      <input type="checkbox" id={id}  onChange={onChange} name={name} checked={checked}  />
      <label className="ml-2" htmlFor={name} >{label}</label>
    </div>
  )
}

export default Checkbox
