import { gql } from '@apollo/client';

export const GET_FUNDING_EVENT_DETAIL = gql`
query getFundingEventDetail($id: ID!) {
  fundingEvent(id: $id){
    id
    name
    description
    amount
    pricePerShare
    sharePremium
    capitalPerShare
    shareClasses
    reparation
    eventOn
    payableBy
    referenceText
  }
}
`;

export const CREATE_FUNDING_EVENT = gql`
  mutation createFundingEvent($attributes: FundingEventInput!) {
    createFundingEvent(
      attributes: $attributes
    ) {
      id
    }
  }
`;

export const UPDATE_FUNDING_EVENT = gql`
  mutation updateFundingEvent(
    $attributes: FundingEventInput!
  ) {
    updateFundingEvent(
      attributes: $attributes
    ) {
      id
      name
      description
      amount
      pricePerShare
      sharePremium
      capitalPerShare
      shareClasses
      reparation
      eventOn
      payableBy
      referenceText
    }
  }
`;

export const DELETE_FUNDING_EVENT = gql`
  mutation deleteFundingEvent($id: ID!) {
    deleteFundingEvent(
      id: $id
    ) {
      id
    }
  }
`;

export const TRANSITION_FUNDING_EVENT_STATUS = gql`
mutation($shareIssuanceEventId: ID!,$transition:String!){
  transitionShareIssuanceEvent(shareIssuanceEventId:$shareIssuanceEventId, transition:$transition){
    id
    publicTransitionEvents{
      transition
      translation
    }
    status{
      background
      label
    }
  }
}
`;
