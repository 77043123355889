import React from 'react';
import { ButtonHolder, ButtonLabel } from './styles';

type buttonType = "button" | "submit" | "reset"
export interface ButtonComponentProps {
  label: string | JSX.Element;
  toExecute?: any;
  size?: string;
  type?: string;
  presetStyle?: string;
  id?: string;
  presetLabelStyle?: string;
  buttonType?: buttonType
}

const ButtonComponent = (props: ButtonComponentProps): JSX.Element => {
  return (
    <ButtonHolder
      onClick={props.toExecute}
      className={`cursor-pointer transition duration-300 focus:outline-none hover:opacity-80 flex justify-center items-center ${props.presetStyle}`}
      size={props.size}
      buttonType={props.type}
      id={props.id}
      type={props.buttonType}
    >
      <ButtonLabel
        buttonType={props.type}
        className={`text-base font-hind capitalize text-base font-bold leading-6 tracking-normal ${props.presetLabelStyle}`}
      >
        {props.label}
      </ButtonLabel>
    </ButtonHolder>
  );
};

export default ButtonComponent;
