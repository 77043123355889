import React, { useState } from 'react';

const LoadingPage = ():JSX.Element => {

  return (
    <div className="flex h-full items-center justify-center">
      <h1>Loading...</h1>
    </div>
  )
}

export default LoadingPage;
