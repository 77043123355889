import React from 'react';
import { Notification } from './components/notification';
import { LayoutProvider } from './context/layoutContext';
import { NotificationsProvider } from "./context/notificationContext";
import 'tippy.js/dist/tippy.css';

import { AppRoute } from './router';

function App(): JSX.Element {
  return (
    <>
      <LayoutProvider>
        <NotificationsProvider>
          <Notification position='top-right' notificationShowTime={3000} />
          <AppRoute />
        </NotificationsProvider>
      </LayoutProvider>
    </>
  )
}

export default App;
