import { InMemoryCache, makeVar, ReactiveVar } from '@apollo/client';
import { GraphqlQueryError } from 'src/model/common';
import { Company, User } from '../model';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loggedInUser: {
          read() {
            return loggedInUserVar();
          },
        },
        isLoading: {
          read() {
            return setLoading();
          },
        },
        selectedCompany: {
          read() {
            return selectedCompanyVar();
          },
        },
        callBackLink: {
          read() {
            return setCallBackLink();
          },
        },
        graphQLQueryError: {
          read() {
            return setGraphQLQueryError();
          },
        },
        previousPath: {
          read() {
            return setPreviousPath();
          },
        },
        isCheckingAccessCompleted: {
          read() {
            return setIsCheckingAccessCompleted();
          },
        },
        isFormDirty: {
          read() {
            return setIsFormDirty();
          },
        },
        showSetupCompany: {
          read() {
            return setShowSetupCompany()
          }
        }
      },
    },
  },
});

export const loggedInUserVar: ReactiveVar<User> = makeVar<User>({} as User);

export const setLoading = makeVar<boolean>(true);

export const selectedCompanyVar: ReactiveVar<Company> = makeVar<Company>(
  {} as Company,
);

export const setCallBackLink = makeVar<string>('');

export const setGraphQLQueryError = makeVar({} as GraphqlQueryError);

export const setPreviousPath = makeVar('');

export const setIsCheckingAccessCompleted = makeVar<boolean>(false);

export const setIsFormDirty = makeVar<boolean>(false);

export const setShowSetupCompany = makeVar<boolean>(false);
