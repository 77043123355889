import { Dividend } from '../../components/dividend';
import React, { useState, useEffect } from 'react';
import { ProfitBeforeTax } from '../../components/profitBeforeTax';
import { NumberOfShares } from '../../components/numberOfShares';
import { formatNumber } from '../../utils/formatNumber';
import { ShareCapitalChart } from '../../components/shareCapitalChart';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Table } from '../../components'
import { toLocaleDateTime } from '../../utils/formatTime'
import { eventTypeIcon, Role } from '../../utils/constants'
import { ManagedCompany, OwnedCompany, SubscribedCompany, CompanyVar } from '../../model/company'
import { GET_OWNED_COMPANY_DETAIL, GET_MANAGED_COMPANY_DETAIL, GET_SUBSCRIBED_COMPANY_DETAIL } from '../../services/company'
import { Company } from '../../model/user'
import { useUrlBuilder } from '../../hooks/urlBuilder'
import _ from 'lodash'

const Dashboard = (): JSX.Element => {
  const [companyDetail, setCompanyDetail] = useState({} as Company)
  const { selectedCompanyId, getRole } = useUrlBuilder()
  const LATEST_EVENTS_PAGE_SIZE = 10

  const [getManagedCompanyDetail] = useLazyQuery<ManagedCompany, CompanyVar>(
    GET_MANAGED_COMPANY_DETAIL,
    {
      onCompleted: (manageCompanyDetail) => {
        setCompanyDetail(manageCompanyDetail.managedCompany)
      },
      onError: () => setCompanyDetail({} as Company),
    },
  );

  const [getOwnedCompanyDetail] = useLazyQuery<OwnedCompany, CompanyVar>(
    GET_OWNED_COMPANY_DETAIL,
    {
      onCompleted: (ownedCompanyDetail) => {
        setCompanyDetail(ownedCompanyDetail.ownedCompany)
      },
      onError: () => setCompanyDetail({} as Company),
    },
  );

  const [getSubscribedCompanyDetail] = useLazyQuery<SubscribedCompany, CompanyVar>(
    GET_SUBSCRIBED_COMPANY_DETAIL,
    {
      onCompleted: (subscribedCompanyDetail) => {
        setCompanyDetail(subscribedCompanyDetail.subscribedCompany)
      },
      onError: () => setCompanyDetail({} as Company),
    },
  );

  const getCompanyDetail = () => {
    const role = getRole()

    switch (role) {
    case Role.ADMIN:
      getManagedCompanyDetail({
        variables: {
          id: selectedCompanyId,
        },
      });
      break
    case Role.SUBSCRIBER:
      getSubscribedCompanyDetail({
        variables: {
          id: selectedCompanyId,
        },
      });
      break
    case Role.SHAREHOLDER:
      getOwnedCompanyDetail({
        variables: {
          id: selectedCompanyId,
        },
      });
      break
    }
  }

  useEffect(() => {
    if (selectedCompanyId)
      getCompanyDetail()
  }, [selectedCompanyId, companyDetail]);

  const shareCapitalData = {
    labels: ['2015', '2016', '2017', '2018', '2019'],
    datasets: [
      {
        label: '',
        data: [0, 0, 100, 120, 900],
        fill: true,
        backgroundColor: '#6C3483',
        borderColor: '#6C3483',
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      axis: 'xy',
      mode: 'nearest',
    },
    radius: 0, // intersection point dot radius
    plugins: {
      legend: { // Disables legend
        display: false
      },
      tooltip: {
        display: true,
        position: 'nearest',
        backgroundColor: '#A385AE',
        padding: 10,
        cornerRadius: 10,
        footerAlign: 'center',
        titleAlign: 'center',
        callbacks: {
          label: (context: any) => {
            return ''
          },
          title: (context: any) => {
            const shareAmount = context[0].formattedValue
            return formatNumber('nb-NO', shareAmount)
          },
          footer: (context: any) => {
            return context[0].label
          }
        }
      }
    },
    elements: {
      line: { // makes the line smooth
        tension: 0.5
      }
    },
    scales: {
      x: {
        display: true, // Dispaly the x- Axis
        title: {
          display: false, // Don't display title
          text: 'Year'
        },
        grid: {
          display: false // Removes the grid
        }
      },
      y: {
        display: false, // Don't Display Y - Axis
        title: {
          display: true,
          text: 'Share Capital'
        }
      }
    }
  };

  const columns = [
    {
      Header: '#',
      accessor: 'icon',
      Cell: function Icon(props : any) {
        return <img src={(eventTypeIcon as any)[props.cell.value]} className='w-6 h-6' />
      }
    },
    {
      Header: 'time',
      accessor: 'time'
    },
    {
      Header: 'description',
      accessor: 'description',
      Cell: function Icon(props : any) {
        return <p className="font-bold one-line-clamp">{props.cell.value}</p>
      }
    }
  ];

  // TODO this is sample data. This shall be replaced from data coming from the backend.
  const tableData = Array.from({ length: LATEST_EVENTS_PAGE_SIZE}, () => {
    return {
      icon: 'transfer',
      time: '02.20.2021',
      description: '35 Aksjer ble kjøpt av Ole Morten '
    }
  } )
  return (
    <div className="relative h-full w-full">
      <div className="flex flex-col h-full pt-10">
        <div id="topContentContainer" className="flex-1 pb-8 mb-10 px-11">
          {
            !_.isEmpty(companyDetail) && <>
              <h1 className="inline-block text-left mb-0" >{companyDetail.name}</h1>
              { companyDetail.registrationDate && <p className="text-dark-orange font-bold mb-5">Stiftet: {toLocaleDateTime(companyDetail.registrationDate, 'L')}</p>}
            </>
          }
          <div className="grid grid-cols-2 mt-5">
            <div className="col-span-2 xl:col-span-1  order-2 xl:order-1 xl:mr-4 mb-8 xl:mb-10">
              <p className="mb-4 font-bold mt-5 xl:mt-0">Siste hendelser</p>
              <Table columns={columns} data={tableData} presetStyle={"latestEventsRowStyle"} initialPageSize={LATEST_EVENTS_PAGE_SIZE} />
            </div>
            <div className="relative p-4 bg-light-shiro shadow-share-capital col-span-2 xl:col-span-1 order-1 xl:order-2 rounded-3xl mb-4 xl:mb-10 min-h-300" >
              <ShareCapitalChart data={shareCapitalData} options={options} shareCapital={15000000} />
            </div>
          </div>
        </div>
        <div className="relative bottom-0 inset-x-0 mt-auto px-11 pb-5">
          <div id="bottomContainer" className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-10 2xl:gap-20">
            <div className="relative lg:col-span-1 h-64 rounded-3xl bg-menu-item-separator flex flex-col">
              <Dividend
                values={[{ value: 356, label: '2019' }, { value: 2568, label: '2020' }]}
                title={'350.00'}
                subtitle={'Antall aksjer'} />
            </div>

            <div className="relative lg:col-span-1 h-64 rounded-3xl bg-menu-item-separator flex flex-col mt-16 xl:mt-0">
              <ProfitBeforeTax
                values={[{ value: 2568, label: '2019' }, { value: 656, label: '2020' }]}
                title={'400.00'}
                subtitle={'Resultat før skatt (NOK)'} />
            </div>

            <div className="relative lg:col-span-1 h-64 rounded-3xl bg-menu-item-separator flex flex-col mt-16 2xl:mt-0">
              <NumberOfShares
                values={[{ value: 2568, label: '2019' }, { value: 656, label: '2020' }]}
                title={'400.00'}
                subtitle={'Antall aksjer'} />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Dashboard;

