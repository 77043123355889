
import React, { useEffect } from 'react'
import HomeNav from '../../components/homePage/homeNav';
import { GET_ACCESS_CHECK_STATUS } from '../../services/auth'
import { useQuery } from '@apollo/client'
import { setLoading } from '../../store/cache'

const DefaultLayout = (props: any) => {

  const { data: accessCheck } = useQuery(GET_ACCESS_CHECK_STATUS)

  useEffect(() => {
    if(accessCheck?.isCheckingAccessCompleted) {
      setLoading(false)
    }
  },[accessCheck])

  return (
    <>
      <HomeNav />
      <props.component />
    </>
  )

}

export default DefaultLayout
