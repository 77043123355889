import React from 'react';
import FoundingEventForm from './form';
import { useParams } from 'react-router-dom';

const CreateFoundingEvent = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="p-8">
      <div className="mb-4 p-4 pl-2 flex">
        <div className="flex flex-col">
          <h2 className="font-hind font-bold text-5xl">
            {id ? 'Rediger' : 'Ny'} Innskudd Aksjekapital
          </h2>
        </div>
      </div>
      <FoundingEventForm />
    </div>
  );
};

export default CreateFoundingEvent;
