import React, { useState, useEffect } from 'react';

interface SwitchButtonProps {
  isChecked: boolean;
  label: string;
  onClick?: (event: any) => void;
  onChange?: () => void
}

const SwitchButton = ({
  isChecked,
  label,
  onChange,
  onClick,
}: SwitchButtonProps): JSX.Element => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const toggleCheck = () => {
    onChange && onChange()
    setChecked(!checked)
  }

  return (
    <div className="flex items-center">
      <label className="switch">
        <input type="checkbox" onChange={toggleCheck} checked={checked} onClick={ (e: any) => onClick && onClick(e)} />
        <span className="slider round"></span>
      </label>
      <h4 className="ml-2">{label}</h4>
    </div>
  );
};

export default SwitchButton;
