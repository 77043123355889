import { gql } from '@apollo/client';

export const GET_BOARD_MEETING_AGENDAS = gql`
  query getBoardMeetingAgendas($boardMeetingId: ID!) {
    boardMeetingAgendas(boardMeetingId: $boardMeetingId) {
      id
      position
      name
      description
    }
  }
`;

export const CREATE_BOARD_MEETING_AGENDA = gql`
  mutation createBoardMeetingAgenda(
    $attributes: BoardMeetingAgendaInput!
  ) {
    createBoardMeetingAgenda(
      attributes: $attributes
    ) {
      id
    }
  }
`;

export const UPDATE_BOARD_MEETING_AGENDA = gql`
  mutation updateBoardMeetingAgenda(
    $attributes: BoardMeetingAgendaInput!
  ) {
    updateBoardMeetingAgenda(
      attributes: $attributes
    ) {
      name
      description
      position
    }
  }
`;

export const DELETE_BOARD_MEETING_AGENDA = gql`
  mutation deleteBoardMeetingAgenda(
    $id: ID!
  ) {
    deleteBoardMeetingAgenda(
      id: $id
    ) {
      id
    }
  }
`;

export const ORDER_BOARD_MEETING_AGENDAS = gql`
  mutation orderBoardMeetingAgendas($ids: [ID!]!) {
      orderBoardMeetingAgendas(ids: $ids) {
        id
        name
        description
        position
      }
    }
`;

export const ADD_STANDARD_AGENDA = gql`
  mutation addStandardAgenda(
    $boardMeetingId: ID!
  ) {
    addStandardAgenda(
      boardMeetingId: $boardMeetingId
    ) {
      id
      position
      name
      description
    }
  }
`;
