export const normalizeValues = (values: number[], min: number, max: number, ) => {

  const minInArray = Math.min(...values)
  const maxInArray = Math.max(...values)

  // if the max in the array is 0, then all values are scaled to the minimum
  // of the range
  if(maxInArray === 0) {
    return values.map(() => min)
  }

  // Scales the array between the given range ( [max, min])
  const scaled =   values.map((item) => Number( ((item - minInArray) / maxInArray) *  ( max - min) + min) )

  const maxInScaled = Math.max(...scaled)

  // factor to make the largest value in the array to equal that of the range's max
  const factor = max - maxInScaled <= 0 ? 0 : max-maxInScaled

  return scaled.map((item) => Math.round(item + factor))
}
