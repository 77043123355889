import React, { useState } from 'react';
import { Label } from '../../../label'
import IcMoney from '../../../../assets/menuIcons/ic-money.svg';
import IcClock from '../../../../assets/icons/ic-clock.svg';
import IcCalendar from '../../../../assets/icons/ic-calendar.svg';
import IcPerson from '../../../../assets/ic-person.svg'
import IcEmail from '../../../../assets/ic-email.svg'
import IcBookmark from '../../../../assets/icons/ic-bookmark.svg'
import { Button } from '../../../button';
import IcIdentification from '../../../../assets/icons/ic-identification.svg'
import { useHistory } from 'react-router-dom'
import { CREATE_TRANSFER_TRANSACTION } from '../../../../services/transaction'
import { useMutation, ApolloError, useQuery } from '@apollo/client'
import ReactLoading from 'react-loading';
import { Shareholder } from 'src/model';
import moment, {Moment} from 'moment'
import { GET_SELECTED_COMPANY } from '../../../../services/company';
import { DATE_TIME_FORMAT, GENERIC_FORM_ERROR_MESSAGE } from '../../../../utils/constants';
import { TransferTransaction } from '../../../../model/transaction'
import { ApiValidationError } from '../../../../model/common'
import { toLocaleDateTime } from '../../../../utils/formatTime';
import { useNotifications } from "../../../../hooks/notification";
import { NOTIFICATION } from '../../../../context/notificationContext';
import { useUrlBuilder } from '../../../../hooks/urlBuilder';

interface ConfirmTransferTransactionProps {
  formData: TransferTransaction
  onClickBack: any
}

const ConfirmTransferTransaction = (
  props: ConfirmTransferTransactionProps,
): JSX.Element => {
  const { onClickBack } = props
  const { name, email, identifier, amount, pricePerShare, shareClass, dateTime, sendFrom, sendTo, customTransactionPeriod } = props.formData

  const history = useHistory()
  const { buildAdminUrl, selectedCompanyId } = useUrlBuilder()

  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const transactionAt = moment(dateTime, DATE_TIME_FORMAT)
  const [apiErrorMessages, setApiErrorMessages] = useState<ApiValidationError[]>([])
  const [createTransferTransaction] = useMutation(CREATE_TRANSFER_TRANSACTION)
  const { setNotification } = useNotifications();

  const onConfirm = () => {
    setSubmitting(true)
    createTransferTransaction({
      variables: {
        companyId: selectedCompanyId,
        fromShareholderId: sendFrom.id,
        toShareholderId: sendTo?.id,
        amount,
        shareClass,
        pricePerShare,
        name,
        email,
        identifier,
        transactionAt: customTransactionPeriod ? transactionAt : null
      },
    })
      .then((res) => {
        setSubmitting(false);
        setNotification({ title: 'Transaksjon lagret!', type: NOTIFICATION.SUCCESS })
        history.push(buildAdminUrl('/transactions'))
      })
      .catch((err: ApolloError) => {
        setSubmitting(false);
        const apiErrorResponse: ApiValidationError[] = err.graphQLErrors.map((error) => {
          const path = error.path
          return  {
            fieldName: path ? path[1].toString() : '',
            message: error.message
          }

        })
        setNotification({ title: GENERIC_FORM_ERROR_MESSAGE, type: NOTIFICATION.ERROR })
        setApiErrorMessages(apiErrorResponse)
        onClickBack(props.formData, apiErrorResponse)
      });
  }

  return (
    <div className="space-y-4">
      <Label label="Aksjetransaksjon" presetStyle="font-bold" />
      <div className={` ${sendTo ? 'grid grid-cols-2 gap-2.5' : null}  space-x-2`}>
        <Label prefix="Fra: " label={sendFrom.name} icon={IcPerson} />
        {sendTo && (<Label prefix="Til: " label={sendTo.name} icon={IcPerson} />)}
      </div>
      { !sendTo && (
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-2.5 space-x-2">
            <Label label={name ?? ''} icon={IcPerson} />
            <Label label={email ?? ''} icon={IcEmail} />
          </div>
          <Label label={identifier ?? ''} icon={IcIdentification} />
        </div>
      )}

      { customTransactionPeriod && <Label label={toLocaleDateTime(moment(dateTime, DATE_TIME_FORMAT).format())} icon={IcCalendar} /> }

      <div className="grid grid-cols-2 gap-2.5 space-x-2">
        <Label label={shareClass} icon={IcBookmark} />
        <Label label={amount.toString()} />
      </div>
      <Label label={pricePerShare.toString()} icon={IcMoney} />
      <button
        className="btn btn-block outline-none bg-first-btn-color text-second-text-color"
        onClick={ () => { onClickBack(props.formData, apiErrorMessages) }}
      >Tilbake</button>
      <button
        className="btn btn-primary btn-block outline-none bg-orange-light"
        onClick={onConfirm}
      >
        {submitting ? (
          <ReactLoading
            type="bars"
            height="30px"
            width="30px"
            color="#4B2A58"
          />
        ) : (
          <p className="text-white">Lagre endringer</p>
        )}
      </button>
      <div className="flex justify-center">
        {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ConfirmTransferTransaction;
