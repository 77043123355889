import React, { useState } from 'react';
import CompanyInitial from '../../companyInitial/companyInitial';
import Settings from '../../../assets/menuIcons/ic-settings.svg';
import Logout from '../../../assets/menuIcons/ic-logout.svg';
import DefaultProfilePictureHolder from '../../../assets/menuIcons/profilePlaceholder.svg';
import SidebarNavItems from './sidebarNavItems';
import { Button } from '../../../components/button';
import IcPlus from '../../../assets/ic-plus.svg';
import { Company } from 'src/model';
import { Link } from 'react-router-dom';

interface MobileMenu {
  companies: Company[];
  profilePictureUrl?: string;
  currentCompany: any;
  onClickAddCompany: any;
  changeCurrentCompany: any;
  onSelectMenuItem: any;
  openCompanySettings: any;
  onClickLogout: any;
  closeMobileNav: () => void;
}

const MobileMenu = (props: MobileMenu): JSX.Element => {
  const {
    companies,
    profilePictureUrl,
    currentCompany,
    onClickAddCompany,
    changeCurrentCompany,
    onSelectMenuItem,
    openCompanySettings,
    onClickLogout,
    closeMobileNav,
  } = props;
  const [avaterDropdownOpened, setAvaterDropdownOpened] = useState(false);
  const onClickUserAvater = () => {
    setAvaterDropdownOpened(!avaterDropdownOpened);
  };
  return (
    <div className="md:hidden flex flex-col h-screen w-full transition duration-1000 bg-dashboard">
      <div className="bg-mobile-menu-top-nav flex p-4 md:py-2  rounded-b-2xl items-center justify-between">
        <div className="flex space-x-2 mx-4 overflow-x-auto">
          {companies.map((item: any, index: number) => {
            return (
              <CompanyInitial
                onClick={changeCurrentCompany}
                key={index}
                name={item.name}
                color={item.color}
                id={item.id}
              />
            );
          })}
        </div>
        <div className="">
          <div
            className="cursor-pointer block lg:hidden"
            onClick={closeMobileNav}
          >
            <i className="uc-icon text-seventh-color text-2xl">&#xeb8e;</i>
          </div>
        </div>
      </div>
      <div className="flex p-4 pl-8 items-center">
        <img onClick={openCompanySettings} className="mr-2" src={Settings} />
        <div className="flex-1 items-center text-center">
          <span className="font-bold">{currentCompany?.name}</span>
        </div>
        <div className="relative">
          <img
            onClick={onClickUserAvater}
            className="ml-2 w-8 h-8"
            src={
              profilePictureUrl
                ? profilePictureUrl
                : DefaultProfilePictureHolder
            }
          />
          <div
            className={`${
              avaterDropdownOpened ? 'block' : 'hidden'
            } shadow-2xl absolute bg-mobile-menu-top-nav w-150 right-0 top-full mt-1 rounded-sm z-10`}
          >
            <Link to="/settings">
              <div className="items-center flex p-2">
                <img className="mr-2 h-6 y-6" src={Settings} />
                <p>Innstillinger</p>
              </div>
            </Link>
            <Link to="#">
              <div onClick={onClickLogout} className="items-center flex p-2">
                <img className="mr-2 h-6 w-6" src={Logout} />
                <p>Logg ut</p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="px-2 overflow-auto">
        <SidebarNavItems onSelectMenuItem={onSelectMenuItem} isMobile />
      </div>
    </div>
  );
};

export default MobileMenu;
