/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { HeaderGroup, Row, Column } from 'react-table';
import GlobalFilter from './filter';
import classNames from 'classnames';
import { SelectDropDown } from '../../components';
import { exportOptions } from '../../utils/constants';

interface MobileTableProps {
  headerGroups: HeaderGroup<Record<string, unknown>>[];
  tableHeader?: boolean;
  preGlobalFilteredRows: Row<Record<string, unknown>>[];
  globalFilter: any;
  setGlobalFilter: (filterValue: unknown) => void;
  page: Row<Record<string, any>>[];
  toUseColumns: Column<Record<string, string | boolean>>[];
  sortValue?: string;
  handleSorting: any;
  searchValue?: string;
  setSearchValue?: any;
  goToInitialPage: () => void;
  prepareRow: (row: Row<Record<string, any>>) => void;
  isTableExport: boolean;
  handlePageMeta: () => void;
  setSelectedExportOption: any;
}

const MobileTable = ({
  headerGroups,
  tableHeader,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  page,
  toUseColumns,
  sortValue,
  handleSorting,
  searchValue,
  setSearchValue,
  goToInitialPage,
  prepareRow,
  isTableExport,
  handlePageMeta,
  setSelectedExportOption,
}: MobileTableProps): JSX.Element => {
  const [showFilter, setShowFilter] = useState(false);
  return (
    <div className={`${tableHeader ? 'block' : 'hidden'} lg:hidden`}>
      {isTableExport && (
        <div className="flex mb-4 justify-end">
          <SelectDropDown
            text="Export as"
            dropDownItems={exportOptions.map((el) => ({
              label: el.label,
              toExecute: () => {
                setSelectedExportOption(el.value);
                handlePageMeta();
              },
            }))}
          />
        </div>
      )}
      <div id="mobile-filter-wrapper" className="flex mb-8">
        <div className="relative inline-block bg-white py-2 px-3 rounded-3xl mr-4">
          <div className="flex justify-center items-center">
            <button
              type="button"
              className="flex justify-center w-full p-2 items-center focus:outline-none"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onClick={() => setShowFilter(!showFilter)}
            >
              <i className="uc-icon text-grey">&#xe9d9;</i>
            </button>
          </div>

          <div
            className={`origin-top-right absolute left-0 mt-4 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ease-${
              showFilter ? 'out' : 'in'
            } duration-${showFilter ? '100' : '75'} transform opacity-${
              showFilter ? '100' : '0'
            } scale-${showFilter ? '100' : '95'}`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="py-1" role="none">
              {headerGroups.map((headerGroup, trIndex) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  key={trIndex}
                  className="rounded-2xl"
                >
                  {headerGroup.headers.map(
                    (column, thIndex) =>
                      // @ts-ignore
                      !column.isHidden && (
                        <div
                          onClick={() => handleSorting(column.id)}
                          className={`py-2 px-4 font-roboto text-grey cursor-pointer group ${column.id}-header-column-mobile`}
                          key={thIndex}
                        >
                          <div
                            className="w-full flex justify-between"
                            onClick={() => setShowFilter(false)}
                          >
                            {column.render('Header')}

                            <i
                              className={`uc-icon text-grey text-lg ${
                                sortValue?.split(' ')[0] === column.id &&
                                sortValue?.split(' ')[1] === 'desc' &&
                                'transform rotate-180'
                              } ${
                                sortValue?.split(' ')[0] !== column.id &&
                                'opacity-0'
                              }`}
                            >
                              &#xe81d;
                            </i>
                          </div>
                        </div>
                      ),
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-white p-2 rounded-3xl w-full flex items-center">
          <i className="uc-icon text-grey">&#xeb12;</i>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            presetStyle="rounded-br-2xl rounded-bl-2xl mobile-search-txt w-full"
            isMobile={true}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            goToInitialPage={goToInitialPage}
          />
        </div>
      </div>
      <div>
        {page.length !== 0 &&
          page.map((el, index) => {
            prepareRow(el);
            return (
              <div
                key={index}
                className="bg-white px-6 pt-6 pb-1 rounded-3xl mb-8"
              >
                {el.cells.map((cell, cellIndex) => {
                  return (
                    // @ts-ignore
                    !cell.column.isHidden && (
                      <div
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // This line was ignored because it is throwing 'key' is specified more than once, so this usage will be overwritten.ts(2783)
                        // An issue found to be part of typescript
                        key={cellIndex}
                        className={classNames(
                          `flex flex-row flex-wrap justify-between pb-2 mb-4 ${cell.column.id}-header-column-mobile ${cell.column.id}-header-column-mbl`,
                          {
                            'border-b border-very-low-gray-color':
                              cellIndex !== el.cells.length - 2,
                          },
                        )}
                        {...cell.getCellProps()}
                      >
                        <span
                          className={`text-grey font-roboto ${cell.column.id}-header-title`}
                        >
                          {cell.column.Header}
                        </span>
                        <span className="font-hind font-semibold">
                          {cell.render('Cell')}
                        </span>
                      </div>
                    )
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MobileTable;
