import React from 'react';
import CompanyInitial from '../../companyInitial/companyInitial';
import { Button } from '../../../components/button';
import IcPlus from '../../../assets/ic-plus.svg';
import IcBack from '../../../assets/menuIcons/ic-back.svg';
import IcForward from '../../../assets/menuIcons/ic-forward.svg';
import { useLayout } from '../../../hooks/layout';
import { useHistory } from 'react-router-dom';
import { ActionButton } from '../../../components';

interface TopBarProps {
  openMobileNav: any;
  companies: any;
  onClickAddCompany: any;
  changeCurrentCompany: any;
  currentMenu?: string;
}

const TopBar = (props: TopBarProps): JSX.Element => {
  const {
    openMobileNav,
    companies,
    onClickAddCompany,
    changeCurrentCompany,
    currentMenu,
  } = props;
  const { sidebarCollapsed, toggleSidebar } = useLayout();
  const history = useHistory();

  return (
    <div className="p-4 md:py-2 items-center flex flex-1 bg-mobile-top-nav md:bg-transparent rounded-b-3xl md:rounded-none relative md:static flex justify-between overflow-auto">
      <div className="z-10 md:flex-1 pl-4 md:pl-0">
        <ActionButton
          onClick={() => history.goBack()}
          type="secondary"
        >
          <i className="uc-icon text-grey">&#xe833;</i>
        </ActionButton>
      </div>

      <div className="hidden md:flex space-x-2 mx-2 overflow-auto">
        {companies.map((item: any, index: number) => {
          return (
            <CompanyInitial
              key={index}
              name={item.name}
              color={item.color}
              id={item.id}
              onClick={changeCurrentCompany}
            />
          );
        })}
      </div>

      <div className="md:hidden absolute inset-0 flex items-center justify-center">
        {currentMenu}
      </div>
      <div
        className="cursor-pointer block md:hidden z-10"
        onClick={openMobileNav}
      >
        <i className="uc-icon text-seventh-color text-2xl">&#xe80d;</i>
      </div>
    </div>
  );
};

export default TopBar;
