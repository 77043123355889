import React from 'react'
import { Message } from '../../../components/message'
import { SecondaryEmptyIcon } from '../../../components/icons'
import { useHistory } from 'react-router';

const Error404 = (): JSX.Element => {
  const history = useHistory()
  return (
    <div className="flex h-full items-center">
      <Message
        title="Side ikke funnet"
        description="Kan ikke finne siden du leter etter"
        toExecute={() => history.goBack()}
        icon={<SecondaryEmptyIcon />}
        btnLabel="Gå tilbake"
      />
    </div>

  )
}

export default Error404;
