import { ActionButton } from '../../components';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { FundingEventForm } from '../../components/fundingEvent';

const FundingEvent = (): JSX.Element => {
  const history = useHistory()
  return (
    <div className="p-8">
      <div className="mb-4 p-4 pl-2 flex">
        <div className="flex flex-col">
          <h2 className="font-hind font-bold text-5xl">Emisjon</h2>
        </div>
      </div>
      <FundingEventForm />
    </div>
  );
};

export default FundingEvent;
