import styled from 'styled-components';

interface ButtonComponentProps {
  size?: string;
  buttonType?: string;
}

export const ButtonHolder = styled.button`${(props: ButtonComponentProps) => `

padding: ${props?.buttonType ? handleBtnPadding(props.buttonType) : '5px 20px'};
width: ${props?.size && props?.size === 'lg' ? '100%' : 'auto'};
border-radius: ${props?.buttonType ? handleBtnRadius(props.buttonType) : '50px'};
border: ${props?.buttonType ? handleBtnBorder(props.buttonType) : ''};
background-color: ${props?.buttonType
    ? handleBtnColor(props.buttonType)
    : 'var(--first-btn-color)'};
`}
`;
export const ButtonLabel = styled.span`${(props: ButtonComponentProps) => `
color: ${handleBtnLabelColor(props?.buttonType || '')}`}`;

const handleBtnLabelColor = (type: string) => {
  switch (type) {
  case 'button-3':
  case 'button-4':
  case 'button-5':
    return 'var(--first-text-color)'

  default:
    return 'var(--second-text-color)'
  }
}

const handleBtnColor = (type: string) => {

  switch (type) {
  case 'button-2':
  case 'button-4':
    return 'var(--third-btn-color)';
  case 'button-3':
    return 'var(--fifth-color)';
  case 'button-5':
    return 'var(--ninth-color)';
  case 'circular':
    return 'transparent'
  }
};

const handleBtnPadding = (type: string) => {
  switch (type) {
  case 'button-2':
  case 'button-4':
  case 'button-5':
    return '15px 20px';
  case 'button-3':
    return '15px 50px';
  }
};

const handleBtnRadius = (type: string) => {
  switch (type) {
  case 'button-2':
    return '20px';
  case 'button-3':
  case 'button-4':
  case 'button-5':
    return '50px';
  case 'circular':
    return '9999px'
  }
}

const handleBtnBorder = (type: string) => {
  switch (type) {
  case 'circular':
    return 'solid 2px'
  }
}
