import React from 'react'
import { SnakeRadialBar } from '../snakeRadialBar'
import { normalizeValues } from '../../utils/math/math'

interface DividendInterface {
  values: { value: number, label: string }[],
  title: string,
  subtitle: string
}
const Dividend = (props: DividendInterface) => {
  const { values, title, subtitle } = props

  const minBarPercentage = 20
  const maxBarPercentage = 80

  const normalized = normalizeValues(values.map(item => item.value), minBarPercentage, maxBarPercentage)

  return (
    <div className="absolute bottom-0 flex flex-col inset-x-0">
      <div className="flex space-x-4 justify-center">
        {
          values.map((item, index) => {
            return (
              <div key={index} className={`snake-chart-${index} flex flex-col items-center content-center`}>
                <div className="label p-4 pt-1 pb-1 rounded-full inline-block font-bold z-20" >
                  {item.label}
                </div>
                <div className="label h-6 w-6 inline-block transform rotate-45 -mt-5 z-10">
                </div>
              </div>
            )
          })
        }
      </div>

      <div className="flex justify-center">
        {
          normalized.map((item, index) => {
            return (
              <div className={`${ index == normalized.length -1 ? 'absolute' : null }`} key={index}>
                <SnakeRadialBar
                  barRadius={50 + 30 * index}
                  barWidth={30}
                  barPercentage={item}
                  style={`snake-chart-${index}`}
                />
              </div>
            )
          })
        }
      </div>

      <div className="flex flex-col text-center font-hind font-bold text-xl mt-5">
        <h1 className="mb-0">{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </div>

  )
}

export default Dividend
