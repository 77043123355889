import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLayout } from '../../../hooks/layout';
import Settings from '../../../assets/menuIcons/settings.svg';
import Logout from '../../../assets/menuIcons/logout.svg';
import CompanyInitial from '../../companyInitial/companyInitial';
import Profile from '../../profile/profile';
import SidebarNavItem from './sidebarNavItem';
import SidebarNavItems from './sidebarNavItems';
import IcBack from '../../../assets/menuIcons/ic-back.svg';
import IcForward from '../../../assets/menuIcons/ic-forward.svg';
import { CurrentCompanyProps } from '../../../model';
import classNames from 'classnames';
import { ActionButton } from '../../../components';
import { GET_FORM_STATUS } from '../../../services/common';
import { useQuery } from '@apollo/client';

interface SidebarProps {
  user: any;
  currentCompany: CurrentCompanyProps;
  openCompanySettings: () => void;
  onClickLogout: () => void;
}

const Sidebar = (props: SidebarProps): JSX.Element => {
  const [fold, setFold] = useState(false);
  const { data: isFormDirty } = useQuery(GET_FORM_STATUS);
  const history = useHistory();

  const toggleProfile = () => {
    setFold(!fold);
  };

  const { user, currentCompany, onClickLogout } = props;
  const { sidebarCollapsed, toggleSidebar } = useLayout();

  return (
    <div id="sidebar-container" className="hidden md:flex flex-col relative">
      <div className="flex h-12 px-2">
        <div className={`flex flex-1 items-center`}>
          <div className={`px-1 ${sidebarCollapsed ? 'mx-auto' : 'ml-4'}`}>
            <CompanyInitial
              name={currentCompany?.name}
              color={currentCompany?.color}
              id={currentCompany?.id}
            />
          </div>
          <div
            className={`flex items-center ${
              sidebarCollapsed ? 'hidden' : 'block'
            }`}
          >
            <span className="text-seventh-color mr-2 mt-0.5 whitespace-nowrap overflow-ellipsis overflow-hidden max-w-180 font-hind font-bold text-lg">
              {currentCompany?.name}
            </span>
          </div>
        </div>
      </div>
      <div
        id="sidebar-content"
        className="flex flex-col flex-1 overflow-auto px-2 group"
      >
        <div className="flex-1">
          <div className="text-center flex-1">
            <SidebarNavItems />
          </div>
        </div>
        <div className="text-center">
          <div
            className={`${
              fold && sidebarCollapsed
                ? 'my-12'
                : ' my-10 bg-nav-bottom-groupping py-2'
            } rounded-3xl`}
          >
            <div className={` px-2 ${fold ? 'hidden' : 'block'}`}>
              <SidebarNavItem
                icon={Settings}
                label={'Innstillinger'}
                route={'/settings'}
              />
              <SidebarNavItem
                onClickNavItem={() => history.push({ search: `action=logout` })}
                icon={Logout}
                label={'Logg ut'}
                route={'#'}
              />
            </div>
            <div className="mx-auto">
              <Profile
                fullName={user?.name}
                role={'Eier'}
                toggleProfile={toggleProfile}
              />
            </div>
          </div>
        </div>
        <div
          id="side-toggle-btn"
          className="absolute top-1/2 -right-4 cursor-pointer z-10 w-auto"
          onClick={toggleSidebar}
        >
          <ActionButton
            type="tertiary"
            onClick={toggleSidebar}
            presetStyle="group-hover:bg-white"
          >
            <i
              className={classNames(
                'uc-icon text-grey transition duration-500',
                {
                  'transform rotate-180': sidebarCollapsed,
                },
              )}
            >
              &#xe833;
            </i>
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
