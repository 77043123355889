interface AuthenticationHeader {
  client: string,
  expiry: number,
  'access-token': string,
  'token-type': string,
  'uid': string,
}

export default class ClientSession {
  static AuthHeader = "INVBOK:AUTH_HEADER"

  static storeAuthHeader = (authHeader: any) => {
    localStorage.setItem(ClientSession.AuthHeader, authHeader)
  }

  static getAuthHeader = () => {
    return localStorage.getItem(ClientSession.AuthHeader)
  }

  static logoutUser = () => {
    localStorage.removeItem(ClientSession.AuthHeader)
  }

  static isLoggedIn = () => {
    return ClientSession.getAuthHeader() !== null
  }

  static checkIfUserHasCompany = (user: any) => {
    return user.authenticatable.managedCompanies.length !== 0
  }

}
