import React, { useState, useEffect } from 'react';
import { TextBox, PaginationSelect } from '../../components';

interface TablePaginationProps {
  gotoPage: (page: number) => void;
  canPreviousPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  pageCount: number;
  canNextPage: boolean;
  pageIndex: number | string | any;
  pageOptions: Array<number>;
  pageSize: number | string | any;
  setPageSize: (pageSize: number) => void;
}

const TablePagination = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  pageCount,
  canNextPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
}: TablePaginationProps): JSX.Element => {
  const [pagesPool, setPagesPool] = useState([] as number[]);

  useEffect(() => {
    const newPool = Array.from({ length: 5 }, (_, i) => i + pageIndex - 1);
    const filtered = newPool.filter((el) => el <= pageCount - 1 && el >= 0);

    if (filtered.length < 5 && filtered[0] !== 0) {
      filtered.unshift(filtered[0] - 1);
    }

    setPagesPool(filtered);
  }, [pageIndex, pageCount]);

  return (
    <div className="pagination flex flex-col justify-center items-center mt-8">
      <div className="flex h-12 font-medium rounded-full">
        {pageCount > 1 && (
          <>
            {' '}
            <div
              className="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full bg-seven-color text-white mr-4"
              onClick={() => canPreviousPage && gotoPage(0)}
              id="pagination-first"
            >
              {'<<'}
            </div>
            <div
              className="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full bg-seven-color text-white mr-4"
              onClick={() => canPreviousPage && previousPage()}
              id="pagination-previous"
            >
              {'<'}
            </div>
          </>
        )}
        {pagesPool.map((el) => (
          <div
            className={`w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full ${
              el === pageIndex && 'bg-seven-color text-white '
            }`}
            key={el}
            onClick={() => gotoPage(el)}
          >
            {el + 1}
          </div>
        ))}
        {pageCount > 1 && (
          <>
            <div
              className="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full bg-seven-color text-white ml-4"
              onClick={() => canNextPage && nextPage()}
              id="pagination-next"
            >
              {'>'}
            </div>
            <div
              className="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full bg-seven-color text-white ml-4"
              onClick={() => canNextPage && gotoPage(pageCount - 1)}
              id="pagination-last"
            >
              {'>>'}
            </div>
          </>
        )}
      </div>
      <div className="mt-4 flex">
        <div className="flex font-hind items-center">
          <span className="mb-4">Gå til side: </span>
          <TextBox
            id="pagination-txtbox"
            txtType="text"
            value={pageIndex + 1}
            handleTextChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            presetTxtBoxStyle="pagination-txt-box"
          />
        </div>
        <PaginationSelect
          id="pagination-select"
          value={pageSize}
          handleChange={(e) => setPageSize(Number(e.target.value))}
          presetStyle="ml-4"
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Vis {pageSize}
            </option>
          ))}
        </PaginationSelect>
      </div>
    </div>
  );
};

export default TablePagination;
