import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DataList } from '../../../components/dataList';
import SearchIcon from '../../../assets/icons/ico-search.svg';
import { GET_COMPANY } from '../../../services/company';
import { BrregCompanies, BrregCompanyVar, Company } from '../../../model';
import { useHistory } from 'react-router-dom';
import { createCompanyMessages } from '../../../utils/constants';
import { useUrlBuilder } from '../../../hooks/urlBuilder'

const FindCompanyComponent = (props: any) => {
  const { buildAdminUrl } = useUrlBuilder()
  const [selected, setSelected] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [companies, setCompanies] = useState({} as BrregCompanies);

  const [getCompany, { loading, data, error }] = useLazyQuery<
    BrregCompanies,
    BrregCompanyVar
  >(GET_COMPANY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setCompanies(data),
  });

  const history = useHistory();

  const handleSelect = (element: string): void => {
    const companyDetails =
      element &&
      companies &&
      companies.searchBrregCompanies.filter((el) => el.orgnr === element)[0];

    setSelected(element);

    if (companyDetails && companyDetails.taken)
      return setErrorMessage(createCompanyMessages.COMPANY_ALREADY_TAKEN);

    element
      ? history.push({
        pathname: buildAdminUrl('/create-company', true),
        state: { companyDetails },
      })
      : setErrorMessage(createCompanyMessages.COMPANY_NOT_SELECTED);
  };

  const handleSearch = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setErrorMessage('');
    getCompany({
      variables: {
        search: ev.target.value,
      },
    });
  };

  const generateCompaniesSelect = (companies: any) => {

    const selectedData: { value: string; label: JSX.Element }[] = [];
    companies.map((el: Company, index: number) =>
      selectedData.push({
        value: el.orgnr,
        label: (
          <div className="">
            <div className="mb-2">
              <span className="font-roboto text-xl">{el.name}</span>
            </div>
            <div className="flex flex-col font-roboto text-sm">
              <p className="mb-0 third-text-color"> Org nr {el.orgnr}</p>
              <p className="-mt-1 third-text-color">
                {`${el.streetAddress}, ${el.postCode} ${el.city}`}
              </p>
            </div>
          </div>
        ),
      }),
    );
    return selectedData;

  };

  return (
    <div className="register-form mx-auto mt-4">
      <h1 className="page-title font-hind">Finn ditt firma</h1>
      <DataList
        data={companies?.searchBrregCompanies ?? []}
        icon={SearchIcon}
        handleSelect={handleSelect}
        handleTextChange={handleSearch}
        loading={loading}
        placeHolder="Legg inn ditt org. nummer"
        errorMessage={errorMessage}
        presetStyleOptionsWrapper="mt-0 bg-first-card-box-color"
        presetStyle="flex p-4 w-full bg-first-card-box-color"
        presetStyleInput="placeholder-first-text-color"
        dataGenerator={generateCompaniesSelect}
        closeOptionsAfterSelect
      />
      <div className="form-group justify-center mt-4">
        <p className="text-error h-error-txt">{errorMessage}</p>
      </div>
    </div>
  );
};

export default FindCompanyComponent;
