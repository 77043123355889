import { gql } from '@apollo/client';

export const GET_SUBSCRIBER_INFO = gql`
query subscriberInfo($subscriberId:ID!){
    subscriberInfo(subscriberId: $subscriberId){
      companyId
      companyName
      eventName
      eventDescription
    }
}
`;

export const ACCEPT_SUBSCRIBER_INVITATION = gql`
mutation acceptSubscriber($subscriberId:ID!){
    acceptSubscriber(subscriberId: $subscriberId)
  }
`;

export const REJECT_SUBSCRIBER_INVITATION = gql`
mutation rejectSubscriber($subscriberId:ID!){
    rejectSubscriber(subscriberId: $subscriberId)
  }
`;

export const ADD_INDIVIDUAL_SUBSCRIBER = gql`
  mutation addSubscriberToEvent($attributes:SubscriberInput!){
    addSubscriberToEvent(attributes: $attributes){
      id
    }
  }
`;

export const DELETE_SUBSCRIBER = gql`
  mutation deleteSubscriber($id:ID!){
    deleteSubscriber(id: $id){
      id
    }
  }
`;

export const UPDATE_SUBSCRIBER = gql`
  mutation updateSubscriber($attributes: SubscriberInput!) {
    updateSubscriber(attributes: $attributes) {
      name
      email
      phone
      amount
      paid
    }
  }
`;
