import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '..';
import { dashboardContainer } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { useUrlBuilder } from '../../hooks/urlBuilder';
import {
  IMPORT_COMBINED_DATA,
  INITIALIZE_MANAGED_COMPANY_FROM_REGISTRY,
  GET_MANAGED_COMPANY_DETAIL,
} from '../../services/company';
import { client } from '../../services/apollo-client';
import ReactLoading from 'react-loading';
import { useNotifications } from '../../hooks/notification';
import { NOTIFICATION } from '../../context/notificationContext';
import { setShowSetupCompany } from '../../store/cache';
import { useLayout } from '../../hooks/layout';
import IcDashboard from '../../assets/menuIcons/ic-dashboard.svg';
import { CompanyVar, ManagedCompany } from 'src/model';
import IcBrick from '../../assets/icons/ic-brickwall.svg';
import IcImport from '../../assets/icons/ic-import.svg';
import IcNumber from '../../assets/icons/ic-number.svg';
import IcCsv from '../../assets/icons/ic-csv.svg';

const SetupCompany = (): JSX.Element => {
  const { buildAdminUrl, selectedCompanyId } = useUrlBuilder();
  const history = useHistory();
  const [uploadFileMutation] = useMutation(IMPORT_COMBINED_DATA);
  const [file, setFile] = useState('');
  const [
    loadingInitializingFromRegistry,
    setLoadingInitializingFromRegistry,
  ] = useState(false);
  const [loadingImportingCsv, setLoadingImportingCsv] = useState(false);
  const { menu, setMenu } = useLayout();

  const [initializeFromRegistry] = useMutation(
    INITIALIZE_MANAGED_COMPANY_FROM_REGISTRY,
    {
      update(cache, { data }) {
        cache.modify({
          id: cache.identify({ __typename: 'ManagedCompany', id: selectedCompanyId }),
          fields: {
            isEmpty(isEmpty) {
              return !isEmpty
            },
          },
        });
      },
    }
  );
  const { setNotification } = useNotifications();

  const { data } = useQuery<ManagedCompany, CompanyVar>(
    GET_MANAGED_COMPANY_DETAIL,
    {
      variables: {
        id: selectedCompanyId,
      },
    },
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null || e.target.files.length == 0) {
      return;
    }

    setLoadingImportingCsv(true);
    uploadFileMutation({
      variables: {
        file: e.target.files[0],
        companyId: selectedCompanyId,
      },
    })
      .then(() => {
        client.resetStore();
        setFile('');

        setNotification({
          title: 'Suksess',
          message: 'Transaksjoner importert',
          type: NOTIFICATION.SUCCESS,
        });
        setShowSetupCompany(false);
        setLoadingImportingCsv(false);
        history.push(buildAdminUrl('/dashboard'));
      })
      .catch(() => {
        setLoadingImportingCsv(false);
        setNotification({
          title: 'Feil',
          message: 'Kan ikke importere transaksjoner',
          type: NOTIFICATION.ERROR,
        });
      });
  };

  const onClickInitializeFromRegistry = () => {
    setLoadingInitializingFromRegistry(true);
    initializeFromRegistry({
      variables: {
        companyId: selectedCompanyId,
      },
    })
      .then(() => {
        setLoadingInitializingFromRegistry(false);
        setNotification({
          title: 'Suksess',
          message: 'Aksjonærer importert fra Brønnøysundregistret',
          type: NOTIFICATION.SUCCESS,
        });
        setShowSetupCompany(false);
        // redirect to dashboard
        history.push(buildAdminUrl('/dashboard'));
      })
      .catch((err) => {
        setLoadingInitializingFromRegistry(false);
        setNotification({
          title: 'Feil',
          message: 'Kan ikke importere aksjonærer fra Brønnøysundregistret',
          type: NOTIFICATION.ERROR,
        });
      });
  };

  return (
    <div className={dashboardContainer}>
      <div className="mb-4 py-4 pl-4 flex justify-between">
        <div className="flex flex-col w-full">
          <div className="flex flex-col">
            <h2 className="font-hind font-bold text-5xl">
              Sett opp bedriften din
            </h2>
          </div>
          <div className="mb-12">
            <p className="text-md">Firmaet ditt er tomt.</p>
            <p className="text-md">Velg et alternativ for å komme i gang.</p>
          </div>
          <div className={`grid grid-cols-1 lg:grid-cols-3 gap-6`}>
            <div className="flex  flex-col bg-white min-h-64 rounded-3xl bg-menu-item-separator p-4 transform hover:scale-105 mb-8 transition duration-500 ease-in-out">
              <div className="flex items-center justify-center rounded-full h-20 w-20 bg-white border-4 -mt-12">
                <img src={IcBrick} className="h-12 w-12 " />
              </div>
              <h2 className="text-xl font-bold">
                Legg til innbetalt Aksjekapital
              </h2>
              <p className="flex-1 mb-8">
                Her vil du registrere innbetaling av aksjekapitalen som ble
                eller skal bli gjort ved registrering av bedriften. Du vil etter
                det kunne legge til andre transaksjoner og emisjoner som måtte
                være gjort i tiden etter aksjekapitalen ble innbetalt.
              </p>
              <Button
                label="Legg til aksjekapital innskudd manuelt"
                presetLabelStyle="normal-case"
                toExecute={() =>
                  history.push(buildAdminUrl('/founding-events/new'))
                }
              />
            </div>
            {data?.managedCompany.canInitialize && (
              <div className="flex  flex-col bg-white min-h-64 rounded-3xl bg-menu-item-separator p-4 transform hover:scale-105 transition duration-500 ease-in-out mb-8">
                <div className="relative flex items-center justify-center rounded-full h-20 w-20 bg-white border-4 -mt-12">
                  <img src={IcImport} className="h-12 w-12 " />
                  {loadingInitializingFromRegistry && (
                    <div className="absolute h-20 w-20">
                      <ReactLoading
                        type="spin"
                        height="100%"
                        width="100%"
                        color="#4B2A58"
                      />
                    </div>
                  )}
                </div>
                <h2 className="text-xl font-bold">
                  Importer aksjonærer fra Brønnøysundregistret
                </h2>
                <p className="flex-1 mb-8">
                  Vi vil da importere aksjonærene med deres antall aksjer som
                  oppført i Brønnøysundregistrene. Det er tall pr 31 Desember
                  sist tilgjengelige år. Disse dataene oppdateres i Mai hvert
                  år, slik at det kan hende du får tall fra mer enn ett år
                  tilbake i tid. Du kan da etterpå legge til eventuelle
                  transaksjoner og emisjoner som måtte ha tatt plass siden.
                </p>
                <Button
                  label="Importer aksjonærer fra Brønnøysundregistret"
                  presetLabelStyle="normal-case"
                  toExecute={() => onClickInitializeFromRegistry()}
                />
              </div>
            )}
            {data && !data.managedCompany.orgnr && (
              <div className="flex  flex-col bg-white min-h-64 rounded-3xl bg-menu-item-separator p-4 transform hover:scale-105 transition duration-500 ease-in-out mb-8">
                <div className="flex items-center justify-center rounded-full h-20 w-20 bg-white border-4 -mt-12">
                  <img src={IcNumber} className="h-12 w-12 " />
                </div>
                <h2 className="text-xl font-bold">
                  Legg til organisasjonsnummer
                </h2>
                <p className="flex-1 mb-8">
                  Vi behøver organisasjonsnummeret til bedriften din. Om du ikke
                  har det enda så kan du legge det til senere. Om du har det
                  tilgjengelig så legg det til her, da kan det hende at vi kan
                  importere en del data automatisk.
                </p>
                <Button
                  label="Legg til organisasjonsnummer"
                  toExecute={() =>
                    history.push(buildAdminUrl('/company-settings'))
                  }
                />
              </div>
            )}
            <div className="flex  flex-col bg-white min-h-64 rounded-3xl bg-menu-item-separator p-4 transform hover:scale-105 transition duration-500 ease-in-out mb-8">
              <div className="relative flex items-center justify-center rounded-full h-20 w-20 bg-white border-4 -mt-12">
                <img src={IcCsv} className="h-12 w-12 " />
                {loadingImportingCsv && (
                  <div className="absolute h-20 w-20">
                    <ReactLoading
                      type="spin"
                      height="100%"
                      width="100%"
                      color="#4B2A58"
                    />
                  </div>
                )}
              </div>
              <h2 className="text-xl font-bold">
                Importer aksjonærbok fra CSV
              </h2>
              <p className="flex-1 mb-8">
                Har du en aksjonærbok fra ett annet system, eller har du ført
                din i Excel. Du kan her importere den slik at vi får helt
                oppdaterte data så langt du har ført aksjeboken.
              </p>
              <div>
                <input
                  id="file-input"
                  type="file"
                  className="hidden"
                  onChange={onChange}
                  value={file}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
                <Button
                  label="Importer aksjonærbok fra CSV"
                  size="lg"
                  toExecute={() => {
                    const fileInput: HTMLInputElement | null = document.querySelector(
                      '#file-input',
                    );
                    fileInput && fileInput.click();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupCompany;
