import React, { useState, useEffect, useMemo } from 'react';
import {
  dashboardContainer,
  voteStatus,
  INTERNAL_SERVER_ERROR,
} from '../../utils/constants';
import {
  ActionButton,
  Skeleton,
  Message,
  SecondaryEmptyIcon,
} from '../../components';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  useLazyQuery,
  useMutation,
  ApolloError,
  useQuery,
} from '@apollo/client';
import {
  GET_BOARD_MEETING_AGENDAS,
  VOTE_FOR_AGENDA,
} from '../../services/events';
import {
  BoardMeetingAgendaVar,
  SingleBoarMeetingAgendaProps,
  BoarMeetingAgenda,
} from '../../model';
import { toLocaleDateTime } from '../../utils/formatTime';
import { useNotifications } from '../../hooks/notification';
import { NOTIFICATION } from '../../context/notificationContext';
import { GopherChart } from '../../components/gopherChart';
import { normalizeValues } from '../../utils/math/math';
import { useUrlBuilder } from '../../hooks/urlBuilder';
import IsFundingEvent from '../../hooks/isFundingEvent';

const BoardMeetingAgendaVoting = (): JSX.Element => {
  const { buildAdminUrl } = useUrlBuilder();
  const [agendas, setAgendas] = useState([] as Array<BoarMeetingAgenda>);
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();
  const { setNotification } = useNotifications();
  const isFundingEvent = IsFundingEvent();

  const [sendVote, { data: newVoteDetails }] = useMutation(VOTE_FOR_AGENDA);

  const [getAgendas, { loading, refetch: refetchAgendas }] = useLazyQuery<
    SingleBoarMeetingAgendaProps,
    BoardMeetingAgendaVar
  >(GET_BOARD_MEETING_AGENDAS, {
    fetchPolicy: 'network-only',
    onCompleted: (boardMeetingAgenda) => {
      setAgendas(boardMeetingAgenda.boardMeetingAgendas);
    },
    onError: () => setAgendas([]),
  });

  useEffect(() => {
    if (eventId) {
      getAgendas({
        variables: {
          boardMeetingId: eventId,
        },
      });
    }
  }, []);

  if (loading) return <Skeleton />;

  const handleSubmitVote = (status: string, boardMeetingAgendaId: string) => {
    sendVote({
      variables: {
        attributes: {
          status,
          boardMeetingAgendaId,
        },
      },
    })
      .then((res) => {
        refetchAgendas &&
          refetchAgendas().then((res) =>
            setAgendas(res.data.boardMeetingAgendas),
          );
      })
      .catch((err: ApolloError) => {
        setNotification({
          title: INTERNAL_SERVER_ERROR,
          type: NOTIFICATION.ERROR,
        });
      });
  };

  const handleVoteIcon = (voteId: string) => {
    switch (voteId) {
    case 'yes':
      return <i className="uc-icon ml-2">&#xeb87;</i>;
    case 'no':
      return <i className="uc-icon ml-2">&#xeb86;</i>;
    case 'pass':
      return <i className="uc-icon ml-2">&#xe858;</i>;

    default:
      return <i className="uc-icon ml-2">&#xeb87;</i>;
    }
  };

  const handleVoteBtnBg = (voteId: string, votes: any) => {
    switch (voteId) {
    case 'yes':
      return `hover:bg-twelfth-color ${
        votes.length && votes[0].status === voteId.toLowerCase()
          ? 'bg-twelfth-color text-white'
          : 'text-grey'
      }`;
    case 'no':
      return `hover:bg-eleventh-color ${
        votes.length && votes[0].status === voteId.toLowerCase()
          ? 'bg-eleventh-color text-white'
          : 'text-grey'
      }`;
    case 'pass':
      return `hover:bg-seven-color ${
        votes.length && votes[0].status === voteId.toLowerCase()
          ? 'bg-seven-color text-white'
          : 'text-grey'
      }`;

    default:
      return <i className="uc-icon mr-2">&#xeb87;</i>;
    }
  };

  return (
    <div className={dashboardContainer}>
      <div className="mb-4 p-4 flex">
        <div className="flex flex-col">
          <h2 className="font-hind font-bold text-2xl xl:text-5xl">
            Generalforsamlings agenda
          </h2>
        </div>
      </div>

      <div id="agendas-wrapper">
        {agendas.length ? (
          agendas.map((agenda, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-3xl mb-8 w-full flex flex-col xl:flex-row"
            >
              <div className="w-full">
                <div className="w-full">
                  <div className="flex justify-between mb-2">
                    <h2 className="font-hind font-bold text-xl">
                      {agenda.name}
                    </h2>
                  </div>
                  <pre>{agenda.description}</pre>
                </div>
              </div>
              <div className="w-auto ml-4 xl:ml-0 flex items-end justify-center xl:justify-end mt-4 xl:mt-0">
                {voteStatus.map((singleVote, voteIndex) => (
                  <div
                    className="flex flex-col items-center px-2"
                    key={voteIndex}
                  >
                    <GopherChart
                      height={
                        normalizeValues(
                          [
                            agenda.voteStats.yes,
                            agenda.voteStats.pass,
                            agenda.voteStats.no,
                          ],
                          30,
                          100,
                        )[voteIndex]
                      }
                      width={30}
                      label={agenda.voteStats[singleVote].toString()}
                      style={`gopher-chart-${voteIndex} relative`}
                    />
                    <div
                      className={`rounded-lg cursor-pointer py-1 px-3 border border-vote-border transition duration-1000 ease-out shadow-md focus:outline-none mt-4 bg-white group hover:text-white ${handleVoteBtnBg(
                        singleVote,
                        agenda.votes,
                      )}`}
                      onClick={() =>
                        handleSubmitVote(singleVote.toLowerCase(), agenda.id)
                      }
                    >
                      <span className="capitalize">{singleVote}</span>
                      {handleVoteIcon(singleVote)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="bg-white p-6 rounded-3xl h-full flex justify-center items-center">
            <Message
              title="Ingen sakspunkter"
              description="Ingen sakspunkter listet for dette møtet enda"
              toExecute={() =>
                history.push(
                  buildAdminUrl(
                    isFundingEvent
                      ? `/funding-events/board-meetings/${eventId}/agendas-voting`
                      : `/founding-events/board-meetings/${eventId}/agendas-voting`,
                  ),
                )
              }
              icon={<SecondaryEmptyIcon />}
              btnLabel="Legg til sakspunkter"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BoardMeetingAgendaVoting;
