import { gql } from '@apollo/client';

// TODO refactor naming to proper naming
export const GET_MANAGED_COMPANY_SHAREHOLDERS_STOCK_CLASSES = gql`
  query getManagedCompanyUsersAndStockClasses($id: ID!) {
    managedCompany(id: $id) {
      shareClasses
      defaultShareClass
      shareholders (page: 1, sort: "name" ){
        collection {
          id
          name
          shares {
            amount
            shareClass
          }
        }
      }
    }
  }
`;

export const CREATE_SPLIT_TRANSACTION = gql`
  mutation createSplitTransaction(
    $amount: Int!
    $companyId: ID!
    $transactionAt: String!
  ) {
    createSplitTransaction(
      amount: $amount
      companyId: $companyId
      transactionAt: $transactionAt
    ) {
      id
    }
  }
`;

export const CREATE_TRANSFER_TRANSACTION = gql`
  mutation createTransferTransaction(
    $companyId: ID!
    $toShareholderId: ID
    $fromShareholderId: ID
    $shareClass: String
    $amount: Int!
    $pricePerShare: Float!
    $name: String
    $email: String
    $identifier: String
    $transactionAt: String
  ) {
    createTransferTransaction(
      companyId: $companyId
      toShareholderId: $toShareholderId
      fromShareholderId: $fromShareholderId
      shareClass: $shareClass
      amount: $amount
      pricePerShare: $pricePerShare
      name: $name
      email: $email
      identifier: $identifier
      transactionAt: $transactionAt
    ) {
      id
    }
  }
`;

export const CREATE_CONVERT_TRANSACTION = gql`
  mutation createConvertTransaction(
    $companyId: ID!
    $amount: Int!
    $transactionAt: String!,
    $fromShareClass: String!,
    $toShareClass: String!
    $fromShareholderId: String!
  ) {
    createConvertTransaction(
      companyId: $companyId
      amount: $amount
      transactionAt: $transactionAt
      fromShareClass: $fromShareClass
      toShareClass: $toShareClass
      fromShareholderId: $fromShareholderId
    ) {
      id
    }
  }
`;
