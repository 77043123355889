import React from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { useState, Fragment } from 'react';
import { UserSignupErrors } from '../../../model';
import { USER_SIGN_UP } from '../../../services/auth';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import { signupErrorMessages } from '../../../utils/constants';
import { TextBox } from '../../textBox';
import useUnsavedChangesWarning from '../../../hooks/unsavedChangesWarning';

const SignUpForm = ({ onSubmit, ...children }: any): JSX.Element => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(
    {} as UserSignupErrors,
  );

  const {
    setOffChanges,
    handleChangesListener,
  } = useUnsavedChangesWarning({});

  const [userSignUp] = useMutation(USER_SIGN_UP);

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const _name = event.target.value;
    handleChangesListener(event);
    setName(_name);
    setValidationError({ ...validationError, name: '' });
  };

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const _password = event.target.value;
    handleChangesListener(event);
    setPassword(_password);
    setValidationError({ ...validationError, password: '' });
  };

  const handlePasswordConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const _passwordConfirmation = event.target.value;
    handleChangesListener(event);
    setPasswordConfirmation(_passwordConfirmation);
    setValidationError({ ...validationError, passwordConfirmation: '' });
  };

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const _email = event.target.value;
    handleChangesListener(event);
    setEmail(_email);
    setValidationError({ ...validationError, email: '' });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (checkValidation()) {
      setOffChanges();
      setLoading(true);
      userSignUp({
        variables: {
          name,
          email,
          password,
          passwordConfirmation,
          confirmSuccessUrl:
            process.env.REACT_APP_INVESTORBOK_APP_URL + '/confirm',
        },
      })
        .then((res) => {
          setLoading(false);
          onSubmit(email);
        })
        .catch((err: ApolloError) => {
          setLoading(false);
          setError(err.message);
        });
    }
  };

  const checkValidation = () => {
    let response = true;
    const errors: UserSignupErrors = {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    };

    if (!name) {
      response = false;
      errors.name = signupErrorMessages.NAME_REQUIRED;
    }

    if (!email) {
      response = false;
      errors.email = signupErrorMessages.EMAIL_REQUIRED;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      response = false;
      errors.email = signupErrorMessages.INVALID_EMAIL;
    }

    if (!password) {
      response = false;
      errors.password = signupErrorMessages.PASSWORD_REQUIRED;
    } else if (password.length < 6) {
      response = false;
      errors.password = signupErrorMessages.INVALID_PASSWORD;
    }

    if (!passwordConfirmation) {
      response = false;
      errors.passwordConfirmation =
        signupErrorMessages.PASSWORD_CONFIRMATION_REQUIRED;
    } else if (password !== passwordConfirmation) {
      response = false;
      errors.passwordConfirmation = signupErrorMessages.PASSWORD_MISMATCH;
    }

    setValidationError(errors);
    return response;
  };

  return (
    <Fragment>
      <div {...children}>
        <h1 className="page-title font-hind">
          Vi trenger navnet og eposten din
        </h1>
        <h6 className="font-roboto">
          Vi foreslår at du bruker eposten som du har på arbeid.
        </h6>

        <TextBox
          errorMessage={validationError.name}
          handleTextChange={handleNameChange}
          txtType="text"
          txtPlaceholder="Navn"
          id="name"
          value={name}
        />

        <TextBox
          errorMessage={validationError.email}
          handleTextChange={handleEmailChange}
          txtType="text"
          txtPlaceholder="din@epost.no"
          id="email"
          value={email}
        />
        <TextBox
          errorMessage={validationError.password}
          handleTextChange={handlePasswordChange}
          txtType="password"
          txtPlaceholder="Passord"
          id="password"
          value={password}
        />
        <TextBox
          errorMessage={validationError.passwordConfirmation}
          handleTextChange={handlePasswordConfirmationChange}
          txtType="password"
          txtPlaceholder="Bekreft Passord"
          id="passwordConfirmation"
          value={passwordConfirmation}
        />
        <div className="form-group justify-center mt-8">
          {error && <p className="text-error mb-2">{error}</p>}
          <button
            className="btn btn-primary btn-block font-hind font-bold outline-none"
            onClick={handleSubmit}
            id="signup-btn"
          >
            {loading ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#4B2A58"
              />
            ) : (
              'Gå videre'
            )}
          </button>
        </div>
      </div>
      <div className="mt-12">
        <p className="text-center">
          Har du allerede konto?{' '}
          <Link to="/login" className="underline">
            Logg inn
          </Link>
        </p>
      </div>
    </Fragment>
  );
};

export default SignUpForm;
