import React, { useState, useEffect, useMemo } from 'react';
import {
  useQuery,
  useLazyQuery,
  useMutation,
  ApolloError,
} from '@apollo/client';
import Sidebar from '../../components/layout/dashboard/sidebar';
import TopBar from '../../components/layout/dashboard/topBar';
import { useHistory } from 'react-router-dom';
import MobileMenu from '../../components/layout/dashboard/mobileMenu';
import {
  CHECK_ACCESS,
  GET_SIGNED_IN_USER,
  LOGOUT_USER,
  GET_SHOW_SETUP_COMPANY
} from '../../services/auth';
import {
  CheckAccessVar,
  RouteAccessResult,
  Company,
  SignedInUser,
} from '../../model/user';
import ClientSession from '../../utils/clientSession';
import {
  selectedCompanyVar,
  setGraphQLQueryError,
  setLoading,
  setShowSetupCompany,
} from '../../store/cache';
import { GET_GRAPHQL_QUERY_ERROR } from '../../services/networkError';
import { GraphqlQueryError, MenuSection } from '../../model/common';
import { Error404 } from '../../../src/views/errorPages';
import {
  menuItems,
  SELECTED_COMPANY_KEY,
  unsavedChangesDefaultMessage,
} from '../../utils/constants';
import _, { filter } from 'lodash';
import { useLayout } from '../../hooks/layout';
import { MenuItems, MenuItem } from '../../model/common';
import { useLocation } from 'react-router-dom';
import { useUrlBuilder } from '../../hooks/urlBuilder';
import { useRouteCheck } from '../../hooks/routeCheck';
import { GET_FORM_STATUS } from '../../services/common';
import { setIsFormDirty } from '../../store/cache';
import useParamQuery from '../../hooks/searchParam';
import { SetupCompany } from '../../components/setupCompany';
import IcSetup from '../../assets/menuIcons/ic-setup.svg'

interface DashboardLayoutInterface {
  component: React.FunctionComponent;
}

const DashboardLayout = (props: DashboardLayoutInterface): JSX.Element => {
  const history = useHistory();
  const [companyChanged, setCompanyChanged] = useState(false);
  const emptyCompanyAllowedMenus = ["Dashboard", "Selskapsinnstillinger"]

  const queryParam = useParamQuery();
  const logoutAction = queryParam.get('action');
  const setCompanyAction = queryParam.get('changeCompany');
  const { data: { showSetupCompany } } = useQuery(GET_SHOW_SETUP_COMPANY);

  useEffect(() => {
    if (logoutAction === 'logout') onClickLogout();
    if (setCompanyAction) changeCurrentCompany(setCompanyAction);
  }, [logoutAction, setCompanyAction]);

  const { data: isFormDirty } = useQuery(GET_FORM_STATUS);

  const { buildAdminUrl, selectedCompanyId } = useUrlBuilder();
  const { checkAccess } = useRouteCheck((checkAccess) => {
    if (companyChanged) {
      // Redirect to the first allowed path.
      const firstAllowedPath = Object.keys(checkAccess.checkAccess).find(
        (key) => checkAccess.checkAccess[key] === true,
      );

      firstAllowedPath && history.push(firstAllowedPath);

      setCompanyChanged(false);
    }
    // filter menu items
    const filtered = filterMenu(menuItems, checkAccess.checkAccess);
    setMenu(filtered);

    setLoading(false);
  });

  const { setMenu, menu } = useLayout();

  const {
    data: { graphQLQueryError },
  } = useQuery(GET_GRAPHQL_QUERY_ERROR);
  const [showError, setShowError] = useState(false);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState('Dashboard');
  const [currentCompany, setCurrentCompany] = useState({} as Company);

  const filterMenu = (menu: MenuSection[], routeCheck: any) => {
    const filteredMenu = [] as MenuSection[];

    menu.forEach((menuSection) => {
      const allowedMenus = [] as MenuItems;

      // check menu in each menu section if it is allowed or not.
      menuSection.menus.forEach((item) => {
        const route = item.route.replace(':companyId', currentCompany.id);

        if (routeCheck[route]) {
          if(showSetupCompany && route.includes("/dashboard")) {
            allowedMenus.push({ ...item, route, label: 'Setup', icon: IcSetup });
          } else if(!showSetupCompany || showSetupCompany && emptyCompanyAllowedMenus.includes(item.label) ) {
            allowedMenus.push({ ...item, route });
          }
        }
      });
      if (allowedMenus.length)
        filteredMenu.push({ ...menuSection, menus: allowedMenus });
    });
    return filteredMenu;
  };

  const { loading, data, error } = useQuery<SignedInUser>(GET_SIGNED_IN_USER);

  const [userLogout] = useMutation(LOGOUT_USER, {
    update(cache) {
      cache.reset();
    },
  });

  useEffect(() => {
    // call check access to rebuild menu after change in showSetupCompany
    if(!companyChanged && selectedCompanyId)
      checkAccess(setCompanyAction ?? selectedCompanyId)
  }, [showSetupCompany])

  useEffect(() => {
    if (data) {
      const { companies, managedCompanies } = data?.signedInUser;
      const currentCompany = selectedCompanyId
        ? _.find(companies, { id: selectedCompanyId })
        : companies[0];

      if (currentCompany) {
        const managedCompany = _.find(managedCompanies, {id: currentCompany.id})
        managedCompany?.isEmpty ? setShowSetupCompany(true) : setShowSetupCompany(false)

        setCurrentCompany(currentCompany);
      }

      if (currentCompany && !menu.length) {
        // rebuild menu
        // check access to build menu
        checkAccess(currentCompany.id);
      }
    }

    const currentUrl = history.location.pathname;

    if (showError && currentUrl !== graphQLQueryError.url) {
      setShowError(false);
      setGraphQLQueryError({} as GraphqlQueryError);
    }

    if (
      graphQLQueryError?.status &&
      !showError &&
      graphQLQueryError.url === undefined
    ) {
      setShowError(true);
      setGraphQLQueryError({ ...graphQLQueryError, url: currentUrl });
    }
  }, [data, graphQLQueryError]);

  const onSelectMenuItem = (selectedMenu: string) => {
    setCurrentMenu(selectedMenu);
    setOpenMobileMenu(false);
  };

  const openMobileNav = () => {
    setOpenMobileMenu(true);
  };

  const onClickAddCompany = () => {
    history.push(buildAdminUrl('/find-company', true));
  };

  const openCompanySettings = () => {
    history.push(buildAdminUrl('/company-settings', false, currentCompany.id));
    setOpenMobileMenu(false);
  };

  const changeCurrentCompany = (companyId: string) => {
    if (data && companyId !== selectedCompanyId) {
      const { companies, managedCompanies } = data.signedInUser;

      const newCompany = companies.find(
        (item: Company) => item.id === companyId,
      );
      if (newCompany) {
        setCurrentCompany(newCompany);
        selectedCompanyVar(newCompany);
        setCompanyChanged(true);

        const managedCompany = _.find(managedCompanies, {id: newCompany.id})
        managedCompany?.isEmpty ? setShowSetupCompany(true) : setShowSetupCompany(false)

        setLoading(true);
        checkAccess(newCompany.id, true);
      }
    }
  };

  const handleChangeCurrentCompany = (companyId: string) =>
    history.push({ search: `changeCompany=${companyId}` });

  const onClickLogout = () => {
    userLogout()
      .then((res) => {
        ClientSession.logoutUser();
        setMenu([])
        history.push('/');
      })
      .catch((err: ApolloError) => {
        // TODO. Show error message
      });
  };

  const renderComponents = () => {
    if(showError)
      return <Error404/>
    else if(showSetupCompany && location.pathname.includes("/dashboard"))
      return <SetupCompany />
    return <props.component/>
  }

  return (
    <div id="container" className="h-screen bg-content-mobile md:bg-dashboard ">
      <div className="flex h-full">
        <Sidebar
          currentCompany={currentCompany}
          user={data?.signedInUser}
          openCompanySettings={openCompanySettings}
          onClickLogout={onClickLogout}
        />
        <div
          id="content-container"
          className={` overflow-auto flex flex-1 flex-col`}
        >
          <div className="flex items-center h-inherit">
            <TopBar
              openMobileNav={openMobileNav}
              companies={data ? data.signedInUser.companies : []}
              onClickAddCompany={onClickAddCompany}
              changeCurrentCompany={handleChangeCurrentCompany}
              currentMenu={currentMenu}
            />
          </div>
          <div
            id="actual-content-container"
            className="bg-dashboard-content rounded-l-content-area flex flex-col flex-1 overflow-auto"
          >
            {
              useMemo(() => {
                return renderComponents()
              }, [showError, showSetupCompany, props.component])
            }
          </div>
        </div>
        {openMobileMenu && (
          <MobileMenu
            companies={data ? data.signedInUser.companies : []}
            changeCurrentCompany={handleChangeCurrentCompany}
            currentCompany={currentCompany}
            onClickAddCompany={onClickAddCompany}
            onSelectMenuItem={onSelectMenuItem}
            openCompanySettings={openCompanySettings}
            onClickLogout={onClickLogout}
            closeMobileNav={() => setOpenMobileMenu(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;
