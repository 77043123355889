import React from 'react'
import '../../../src/styles/chart.scss'

interface BearChartInterface {
  height: number,
  width: number,
  label: string
  style: string
}

const BearChart = (props: BearChartInterface) => {
  const { height, width, label, style } = props

  const eyeRadius = width/ 10 + 1
  const mouthRadius = eyeRadius + 2
  const earWidth = width / 2
  const earHeight = width + 10

  const svgHeight =  height + width + earHeight - earHeight /4
  const svgWidth = width * 2

  const headRadius = width
  const bodyHeight = height
  const bodyWidth = 2 * headRadius

  // derived variables
  const x = svgWidth /2 - bodyWidth / 2
  const y = svgHeight

  const cx = svgWidth /2
  // const cy =  headRadius + 25
  const cy =  svgHeight - bodyHeight

  const eye1Cx = cx - headRadius/2 - eyeRadius
  const eye2Cx = cx + headRadius/2 + eyeRadius

  // To Make the mouth half circle

  const area = Math.PI*(mouthRadius*2);
  const smile = 50 // Represents how much the mouth is opened. If 100 then it would become a circle
  const strokeDashoffset =  ((100-smile)/100)* area;
  const strokeDasharray = mouthRadius * 565.48 / 90

  const ear1X = cx/2 - earWidth/2
  const ear2X = cx + cx/2  - earWidth/2
  const earY = earHeight / 2

  return (
    <div className="relative" style={{ width: bodyWidth }}>
      <div className={`absolute bottom-0 bear-chart ${style} `}>
        <div className={`flex flex-col items-center content-center  -mb-8 }`}>
          <div className="p-4 pt-1 pb-1 rounded-full inline-block font-bold label z-20" >
            { label }
          </div>
          <div className="h-6 w-6 inline-block transform rotate-45 -mt-5 label z-10 ">
          </div>
        </div>
        <svg width={svgWidth} height={svgHeight} xmlns="http://www.w3.org/2000/svg" >
          {/* ear  */}
          <rect y={earY} x={ ear1X } width={earWidth} height={earHeight}  rx={earWidth/2} ry={earWidth/2} transform={`rotate(-45, ${ ear1X + earWidth /2 }, ${earY + earHeight/2})`} />
          <rect y={earY} x={ ear2X  } width={earWidth} height={earHeight} rx={earWidth/2} ry={earWidth/2} transform={`rotate(45, ${ ear2X + earWidth /2 }, ${earY + earHeight/2})`} />

          {/* Bear Head  */}
          <circle cx={cx} cy={cy} r={headRadius} />

          {/* Bear Body  */}
          <rect y={y} x={x} width={bodyWidth} height={bodyHeight}  transform={`rotate(180, ${svgWidth /2 }, ${svgHeight})`}  />

          {/* Eyes  */}
          <circle className="eye" cx={eye1Cx} cy={cy + eyeRadius} r={eyeRadius}  />
          <circle className="eye" cx={eye2Cx} cy={cy + eyeRadius} r={eyeRadius}  />

          {/* Bear Mouth  */}
          {/* The bear mouth is symetric with the bear height  */}
          {/* The bear mouth starts at the top of the bear eye ( with is the center point of the head)  */}
          <circle className="mouth" cx={cx} cy={cy} r={mouthRadius} strokeWidth="4" strokeDasharray={strokeDasharray}  style={{ strokeDashoffset: `${strokeDashoffset}`}}/>
        </svg>
      </div>
    </div>
  )
}

export default BearChart
