import React, { useState, useEffect } from 'react';
import { Select } from '../../../select';
import IcPerson2 from '../../../../assets/icons/ic-person-2.svg';
import IcStockClass from '../../../../assets/icons/ic-stock-class.svg';
import IcMoney from '../../../../assets/menuIcons/ic-money.svg';
import IcCalendar from '../../../../assets/icons/ic-calendar.svg';
import IcPerson from '../../../../assets/ic-person.svg'
import IcEmail from '../../../../assets/ic-email.svg'
import IcIdentification from '../../../../assets/icons/ic-identification.svg'
import { TextInput } from '../../../textInput'
import { Shareholder } from '../../../../model/user'
import { CreateTransferTransactionErrors } from '../../../../model/transaction'
import { getFormData } from '../../../../utils/form';
import { isEmailValid } from '../../../../utils/validators';
import { createTransactionErrorMessages, createUpdateFundingEventErrorMessages, TIME_FORMAT, transactionTypes } from '../../../../utils/constants'
import { useHistory } from 'react-router';
import { GET_MANAGED_COMPANY_SHAREHOLDERS_STOCK_CLASSES } from '../../../../services/transaction'
import { ManagedCompany } from '../../../../model/company'
import { useQuery } from '@apollo/client'
import { GET_SELECTED_COMPANY } from '../../../../services/company';
import { DateTimePicker } from '../../../dateTimePicker'
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../../utils/constants'
import { TransferTransaction } from '../../../../model/transaction'
import { ApiValidationError } from '../../../../model/common'
import { useUrlBuilder } from '../../../../hooks/urlBuilder'
import _ from 'lodash'
import { Checkbox } from '../../../../components/checkbox'

interface TransferTransactionFormInterface {
  formData: TransferTransaction
  apiErrorsMessages: ApiValidationError[]
}

const TransferTransactionForm = (props: TransferTransactionFormInterface): JSX.Element => {
  const history = useHistory()
  const { buildAdminUrl, selectedCompanyId } = useUrlBuilder()

  const [sendToNewShareholder, setSendToNewShareholder] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedDateTime, setSelectedDateTime] = useState(moment().format(DATE_TIME_FORMAT))
  const [selectedShareClass, setSelectedShareClass] = useState('')
  const [customTransactionPeriod, setCustomTransactionPeriod] = useState(false)

  const [validationError, setValidationError] = useState({} as CreateTransferTransactionErrors)

  const { data } = useQuery<ManagedCompany>(GET_MANAGED_COMPANY_SHAREHOLDERS_STOCK_CLASSES, {
    variables: {
      id: selectedCompanyId
    }
  })
  const shareholders = data ? data.managedCompany.shareholders.collection : []
  const shareClasses = data ? data.managedCompany.shareClasses : []

  const { sendFrom, sendTo, amount, name, email, identifier, pricePerShare } = props.formData

  const toggleSendToNewShareholder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendToNewShareholder(event.target.checked)
  }

  useEffect(() => {
    if (!sendToNewShareholder && props.formData.sendFrom && !props.formData.sendTo) {
      setSendToNewShareholder(true)
    }

    if (!customTransactionPeriod && props.formData.customTransactionPeriod) {
      setCustomTransactionPeriod(true)
    }

    if(props.formData.dateTime && selectedDateTime !== props.formData.dateTime) {
      setSelectedDateTime(props.formData.dateTime)
    }

    if(data && !props.formData.shareClass) {
      setSelectedShareClass(data.managedCompany.defaultShareClass)
    } else {
      setSelectedShareClass(props.formData.shareClass)
    }

    const apiErrorMessages = props.apiErrorsMessages
    const apiValidationErrors: CreateTransferTransactionErrors = validationError

    if(apiErrorMessages) {
      apiErrorMessages.forEach((error: ApiValidationError) => {
        (apiValidationErrors as any )[error.fieldName] = error.message
      })
      setValidationError({...apiValidationErrors})
    }

  }, [data])

  const onChangeShareClass = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedShareClass(e.target.value)
  }

  const onSubmitForm = (event: any) => {
    event.preventDefault()
    const formData = getFormData(event.target.elements)
    const { date, time, name, email, identifier, sendTo } = formData
    let { sendFrom } = formData
    sendFrom = shareholders.find((itm: Shareholder) => itm.id === sendFrom)

    // update the formData object
    formData['sendFrom'] = sendFrom
    formData['sendTo'] = shareholders.find((itm: Shareholder) => itm.id === sendTo)
    formData['amount'] = Number(formData.amount)
    formData['pricePerShare'] = Number(formData.pricePerShare)
    formData['sendToNewShareholder'] = sendToNewShareholder
    formData['customTransactionPeriod'] = customTransactionPeriod
    formData['transactionType'] = transactionTypes[0]
    formData['dateTime'] = selectedDateTime

    const { amount, pricePerShare } = formData

    if (checkValidation(amount, pricePerShare, selectedDateTime, sendFrom, sendTo, name, email, identifier)) {
      setErrorMessage('');

      history.push({
        pathname: buildAdminUrl('/confirm-transaction'),
        state: { formData }
      })
    }
  }

  const checkValidation = (amount: number, pricePerShare: number, dateTime: string, sendFrom: Shareholder, sendTo?: string, name?: string, email?: string, identifier?: string) => {
    let response = true
    const errors: CreateTransferTransactionErrors = {}

    if(sendFrom?.id === sendTo) {
      response = false
      errors.sendTo = createTransactionErrorMessages.SENDER_SAME_AS_RECIPENT
    }

    if (!amount) {
      response = false
      errors.amount = createTransactionErrorMessages.SHARE_AMOUNT_REQUIRED
    } else if (amount <= 0) {
      response = false
      errors.amount = createTransactionErrorMessages.SHARE_AMOUNT_GREATER_THAN_ZERO
    }

    if (!pricePerShare) {
      response = false
      errors.pricePerShare = createTransactionErrorMessages.PRICE_PER_SHARE_REQUIRED
    } else if (pricePerShare <= 0) {
      response = false
      errors.pricePerShare = createTransactionErrorMessages.PRICE_PER_SHARE_GREATER_THAN_ZERO
    }

    if (!dateTime) {
      response = false
      errors.dateTime = createTransactionErrorMessages.DATE_AND_TIME_REQUIRED
    }

    if (!sendFrom) {
      response = false
      errors.sendFrom = createTransactionErrorMessages.SENDING_SHAREHOLDER_REQUIRED
    }

    if (sendToNewShareholder) {
      // Name, Email and Identification Number are required.
      if (!name) {
        response = false
        errors.name = createTransactionErrorMessages.NAME_REQUIRED
      }

      if (!email) {
        response = false
        errors.email = createTransactionErrorMessages.EMAIL_REQUIRED
      } else if (!isEmailValid(email)) {
        response = false
        errors.email = createTransactionErrorMessages.INVALID_EMAIL
      }

      if (!identifier) {
        response = false
        errors.identifier = createTransactionErrorMessages.IDENTIFICATION_NUMBER_REQUIRED
      } else {
        // Validate the length of identification number
        const validIdentificationNumberLengths = [9, 11]
        if (!validIdentificationNumberLengths.includes(identifier.length)) {
          response = false
          errors.identifier = createTransactionErrorMessages.INVALID_IDENTIFICATION_NUMBER
        }
      }
    } else {

      if (!sendTo) {
        response = false
        errors.sendTo = createTransactionErrorMessages.RECEIVEING_SHAREHOLDER_REQUIRED
      }
    }

    const sharesOfSellerBySelectedShareClass = _.find(sendFrom?.shares, { 'shareClass': selectedShareClass})

    // the one transferring don't have
    // share of the selected type
    if(!sharesOfSellerBySelectedShareClass) {
      response = false
      errors.shareClass = createUpdateFundingEventErrorMessages.INVALID_SHARE_CLASS
    } else if(sharesOfSellerBySelectedShareClass.amount < amount) {
      response = false
      errors.amount = `${createUpdateFundingEventErrorMessages.INVALID_MSG_MAX_ALLOWED_SHARES} ${sharesOfSellerBySelectedShareClass.amount}`
    }

    setValidationError(errors);
    return response;
  }

  return (
    <form onSubmit={onSubmitForm}>
      <Select
        name={'sendFrom'}
        presetStyle="formSelect"
        placeholder={"Sendt fra"}
        errorMessage={validationError.sendFrom}
        defaultValue={sendFrom?.id}
        icon={IcPerson2}
        emptyOptionsMessage={createTransactionErrorMessages.NO_SHAREHOLDERS_FOUND}
      >
        {shareholders.map((user: any, idx: number) => {
          return <option key={idx} value={user.id}>{user.name}</option>;
        })}
      </Select>

      <div className="mt-8">
        <input type="checkbox" id="sendToNewShareholder" onChange={toggleSendToNewShareholder} name="sendToNewShareholder" checked={sendToNewShareholder} />
        <label className="ml-2" htmlFor="sendToNewShareholder">Ny aksjonær</label>
      </div>

      { !sendToNewShareholder ? (<Select
        name={'sendTo'}
        presetStyle="formSelect"
        placeholder={"Sendt til"}
        errorMessage={validationError.sendTo}
        defaultValue={sendTo?.id}
        icon={IcPerson2}
        emptyOptionsMessage={createTransactionErrorMessages.NO_SHAREHOLDERS_FOUND}
      >
        {
          shareholders.map((user: any, idx: number) => {
            return <option key={idx} value={user.id} >{user.name}</option>;
          })
        }
      </Select>)
        : (
          <>
            <TextInput
              name="identifier"
              placeholder="Identifikasjon/Organisasjons nummer"
              type="text"
              errorMessage={validationError.identifier}
              defaultValue={identifier}
              icon={IcIdentification} />
            <div className="grid grid-cols-2 gap-2.5">
              <div >
                <TextInput
                  name="name"
                  placeholder={"Navn"}
                  type="text"
                  errorMessage={validationError.name}
                  icon={IcPerson}
                  defaultValue={name} />
              </div>
              <div>
                <TextInput
                  name="email"
                  placeholder={"E-post adresse"}
                  type="text"
                  errorMessage={validationError.email}
                  defaultValue={email}
                  icon={IcEmail} />
              </div>
            </div>
          </>
        )
      }

      <div className="mt-8">
        <Checkbox
          id="customTransactionTime"
          name="customTransactionTime"
          label="Velg dato og klokkeslett"
          checked={customTransactionPeriod}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setCustomTransactionPeriod(event.target.checked) }}
        />
      </div>
      {
        customTransactionPeriod && (
          <DateTimePicker
            presetStyle="flex rounded-full bg-text-input p-4 w-full"
            options={{ enableTime: true }}
            icon={IcCalendar}
            defaultValue={moment(selectedDateTime, DATE_TIME_FORMAT)}
            onChange={(date: any) => setSelectedDateTime(moment(date[0]).format(DATE_TIME_FORMAT))}
            errorMessage={validationError.dateTime}
          />
        )
      }
      <div className="grid grid-cols-2 gap-2.5">
        <div>
          <Select
            name={'shareClass'}
            presetStyle="formSelect"
            icon={IcStockClass}
            value={selectedShareClass}
            emptyOptionsMessage={createTransactionErrorMessages.NO_SHARE_CLASSES_FOUND}
            errorMessage={validationError.shareClass}
            handleChange={onChangeShareClass}
          >
            {shareClasses.map((stockClass: any, idx: number) => {
              return <option key={idx}>{stockClass}</option>;
            })}
          </Select>
        </div>
        <div>
          <TextInput
            name="amount"
            placeholder={"Antall"}
            type="number"
            errorMessage={validationError.amount}
            defaultValue={amount?.toString()}
          />
        </div>
      </div>
      <TextInput
        name="pricePerShare"
        placeholder={"Pris per aksje"}
        type="number"
        errorMessage={validationError.pricePerShare}
        defaultValue={pricePerShare?.toString()}
        icon={IcMoney} />

      <div className="form-group justify-center mt-8">
        {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
        <button
          className="btn btn-primary btn-block outline-none bg-orange-light"
          type="submit"
        >
          <p className="text-white">Lagre endringer</p>
        </button>
      </div>
    </form>
  );
};

export default TransferTransactionForm;
