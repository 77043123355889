import { gql } from '@apollo/client';

export const GET_MANAGED_COMPANY_FOUNDING_EVENTS = gql`
  query ManagedCompanyFoundingEvents(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    managedCompany(id: $companyId) {
      foundingEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          capitalPerShare
          createdAt
          name
          pricePerShare
          shareClasses
          sharePremium
          eventOn
          publicTransitionEvents {
            transition
            translation
          }
          status {
            background
            label
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_OWNED_COMPANY_FOUNDING_EVENTS = gql`
  query OwnedCompanyFoundingEvents(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    ownedCompany(id: $companyId) {
      foundingEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          capitalPerShare
          createdAt
          name
          pricePerShare
          shareClasses
          sharePremium
          eventOn
          publicTransitionEvents {
            transition
            translation
          }
          status {
            background
            label
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_SUBSCRIBED_COMPANY_FOUNDING_EVENTS = gql`
  query SubscribedCompanyFoundingEvents(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    subscribedCompany(id: $companyId) {
      foundingEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          capitalPerShare
          createdAt
          name
          pricePerShare
          shareClasses
          sharePremium
          eventOn
          publicTransitionEvents {
            transition
            translation
          }
          status {
            background
            label
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const CREATE_FOUNDING_EVENT = gql`
  mutation createFoundingEvent($attributes: FoundingEventInput!) {
    createFoundingEvent(
      attributes: $attributes
    ) {
      id
    }
  }
`;

export const UPDATE_FOUNDING_EVENT = gql`
  mutation updateFoundingEvent(
    $attributes: FoundingEventInput!
  ) {
    updateFoundingEvent(
      attributes: $attributes
    ) {
      id
      name
      description
      amount
      pricePerShare
      sharePremium
      capitalPerShare
      shareClasses
      reparation
      eventOn
      payableBy
    }
  }
`;

export const GET_FOUNDING_EVENT_DETAIL = gql`
query getFoundingEventDetail($id: ID!) {
  foundingEvent(id: $id){
    id
    name
    description
    amount
    pricePerShare
    sharePremium
    capitalPerShare
    shareClasses
    reparation
    eventOn
    payableBy
  }
}
`;

export const GET_SINGLE_FOUNDING_EVENT = gql`
query FoundingEvent($id: ID!){
  foundingEvent(id: $id){
    amount
    name
    description
    type
    boardMeeting{
      id
      city
      description
      name
      offline
      online
      postCode
      status
      streetAddress
      videoconferenceLink
      createdAt
      meetingAt
      boardMeetingAgendas{
        id
        name
        position
        description
        createdAt
      }
    }
    publicTransitionEvents{
      transition
      translation
    }
    status{
      background
      label
    }
    capitalPerShare
    createdAt
    eventOn
    id
    pricePerShare
    shareClasses
    sharePremium
    payableBy
    reparation
  }
}
`;

export const GET_FOUNDING_EVENT_SUBSCRIBERS = gql`
query EventSubscribers($id: ID!, $page: Int, $limit: Int, $sort: String, $search: String) {
  foundingEvent(id: $id){
    subscribers(page: $page, limit: $limit sort: $sort, search: $search) {
      collection {
        id
        name
        email
        phone
        status
        amount
        paid
      }
      metadata {
        totalPages
        totalCount
        currentPage
        limitValue
      }
    }
  }
}
`;

export const SEARCH_SHAREHOLDERS_FOUNDING_EVENT = gql`
query shareholders($companyId: ID!, $fundingEventId: ID!, $search: String, $sort: String) {
  managedCompany(id: $companyId) {
    shareholders(search: $search, sort: $sort) {
      collection {
        id
        name
        email
        phone
        shareholderType
      }
    }
  }

  foundingEvent(id: $fundingEventId){
    subscribers(page: 1) {
      collection {
        email
      }
    }
  }
}
`;

export const DELETE_FOUNDING_EVENT = gql`
  mutation deleteFoundingEvent($id: ID!) {
    deleteFoundingEvent(
      id: $id
    ) {
      id
    }
  }
`;
