import { ApolloError, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { TextInput } from '../../components';
import { NOTIFICATION } from '../../context/notificationContext';
import { useNotifications } from '../../hooks/notification';
import { Shareholder, NewIndividualShareholderErrors, ShareholderTypes } from '../../model';
import { UPDATE_SHAREHOLDER } from '../../services/shareholder';
import { INTERNAL_SERVER_ERROR, updateIndividualShareholderErrorMessages } from '../../utils/constants';
import { isEmailValid } from '../../utils/validators';

interface UpdateIndividualShareholderProps {
  closeModal?: () => void;
  shareholder?: Shareholder,
  onUpdateSuccess?: (shareholder: Shareholder) => void;
}

const UpdateIndividualShareholder = ({
  closeModal,
  shareholder,
  onUpdateSuccess
}: UpdateIndividualShareholderProps): JSX.Element => {
  const { setNotification } = useNotifications();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [postCode, setPostCode] = useState('0');
  const [city, setCity] = useState('');
  const [contact, setContact] = useState('');

  const [validationErrors, setValidationsErrors] = useState(
    {} as NewIndividualShareholderErrors,
  );

  useEffect(() => {
    if (shareholder) {
      setName(shareholder.name);
      setEmail(shareholder.email);
      setPhone(shareholder.phone);
      setBankAccount(shareholder.bankAccount);
      setStreetAddress(shareholder.streetAddress);
      setPostCode(shareholder.postCode?.toString() || '0');
      setCity(shareholder.city);
      setContact(shareholder.contact);
    }
  }, [shareholder]);

  const setInput = (setter: React.Dispatch<React.SetStateAction<string>>) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setter(event.target.value);
    setValidationsErrors({
      name: '',
      phone: '',
      email: '',
      bankAccount: '',
      streetAddress: '',
      postCode: '',
      city: '',
      contact: '',
    });
  };

  const [updateIndividualShareholder, { loading: updateInProgress }] = useMutation(
    UPDATE_SHAREHOLDER
  );

  const updateShareholder = (id: string) => {
    updateIndividualShareholder({
      variables: {
        attributes: {
          id,
          name,
          phone,
          email,
          bankAccount,
          streetAddress,
          postCode: parseInt(postCode),
          city,
          contact
        },
      },
    })
      .then(() => {
        setNotification({
          title: 'Shareholder updated successfully',
          type: NOTIFICATION.SUCCESS,
        });
        const updatedShareholder = { ...shareholder, name, phone, email, bankAccount, streetAddress, postCode: parseInt(postCode), city, contact } as Shareholder;

        onUpdateSuccess && onUpdateSuccess(updatedShareholder);
        closeModal && closeModal();
      })
      .catch((err: ApolloError) => {
        setNotification({
          title: err.message ?? INTERNAL_SERVER_ERROR,
          type: NOTIFICATION.ERROR,
        });
      });
  };

  const onSubmitForm = (event: React.FormEvent) => {
    event.preventDefault();
    if (checkValidation() && shareholder) {
      updateShareholder(shareholder.id);
    }
  };

  const checkValidation = () => {
    let response = true;
    const errors = {
      name: '',
      phone: '',
      email: '',
      bankAccount: '',
      streetAddress: '',
      postCode: '',
      city: '',
      contact: '',
    };

    if (!name) {
      response = false;
      errors.name = updateIndividualShareholderErrorMessages.NAME_REQUIRED;
    }

    if (!email) {
      response = false;
      errors.email = updateIndividualShareholderErrorMessages.EMAIL_REQUIRED;
    } else if (!isEmailValid(email)) {
      response = false;
      errors.email = updateIndividualShareholderErrorMessages.INVALID_EMAIL;
    }

    setValidationsErrors(errors);
    return response;
  };

  return (
    <div>
      <div className="flex justify-between items-center border-gray-200 border-b-1">
        <div>
          <h2 className="font-hind font-bold text-2xl">Endre en aksjonær</h2>
        </div>
        <button
          type="button"
          className="focus:outline-none"
          onClick={closeModal}
          tabIndex={-1}
        >
          <i className="uc-icon text-2xl text-low-gray-color">&#xeb8e;</i>
        </button>
      </div>
      <form onSubmit={onSubmitForm}>
        <TextInput
          name="name"
          placeholder="Navn"
          type="text"
          errorMessage={validationErrors.name}
          label="Navn"
          onChange={setInput(setName)}
          value={name}
        />
        <TextInput
          name="email"
          placeholder="Epost"
          type="text"
          errorMessage={validationErrors.email}
          label="Epost"
          onChange={setInput(setEmail)}
          value={email}
        />
        <TextInput
          name="phone"
          placeholder="Telefonnummer"
          type="text"
          errorMessage={validationErrors.phone}
          label="Telefonnummer"
          onChange={setInput(setPhone)}
          value={phone}
        />
        <TextInput
          name="bankAccount"
          placeholder="Bankkonto"
          type="number"
          errorMessage={validationErrors.bankAccount}
          label="Bankkonto"
          onChange={setInput(setBankAccount)}
          value={bankAccount}
        />
        <TextInput
          name="streetAddress"
          placeholder="Gateaddresse"
          type="text"
          errorMessage={validationErrors.streetAddress}
          label="Gateaddresse"
          onChange={setInput(setStreetAddress)}
          value={streetAddress}
        />
        <TextInput
          name="postCode"
          placeholder="Postkode"
          type="number"
          errorMessage={validationErrors.postCode}
          label="Postkode"
          onChange={setInput(setPostCode)}
          value={postCode}
        />
        <TextInput
          name="city"
          placeholder="Sted"
          type="text"
          errorMessage={validationErrors.city}
          label="Sted"
          onChange={setInput(setCity)}
          value={city}
        />
        {shareholder?.shareholderType == ShareholderTypes.COMPANY && <TextInput
          name="contact"
          placeholder="Kontakt informasjon"
          type="text"
          errorMessage={validationErrors.contact}
          label="Kontakt"
          onChange={setInput(setContact)}
          value={contact}
        />}
        <div className="mt-12">
          <button
            className="btn btn-primary btn-block outline-none bg-orange-light mr-0 xl:mr-4 mb-4 xl:mb-0"
            type="submit"
          >
            {updateInProgress ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#4B2A58"
              />
            ) : (
              <p className="text-white">Lagre</p>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateIndividualShareholder;
