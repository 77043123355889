import classNames from 'classnames';
import React from 'react';
// import classNames from 'classnames'

const ActionButton = ({
  onClick,
  children,
  type = 'primary',
  presetStyle,
}: {
  onClick: () => void;
  children: any;
  type?: 'primary' | 'secondary' | 'tertiary';
  presetStyle?: string;
}): JSX.Element => {
  return (
    <button
      className={classNames(
        `px-4 xl:px-0 flex justify-center items-center rounded-full transition duration-200 focus:outline-none active:outline-none bg-white ${presetStyle}`,
        {
          'hover:bg-action-hover active:bg-action-active mr-4 w-10 h-10':
            type === 'primary',
          'opacity-70 hover:opacity-100 active:opacity-100 mr-4 w-10 h-10':
            type === 'secondary',
          'bg-dashboard hover:bg-white w-8 h-8': type === 'tertiary',
        },
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
export default ActionButton;
