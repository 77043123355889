import CardImage1 from '../assets/cards/card-img-1.svg';
import CardImage2 from '../assets/cards/card-img-2.svg';
import CardImage3 from '../assets/cards/card-img-3.svg';
import CardImage4 from '../assets/cards/card-img-4.svg';
import IcDashboard from '../assets/menuIcons/ic-dashboard.svg'
import IcMoney from '../assets/menuIcons/ic-money.svg'
import IcTransaction from '../assets/menuIcons/ic-transaction.svg'
import IcShareholders from '../assets/menuIcons/ic-shareholders.svg'
import IcCompanyShares from '../assets/menuIcons/ic-company-shares.svg'
import IcDirectOwnership from '../assets/menuIcons/ic-direct-ownership.svg'
import IcIndirectOwnership from '../assets/menuIcons/ic-indirect-ownership.svg'
import IcCreateTransaction from '../assets/menuIcons/ic-create-transaction.svg'
import IcIssue from '../assets/menuIcons/ic-issue.svg'
import IcFlag from '../assets/menuIcons/ic-flag.svg'
import IcEventPurchase from '../assets/ic-event-purchase.svg'
import IcEventTransfer from '../assets/ic-event-transfer.svg'
import IcSettings from '../assets/menuIcons/settings.svg'

export enum Role {
  ADMIN = 'ADMIN',
  SHAREHOLDER = 'SHAREHOLDER',
  SUBSCRIBER = 'SUBSCRIBER'
}

export const homeMenuItems = Object.freeze([

])

export const homeCards = Object.freeze([
  {
    illustration: CardImage1,
    title: 'Emisjoner',
    description: 'Utvid selskapets egenkapital gjennom å øke andel aksjer.'
  },
  {
    illustration: CardImage2,
    title: 'Kapitalfunn',
    description: `Ved investering i gründerbedrifter, kan du få inntektsfradrag.`
  },
  {
    illustration: CardImage3,
    title: 'Aksjesplitt',
    description: 'Del opp aksjene eller spleis dem, enkelt og greit.'
  },
  {
    illustration: CardImage4,
    title: 'Administrering',
    description: `Få full oversikt over hvem som eier aksjene, eller hvilke aksjer du har investert i.`
  }
]);

export const footerElements = Object.freeze([
  {
    label: 'Produkt',
    children: [
      { label: 'Tjenester', link: '/' },
      { label: 'Prisene', link: '/' },
      { label: 'Lover', link: '/' },
      { label: 'Sikkerhet og personvern', link: '/' },
      { label: 'Cookies', link: '/' }
    ]
  },
  {
    label: 'Resurser',
    children: [
      { label: 'Logg inn', link: '/' },
      { label: 'Lag en konto', link: '/' },
      { label: 'Blogg', link: '/' },
      { label: 'Versjoner', link: '/' }
    ]
  },
  {
    label: 'Om HaaS',
    children: [
      { label: 'Om oss', link: '/' },
      { label: 'Kontakt oss', link: '/' },
      { label: 'Karriere', link: '/' },
      { label: 'Partnere', link: '/' }
    ]
  }
]);

export const screenSizes = Object.freeze({
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1028px',
  laptopM: '1200px',
  laptopL: '1440px',
  laptopXL: '1640px',
  laptopMXL: '1800px',
  screenXXL: '2560px'
});

export const primaryContainer = 'container mx-auto px-3.5 md:px-12 xl:px-30 2xl:px-36';

export const dashboardContainer = 'p-2 sm:p-6';

export const signupErrorMessages = Object.freeze({
  NAME_REQUIRED: 'Navn er påkrevd',
  EMAIL_REQUIRED: 'Epost er påkrevd',
  INVALID_EMAIL: 'Eposten ser feil ut',
  PASSWORD_REQUIRED: 'Passord er påkrevd',
  INVALID_PASSWORD: 'Passordet er for svakt (minimum 6 tegn)',
  PASSWORD_CONFIRMATION_REQUIRED: 'Passordet bekreftelse er påkrevd',
  PASSWORD_MISMATCH: 'Passordene er ikke like'
});

export const loginErrorMessages = Object.freeze({
  EMAIL_REQUIRED: 'Epost er påkrevd',
  INVALID_EMAIL: 'Eposten ser feil ut',
  PASSWORD_REQUIRED: 'Passord er påkrevd',
  INVALID_PASSWORD: 'Passordet er for svakt (minimum 6 tegn)',
});

export const TOKEN_STORE_KEY = 'INVBOK:UTKN';

export const SELECTED_COMPANY_KEY = 'INVBOK:SELCOMP'

export const createCompanyMessages = Object.freeze({
  COMPANY_NOT_SELECTED: 'Vennligst velg et selskap',
  COMPANY_ALREADY_TAKEN: 'Orgnr er allerede i bruk',
  COMPANY_NAME_REQUIRED: 'Selskapsnavn er påkrevd'
});

export const menuItems = [
  {
    label: 'Administrator',
    menus:
      [ //
        {
          label: 'Dashboard',
          icon: IcDashboard,
          route: '/admin/company/:companyId/dashboard',
        },
        {
          label: 'Selskapsinnstillinger',
          icon: IcSettings,
          route: '/admin/company/:companyId/company-settings',
        },
        {
          label: 'Emisjoner',
          icon: IcIssue,
          route: '/admin/company/:companyId/funding-events',
        },
        {
          label: 'Opprett transaksjon',
          icon: IcCreateTransaction,
          route: '/admin/company/:companyId/create-transaction',
          separator: {
            position: 'TOP'
          }
        },
        {
          label: 'Lag ny Emisjon',
          icon: IcMoney,
          route: '/admin/company/:companyId/funding-events/new',
        },
        {
          label: 'Transaksjoner',
          icon: IcTransaction,
          route: '/admin/company/:companyId/transactions',
        },
        {
          label: 'Aksjonærer',
          icon: IcShareholders,
          route: '/admin/company/:companyId/shareholders',
        },
      ],

  },
  {
    label: 'Aksjonær',
    menus: [
      // Shareholder only menus goes here

      {
        label: 'Dashboard',
        icon: IcDashboard,
        route: '/shareholder/company/:companyId/dashboard',
      },
      {
        label: 'Aksjonærer',
        icon: IcShareholders,
        route: '/shareholder/company/:companyId/shareholders',
      },
      {
        label: 'Emisjoner',
        icon: IcIssue,
        route: '/shareholder/company/:companyId/funding-events',
      },
      {
        label: 'Founding Events',
        icon: IcIssue,
        route: '/shareholder/company/:companyId/founding-events',
      },
      {
        label: 'Transaksjoner',
        icon: IcTransaction,
        route: '/shareholder/company/:companyId/transactions',
      },
      {
        label: 'Selskapets aksjer',
        icon: IcCompanyShares,
        route: '/shareholder/company/:companyId/company-shares',
      },
      {
        label: 'Mitt Eierskap',
        icon: IcFlag,
        route: '/shareholder/company/:companyId/my-ownership',
        separator: {
          position: 'TOP'
        }
      },
      {
        label: 'Direkte eierskap',
        icon: IcDirectOwnership,
        route: '/shareholder/company/:companyId/direct-ownership',
      },
      {
        label: 'Indirekte eierskap',
        icon: IcIndirectOwnership,
        route: '/shareholder/company/:companyId/indirect-ownership',
      }],

  },
  {
    label: 'Abonnent',
    menus: [
      // Subscriber only menus goes here
      // show only funding events that a Subscriber is invited to

      {
        label: 'Pågående Emisjon', // Ongoing Funding event
        icon: IcIssue,
        route: '/subscriber/company/:companyId/funding-events'
      }],

  }
]

export const transactionTypes = [
  'Aksjetransaksjon',    // Transfer
  'Splitt',              // Split
  //'Oppstarts emisjon', // Founding
  //'Emisjon',           // Funding
  //'Utbytte',           // Dividend
  'Konvertering',        // Convert
  //'Import',            // Import
];

export const createTransactionErrorMessages = Object.freeze({
  SHARE_AMOUNT_REQUIRED: 'Antall aksjer må skrives inn',
  SHARE_AMOUNT_GREATER_THAN_ZERO: 'Antall må være større enn null',
  PRICE_PER_SHARE_REQUIRED: 'Skriv inn pris pr aksje',
  PRICE_PER_SHARE_GREATER_THAN_ZERO:
    'Pris må være større enn null',
  DATE_REQUIRED: 'Dato må velges',
  TIME_REQUIRED: 'Tidspunkt må velges',
  DATE_AND_TIME_REQUIRED: 'Dato og tid er påkrevd',
  SENDING_SHAREHOLDER_REQUIRED: 'Velg aksjonær som selger aksjene',
  RECEIVEING_SHAREHOLDER_REQUIRED: 'Velg aksjonær som kjøper aksjene',
  SENDER_SAME_AS_RECIPENT: 'Sender og mottaker kan ikke være den samme',
  NAME_REQUIRED: 'Navn må fylles ut',
  EMAIL_REQUIRED: 'Epost må fylles ut',
  INVALID_EMAIL: 'Eposten må være gyldig',
  IDENTIFICATION_NUMBER_REQUIRED: ' Organisasjonsnummer eller personnummer må fylles inn',
  PLANNED_TRANSACTION_INVALID_TIME:
    'Tidspunkt må være i fremtiden',
  PLANNED_TRANSACTION_INVALID_DATE_TIME:
    'Dato og tid må være i fremtiden',
  INVALID_IDENTIFICATION_NUMBER:
    ' Organisasjonsnummer eller personummer er 9 eller 11 nummre',
  SHARE_CLASS_EQUAL:
    'Aksjeklassene kan ikke være den samme',
  NOT_ENOUGH_SHARES:
    'Valgt aksjonær har ikke nok aksjer av valgt type',
  NO_SHAREHOLDERS_FOUND:
    'Ingen aksjonærer funnet',
  NO_SHARE_CLASSES_FOUND:
    'Ingen aksjeklasser funnet'
});

export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_FORMAT_FLAT_PICKER_EQUIVALENT = 'd.m.Y'
export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_FLAT_PICKER_EQUIVALENT = 'H:i'
export const DATE_TIME_FORMAT = `${DATE_FORMAT}:${TIME_FORMAT}`
export const GENERIC_FORM_ERROR_MESSAGE = 'Sjekk skjemaet - det er en feil der'
export const UNFORMATED_DATE_TIME = 'YYYY-MM-DD hh:mm:ss z';

export const updateCompanyErrorMessages = Object.freeze({
  NAME_REQUIRED: 'Navn må fylles ut',
  COLOR_REQUIRED: 'Farge må velges',
  ORGANIZATION_NUMBER_REQUIRED: 'Organisasjons nummer er påkrevd',
  INVALID_ORGANIZATION_NUMBER: 'Organisasjons nummer har 9 tall',
  INVALID_POST_CODE: 'Legg inn en 4 sifret postkode'
})

export const createUpdateFundingEventErrorMessages = Object.freeze({
  NAME_REQUIRED: 'Navn må fylles ut',
  DESCRIPTION_REQUIRED: 'Legg inn en beskrivelse',
  AMOUNT_REQUIRED: 'Antall aksjer må skrives inn',
  INVALID_AMOUNT: 'Antall askjer må være større enn null',
  PRICE_PER_SHARE_REQUIRED: 'Pris pr aksje er påkreved',
  INVALID_PRICE_PER_SHARE: 'Pris pr aksje må være større enn null',
  SHARE_PREMIUM_REQUIRED: ' Aksje premium er påkrevd',
  INVALID_SHARE_PREMIUM: 'Premium må være 0 eller større',
  INVALID_MSG_SHARE_PREMIUM_GREATER_THAN_SHARE_CAPITAL: 'Aksje premium kan ikke være større enn pris pr aksje',
  DATE_OF_EVENT_REQUIRED: '',
  PAYABLE_BY_DATE_REQUIRED: '',
  INVALID_PAYABLE_BY_DATE: 'Betales etter dato må være før datoen for hendelsen',
  INVALID_SHARE_CLASS: "Akjsonæren har ikke aksjer i den valgte klassen",
  INVALID_MSG_MAX_ALLOWED_SHARES: "Maksimumet du har lov til å overføre fra denne andelsklassen er:"
});

export const createUpdateFoundingEventErrorMessages = Object.freeze({
  NAME_REQUIRED: 'Navn må fylles ut',
  DESCRIPTION_REQUIRED: 'Legg inn en beskrivelse',
  AMOUNT_REQUIRED: 'Antall aksjer må skrives inn',
  INVALID_AMOUNT: 'Antall askjer må være større enn null',
  PRICE_PER_SHARE_REQUIRED: 'Pris pr aksje er påkreved',
  INVALID_PRICE_PER_SHARE: 'Pris pr aksje må være større enn null',
  SHARE_PREMIUM_REQUIRED: ' Aksje premium er påkrevd',
  INVALID_SHARE_PREMIUM: 'Premium må være 0 eller større',
  INVALID_MSG_SHARE_PREMIUM_GREATER_THAN_SHARE_CAPITAL: 'Aksje premium kan ikke være større enn pris pr aksje',
  DATE_OF_EVENT_REQUIRED: '',
  PAYABLE_BY_DATE_REQUIRED: '',
  INVALID_PAYABLE_BY_DATE: 'Betales etter dato må være før datoen for hendelsen',
  INVALID_SHARE_CLASS: "Akjsonæren har ikke aksjer i den valgte klassen",
  INVALID_MSG_MAX_ALLOWED_SHARES: "Maksimumet du har lov til å overføre fra denne andelsklassen er:"
});

export const eventTypeIcon = {
  'transfer': IcEventTransfer,
  'purchase': IcEventPurchase,
  'founding': IcEventPurchase,
  'split': IcEventPurchase,
  'convert': IcEventPurchase,
  'dividend': IcEventPurchase
}

export const newBoardMeetingErrors = Object.freeze({
  NAME_REQUIRED: 'Navn må fylles ut',
  DESCRIPTION_REQUIRED: 'Legg inn en beskrivelse',
  VENUE_REQUIRED: `Møtested må velges - enten virtuelt eller fysisk`,
  CONFERENCE_LINK_REQUIRED: 'Legg inn link til videokonferanse',
  INVALID_LINK: 'Den nettadressen ser feil ut',
  STREET_ADDRESS_REQUIRED: 'Legg inn gateadresse',
  POST_CODE_REQUIRED: 'Legg inn postkode',
  INVALID_POST_CODE: 'Legg inn en 4 sifret postkode',
  CITY_REQUIRED: 'Legg inn stedsnavn',
  MEETING_AT_REQUIRED: 'Velg et tidspunkt for møtet'
});

export const boardMeetingStatus = Object.freeze({
  initial: 'initial'
})

export const webLinkRegex = `!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i`;

export const postCodeLength = 4;

export const invalidPostCode = '0000';

export const backendErrorCodes = Object.freeze({
  AUTHENTICATION_ERROR: "AUTHENTICATION_ERROR"
})

export const voteStatus = Object.freeze([
  'yes',
  'pass',
  'no',
])

export const INTERNAL_SERVER_ERROR = 'Noe gikk galt, prøv igjen';

export const paginationElements = Object.freeze({
  INITIAL_PAGE: 1,
  LIMIT: 5
})

export const boardMeetingEditRoute = '/admin/company/:companyId/board-meetings/:eventId/edit';

export const fundingEventTabs = [
  { name: 'Emisjon', href: '/event', identifier: 'event-description' },
  // { name: 'Generalforsamling', href: '/board-meeting', identifier: 'board-meeting' },
  { name: 'Tegningsliste', href: '/subscribers', identifier: 'subscribers' },
  { name: 'Betalingsinformasjon', href: '/payment-details', identifier: 'payment-details' },
];

export const newIndividualSubscriberErrorMessages = Object.freeze({
  EMAIL_REQUIRED: 'Epost er påkrevd',
  INVALID_EMAIL: 'Eposten ser feil ut',
  NAME_REQUIRED: 'Navn er påkrevd',
  PHONE_REQUIRED: 'Telefonnummer is required',
  INVALID_AMOUNT: 'Antall askjer må være større enn null'
})

export const updateIndividualShareholderErrorMessages = Object.freeze({
  EMAIL_REQUIRED: 'Epost er påkrevd',
  INVALID_EMAIL: 'Eposten ser feil ut',
  NAME_REQUIRED: 'Navn er påkrevd',
})

export const modalIdentifiers = Object.freeze({
  ADD_INDIVIDUAL_SUBSCRIBER: "add-individual-subscriber"
})

export const unsavedChangesDefaultMessage = 'Er du sikker på at du vil forkaste endringene du har gjort?';

export enum FundingEventStatus {
  DRAFT = 'Utkast',
  COMPLETED = 'Fullført'
}

export enum FoundingEventStatus {
  DRAFT = 'Utkast',
  COMPLETED = 'Fullført'
}

export enum Roles {
  ADMIN,
  SHAREHOLDER,
  SUBSCRIBER
}
export const exportOptions = [
  {
    label: 'CSV',
    value: 'csv'
  },
]

export const fundingEventsRoutePrefix = "funding-events";

export const ShareIssuanceEventTypes = Object.freeze({
  FoundingEvent: 'FoundingEvent',
  FundingEvent: 'FundingEvent'
})
