/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, useEffect, useState } from 'react';
import {
  useTable,
  Column,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from 'react-table';
import TablePagination from './pagination';
import IconSearch from '../../assets/icons/icon-search-2.svg';
import _ from 'lodash';
import { EmptyIcon } from '../icons';
import TableHeader from './header';
import TableBody from './tableBody';
import '../../styles/table.scss';
import MobileTable from './mobile';
import { paginationElements } from '../../utils/constants';
import { exportToCsv } from '../../utils/csvGenerator';
import { Button, Skeleton } from '../../components';
import { PagingMetadata } from '../../model';

interface TableComponentProps {
  columns: Column<Record<string, string | boolean>>[];
  data: Array<Record<string, string | any>>;
  searchable?: boolean;
  paginate?: boolean;
  initialPageSize?: number;
  tableHeader?: boolean;
  presetStyle?: string;
  onTableChange?: () => void;
  setPageMetaData?: any;
  pageMetaData?: any;
  setSortValue?: any;
  sortValue?: string;
  searchValue?: string;
  setSearchValue?: any;
  isLoading?: boolean;
  fileName?: string;
}

const TableComponent = ({
  columns,
  data,
  searchable,
  paginate,
  initialPageSize,
  tableHeader,
  presetStyle,
  pageMetaData,
  setPageMetaData,
  onTableChange,
  setSortValue,
  sortValue,
  searchValue,
  setSearchValue,
  isLoading = false,
  fileName,
}: TableComponentProps): JSX.Element => {
  const [toUseColumns, setToUseColumns] = useState(
    [] as Column<Record<string, string | boolean>>[],
  );
  const [initialLoad, setInitialLoad] = useState(true);
  const [previousMetaData, setPreviousMetaData] = useState(
    {} as PagingMetadata,
  );
  const [previousSearch, setPreviousSearch] = useState('');
  const [selectedExportOption, setSelectedExportOption] = useState('');
  const [isShowTable, setIsShowTable] = useState(true);

  useEffect(() => {
    let toUseColumns = columns;
    if (searchable) {
      toUseColumns = _.unionBy(
        columns,
        [
          {
            Header: (
              <img
                src={IconSearch}
                className="absolute top-4 right-4 hidden lg:block"
              />
            ),
            accessor: 'search',
            width: 50,
            maxWidth: 50,
          },
        ],
        'accessor',
      );
    }
    setToUseColumns(toUseColumns);
  }, []);
  useEffect(() => {
    setInitialLoad(false);
    !initialLoad &&
      onTableChange &&
      !pageMetaData.skipRefetch &&
      !isLoading &&
      onTableChange();
  }, [pageMetaData, sortValue, searchValue]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    // filter
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns: useMemo(() => toUseColumns, [toUseColumns]),
      data: useMemo(() => data, [data]),
      initialState: {
        pageIndex: paginationElements.INITIAL_PAGE - 1,
        pageSize: pageMetaData?.limitValue ?? paginationElements.LIMIT,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const handleSorting = (columnId: string) => {
    let newSortVal = '';
    if (sortValue?.split(' ')[0] !== columnId) {
      newSortVal = `${columnId} asc`;
    } else {
      if (sortValue?.split(' ')[1] === 'asc') {
        newSortVal = `${columnId} desc`;
      } else newSortVal = `${columnId} asc`;
    }
    setPageMetaData({ ...pageMetaData, skipRefetch: false });
    setSortValue(newSortVal);
  };

  const goToInitialPage = () => {
    gotoPage(0);
    setPageMetaData({
      ...pageMetaData,
      currentPage: 1,
    });
  };

  const isTableExport = useMemo(
    // @ts-ignore
    () => columns.some((el) => el.isExported),
    [columns],
  );

  const printableColumns = useMemo(
    // @ts-ignore
    () => columns.filter((el) => el.isExported),
    [columns],
  );

  const toPrintData = useMemo(() => data, [data]);

  const handleGenerateCSV = () => {
    const CsvString: any[] = [];

    CsvString.push([printableColumns.map((el) => el.Header)]);

    toPrintData.map((elt) => {
      CsvString.push(
        '\n',
        // @ts-ignore
        printableColumns.map((el) => elt[el.accessor]),
      );
    });

    exportToCsv({
      CsvString,
      extension: selectedExportOption,
      title: fileName,
    });
  };

  useEffect(() => {
    if (
      selectedExportOption &&
      Object.values(pageMetaData).length !== 0 &&
      !isLoading
    ) {
      handleGenerateCSV();
      setPageMetaData(previousMetaData);
      setSearchValue(previousSearch);
      setSelectedExportOption('');
      setTimeout(() => {
        setIsShowTable(true);
      }, 500);
    }
  }, [selectedExportOption, pageMetaData, isLoading]);

  return (
    <div className="w-full">
      <div className={`w-full ${tableHeader && 'hidden'} lg:block`}>
        {isTableExport && (
          <div className="flex mb-4 justify-end">
            <Button
              label="Eksporter til CSV"
              toExecute={() => {
                setSelectedExportOption('csv');
                setPreviousMetaData(pageMetaData);
                setPageMetaData({});
                setPreviousSearch(searchValue as string);
                setSearchValue('');
                setIsShowTable(false);
              }}
              type="button-5"
            />
          </div>
        )}

        {pageMetaData &&
        Object.values(pageMetaData).length !== 0 &&
        !selectedExportOption &&
        isShowTable ? (
            <table {...getTableProps()} className="w-full">
              {tableHeader && (
                <TableHeader
                  headerGroups={headerGroups}
                  searchable={searchable}
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  sortValue={sortValue}
                  handleSorting={handleSorting}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  goToInitialPage={goToInitialPage}
                />
              )}

              <TableBody
                data={data}
                getTableBodyProps={getTableBodyProps}
                page={page}
                prepareRow={prepareRow}
                presetStyle={presetStyle}
              />
            </table>
          ) : (
            <Skeleton />
          )}
      </div>
      <MobileTable
        headerGroups={headerGroups}
        tableHeader={tableHeader}
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        page={page}
        prepareRow={prepareRow}
        toUseColumns={toUseColumns}
        sortValue={sortValue}
        handleSorting={handleSorting}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        goToInitialPage={goToInitialPage}
        isTableExport={isTableExport}
        handlePageMeta={() => {
          setPageMetaData({});
          setPreviousMetaData(pageMetaData);
        }}
        setSelectedExportOption={setSelectedExportOption}
      />

      {data.length === 0 && (
        <div className="w-full flex justify-center items-center mt-8 flex-col">
          <EmptyIcon />
          <div>
            <span className="font-roboto text-disabled">No Data</span>
          </div>
        </div>
      )}
      {paginate &&
        data.length !== 0 &&
        pageCount !== 0 &&
        isShowTable &&
        pageMetaData &&
        Object.values(pageMetaData).length !== 0 && (
        <TablePagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          pageCount={pageMetaData?.totalPages}
          canNextPage={canNextPage}
          pageIndex={pageMetaData?.currentPage - 1}
          pageOptions={pageOptions}
          pageSize={pageMetaData?.limitValue}
          setPageSize={setPageSize}
          setPageMetaData={setPageMetaData}
          pageMetaData={pageMetaData}
        />
      )}
    </div>
  );
};

export default TableComponent;
