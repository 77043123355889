import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ReactLoading from 'react-loading';
import { isArrayEqual } from '../../utils/miscellaneous'

interface dataElement {
  value: string,
  label: string | number | JSX.Element,
  disabled?: boolean
}

interface DataListProps {
  name?: string
  data: any[];
  icon?: string;
  label?: string;
  handleSelect: (element: string) => void;
  handleTextChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  placeHolder: string;
  errorMessage?: string;
  presetStyle?: string;
  presetStyleInput?: string;
  presetStyleOptionsWrapper?: string;
  value?: string;
  dataGenerator: (rawData: any[]) => dataElement[];
  focus?: boolean;
  closeOptionsAfterSelect?: boolean
}

const DataList = ({
  name,
  data,
  icon,
  label,
  handleSelect,
  handleTextChange,
  loading,
  placeHolder,
  errorMessage,
  presetStyle,
  presetStyleInput,
  presetStyleOptionsWrapper,
  value,
  dataGenerator,
  focus,
  closeOptionsAfterSelect
}: DataListProps): JSX.Element => {
  const [rawData, setRawData] = useState([] as any[])
  const [toUseData, setToUseData] = useState([] as dataElement[]);
  const [selected, setSelected] = useState('');
  const [showHide, setShowHide] = useState(data.length !== 0);
  const [initialLoad, setInitialLoad] = useState(true);
  const inputRef = useRef(null)
  const [lastAction, setLastAction] = useState('')

  useEffect(() => {
    if (!isArrayEqual(rawData, data)) {
      setToUseData(dataGenerator(data));
      setRawData(data)

      let response = data.length !== 0;
      if (errorMessage) {
        response = false;
      }

      if (!initialLoad) {
        response = false;
        setInitialLoad(true);
      }

      const current: any = inputRef.current
      if(document.activeElement === current && data.length)
        response = true
      else if(!showHide)
        response = false

      setShowHide(response);
    }

  }, [data]);

  // focus after an error message
  useEffect(() => {
    const current: any = inputRef.current
    const focusedElement = document.activeElement

    if(focusedElement?.nodeName !== 'INPUT' && current && errorMessage)
      current.focus()

  },[errorMessage])

  return (
    <div
      className={`${!showHide && !errorMessage ? 'rounded-full' : 'rounded-t-c-2xl'
      } py-4`}
      onPointerLeave={() => setShowHide(false)}
      onMouseOver={() => { toUseData.length !== 0 && !errorMessage && setShowHide(true) } }
      id="data-list-wrapper"
    >
      <div className={`pl-4 mb-1 font-bold ${label ? 'block mt-4' : 'hidden'}`}>{label}</div>
      <div className={`flex items-center px-4 ${!showHide ? 'rounded-full' : 'rounded-t-c-2xl'} ${presetStyle} ${errorMessage ? 'border-primary-red' : 'border-text-input'} `}>
        <div>{icon && <img src={icon} className="mr-2 w-5" />}</div>
        <input
          ref={inputRef}
          name={name}
          id="data-list-input"
          type="text"
          className={`${presetStyleInput} w-full outline-none font-roboto bg-first-card-box-color `}
          placeholder={placeHolder}
          onChange={handleTextChange}
          autoComplete="off"
          value={value}
          autoFocus={focus}
          onBlur={() => { lastAction === 'Tab' && setShowHide(false) }} // hide the options if blur is cause by tab.
          onFocus={() => { !errorMessage && data.length && setShowHide(true) }}
          onKeyDown={(e) => { setLastAction(e.code) }}
        />
        {loading && (
          <ReactLoading
            type="spinningBubbles"
            height={'20px'}
            width={'20px'}
            color="#4B2A58"
          />
        )}
      </div>
      {
        errorMessage && (
          <div className="text-right pr-4 pt-2 form-group">
            <p className="form-error">{errorMessage}</p>
          </div>
        )
      }
      {showHide && !errorMessage && (
        <div className="relative">
          <div className={`absolute w-full rounded-b-c-2xl overflow-y-auto h-80 ${presetStyleOptionsWrapper} `}>
            {toUseData.map((el , index) => (
              <div
                key={index}
                className={`pb-4 px-8 py-4 hover:bg-ninth-color cursor:pointer ${el.value.toString() === selected && 'bg-ninth-color'
                }`}
                onClick={() => {
                  if(!el.disabled) {
                    setSelected(el.value.toString());
                    handleSelect(el.value.toString());
                    closeOptionsAfterSelect && setShowHide(false);
                  }
                }}
                id="data-list-item"
                onMouseDown={() => { setLastAction('click') }}
              >
                <span>{el.label}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataList;
