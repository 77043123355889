import { FundingEventStatus, PublicTransitionEvents } from '../model'

export const handleEventStatus = (eventStatus: FundingEventStatus, transitions: Array<PublicTransitionEvents>) => {
  let pillColor = 'warning';
  if (!eventStatus.background) {
    if (transitions.length !== 0)
      pillColor = 'default'
    else pillColor = 'success'
  }

  return pillColor as 'default' | 'success' | 'warning' | 'danger';
};

