import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import ClientSession from '../utils/clientSession';
import { cache } from '../store/cache'
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { backendErrorCodes } from '../utils/constants'
import { setGraphQLQueryError } from '../store/cache'
import { GraphqlQueryError } from '../model/common';
import { onError } from "@apollo/client/link/error";

const MAX_BATCH_OPERATION = 5 // No more than 5 operations per batch
const BATCH_INTERVAL = 10 // Wait no more than 10ms after first batched operation

const batchHttpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_INVESTORBOK_API_URL,
  batchMax: MAX_BATCH_OPERATION,
  batchInterval: BATCH_INTERVAL
});

const authLink = setContext((_, { headers }) => {
  const authHeaders = ClientSession.getAuthHeader();
  const parsedHeaders = authHeaders && JSON.parse(authHeaders);

  return {
    headers: {
      ...headers,
      ...parsedHeaders
    }
  }
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {

    const { response: { headers } } = operation.getContext();
    if (headers.get('client')) ClientSession.storeAuthHeader(formatAuthHeader(headers));
    return response
  })
})

const errorLink = onError(({ graphQLErrors, networkError }) => {

  const queryError = {} as GraphqlQueryError

  if (graphQLErrors && graphQLErrors.length) {
    const graphQLError = graphQLErrors[0]
    const extensions = (graphQLError as any).extensions
    const type = (graphQLError as any).type

    if(type) {
      switch(type) {
      default:
        queryError.status = 404
        queryError.message = graphQLError.message
      // Handle also other errors.
      }
    } else if (extensions && extensions.code === backendErrorCodes.AUTHENTICATION_ERROR) {
      ClientSession.logoutUser();
    }

    setGraphQLQueryError(queryError)
  }

  if(networkError) {
    setGraphQLQueryError({
      status:500,
      message:'Internal Server Error'
    })
  }

});

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_INVESTORBOK_API_URL });

export const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink, authLink, afterwareLink, uploadLink, batchHttpLink
  ]),
  cache,
});

const formatAuthHeader = (headers: any) => {

  return JSON.stringify({
    client: headers.get('client'),
    expiry: headers.get('expiry'),
    'access-token': headers.get('access-token'),
    'token-type': headers.get('token-type'),
    uid: headers.get('uid'),
  })
}
