import React, { Component } from 'react';
import '../../styles/textInput.scss'

interface TextInputProps {
  errorMessage?: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string
  name: string
  type: string
  label?: string
  placeholder?: string
  icon?: any
  defaultValue?: string | number
  value?: string | number
  disabled?: boolean
  presetStyle?: string
  autoFocus?: boolean
  tabIndex?: number
}

const TextInput = (props: TextInputProps) => {

  const { id, name, type, label, placeholder, icon, defaultValue, errorMessage, onChange, disabled, value, presetStyle, autoFocus, tabIndex } = props

  // This helper function is used to not allow user to enter a text character into a number field in mozila firefox
  const validateNumber = (evt: any) => {
    const theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const onKeyPress = (evt: any) => {
    if(type === "number") {
      return validateNumber(evt)
    }
  }

  return (
    <div className="mt-8 relative">
      <div className={`pl-4 mb-1 font-bold ${label ? 'block' : 'hidden'}`}>{label}</div>
      <div key={`${defaultValue}${name}`} className={`input-box ${errorMessage ? 'border-primary-red border-2' : 'border-2 border-text-input'} ${presetStyle} ${disabled ? 'opacity-60': ''}`} >
        <img src={icon} className={`${icon ? 'block' : 'hidden'}`} />
        <input
          className="bg-inherit flex flex-1 ml-1 focus:outline-none placeholder-default disabled:opacity-60 w-inherit"
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
          onKeyPress={onKeyPress}
          value={value}
          autoFocus={autoFocus}
          tabIndex={tabIndex}
        />
      </div>
      <div className="text-right pr-4 pt-2 form-group">
        {errorMessage && <p className="form-error">{errorMessage}</p>}
      </div>
    </div>

  );
};

export default TextInput;
