import React from 'react';
import '../../styles/dateTimePicker.scss';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import { Norwegian } from 'flatpickr/dist/l10n/no';
import { Moment } from 'moment';
import { toLocaleDateTime } from '../../utils/formatTime';

interface DateTimePickerInterface {
  label?: string;
  icon?: string;
  placeholder?: string;
  defaultValue?: Moment;
  errorMessage?: string;
  onChange: any;
  dateFormat?: string;
  options?: any;
  disabled?: boolean;
  presetStyle?: string;
}

const DateTimePicker = (props: DateTimePickerInterface) => {
  const {
    onChange,
    defaultValue,
    label,
    icon,
    errorMessage,
    dateFormat,
    options,
    placeholder,
    disabled,
    presetStyle,
  } = props;

  return (
    <div className="mt-8 relative">
      <div className={`pl-4 mb-1 font-bold ${label ? 'block' : 'hidden'}`}>
        {label}
      </div>
      <div
        className={`${presetStyle} ${
          errorMessage ? 'border-primary-red border-2' : null
        }`}
      >
        <img src={icon} className={`${icon ? 'block' : 'hidden'}`} />
        <div className="ml-1 w-full">
          <Flatpickr
            value={defaultValue?.toDate()}
            options={{
              locale: Norwegian,
              dateFormat,
              formatDate: toLocaleDateTime,
              ...options
            }}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            className="w-full"
          />
        </div>
      </div>
      <div className="text-right pr-4 pt-2 form-group">
        {errorMessage && <p className="form-error">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default DateTimePicker;
