import React from 'react';
import DefaultProfilePictureHolder from '../../assets/menuIcons/profilePlaceholder.svg';
import Unfold from '../../assets/menuIcons/ic-unfold.svg';
import { useLayout } from '../../hooks/layout';
import Tippy from '@tippyjs/react';

interface ProfileInitialProps {
  fullName: string;
  role: string;
  profilePictureUrl?: string;
  toggleProfile: any
}

const Profile = (props: ProfileInitialProps): JSX.Element => {
  const { toggleProfile } = props
  const { sidebarCollapsed } = useLayout()

  return sidebarCollapsed ?
    (
      <Tippy content="Profil" placement="right" onShow={() => { if(!sidebarCollapsed) return false }}>
        <img className="w-7 h-7 mx-auto cursor-pointer" src={props.profilePictureUrl ? props.profilePictureUrl : DefaultProfilePictureHolder} onClick={toggleProfile} />
      </Tippy>
    )
    :
    (
      <div className="flex px-5 items-center">
        <img className="mr-3 w-8 h-8" src={props.profilePictureUrl ? props.profilePictureUrl : DefaultProfilePictureHolder} />
        <div className="flex-1">
          <div className="flex justify-start">
            <p className="font-bold whitespace-nowrap overflow-ellipsis overflow-hidden max-w-180">
              {props.fullName}
            </p>
          </div>
          <div className="flex justify-start">{props.role}</div>
        </div>
        <img className="h-6 w-6 mx-2 cursor-pointer" src={Unfold} onClick={toggleProfile} />
      </div>
    )
};

export default Profile;
