import React, { useState } from 'react';
import { CREATE_SPLIT_TRANSACTION } from '../../../../services/transaction'
import IcMoney from '../../../../assets/menuIcons/ic-money.svg';
import IcClock from '../../../../assets/icons/ic-clock.svg';
import IcCalendar from '../../../../assets/icons/ic-calendar.svg';
import { Label } from '../../../label';
import moment from 'moment'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import ReactLoading from 'react-loading';
import { GET_SELECTED_COMPANY } from '../../../../services/company';
import { DATE_TIME_FORMAT } from '../../../../utils/constants'
import { SplitTransaction } from '../../../../model/transaction'
import { ApiValidationError } from '../../../../model/common'
import { GENERIC_FORM_ERROR_MESSAGE } from '../../../../utils/constants';
import { toLocaleDateTime } from '../../../../utils/formatTime';
import { useNotifications } from "../../../../hooks/notification";
import { NOTIFICATION } from '../../../../context/notificationContext';
import { useUrlBuilder } from '../../../../hooks/urlBuilder'

interface ConfirmSplitTransactionProps {
  formData: SplitTransaction
  onClickBack: any
}

const ConfirmSplitTransaction = (props: ConfirmSplitTransactionProps): JSX.Element => {
  const history = useHistory()
  const { buildAdminUrl, selectedCompanyId } = useUrlBuilder()

  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [createSplitTransaction] = useMutation(CREATE_SPLIT_TRANSACTION)
  const { amount, dateTime } = props.formData
  const { onClickBack } = props
  const transactionAt = moment(dateTime, DATE_TIME_FORMAT)

  const [apiErrorMessages, setApiErrorMessages] = useState<ApiValidationError[]>([])

  const { setNotification } = useNotifications();

  const onConfirm = () => {
    setSubmitting(true)
    createSplitTransaction({
      variables: {
        companyId: selectedCompanyId,
        amount,
        transactionAt
      },
    })
      .then((res) => {
        setSubmitting(false);
        setNotification({ title: 'Transaksjon lagret!', type: NOTIFICATION.SUCCESS })
        history.push(buildAdminUrl('/transactions'))
      })
      .catch((err: ApolloError) => {
        setSubmitting(false);
        const apiErrorResponse: ApiValidationError[] = err.graphQLErrors.map((error) => {
          const path = error.path
          return  {
            fieldName: path ? path[1].toString() : '',
            message: error.message
          }

        })
        setNotification({ title: GENERIC_FORM_ERROR_MESSAGE, type: NOTIFICATION.ERROR })
        setApiErrorMessages(apiErrorResponse)
        onClickBack(props.formData, apiErrorResponse)
      });
  }

  return (
    <div className="space-y-4">
      <Label label="Splitt" presetStyle="font-bold" />
      <Label label={amount.toString()} icon={IcMoney} />
      <Label label={toLocaleDateTime(moment(dateTime, DATE_TIME_FORMAT).format())} icon={IcCalendar} />
      <button
        className="btn btn-block outline-none bg-first-btn-color text-second-text-color"
        onClick={() => { onClickBack(props.formData, apiErrorMessages) }}
      >Tilbake</button>
      <button
        className="btn btn-primary btn-block outline-none bg-orange-light"
        onClick={onConfirm}
      >
        {submitting ? (
          <ReactLoading
            type="bars"
            height="30px"
            width="30px"
            color="#4B2A58"
          />
        ) : (
          <p className="text-white">Lagre endringer</p>
        )}
      </button>
      <div className="flex justify-center">
        {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ConfirmSplitTransaction;
