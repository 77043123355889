import React, { useState, useEffect } from 'react';
import { primaryContainer } from '../../utils/constants';
import { useLazyQuery, useMutation, ApolloError } from '@apollo/client';
import {
  GET_SUBSCRIBER_INFO,
  ACCEPT_SUBSCRIBER_INVITATION,
  REJECT_SUBSCRIBER_INVITATION,
} from '../../services/subscriber';
import {
  SubscriberVar,
  SubscriberInvitationProps,
  SubscriberInvitationDetails,
} from '../../model';
import { useParams, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';

const SubscriberInvitation = (): JSX.Element => {
  const history = useHistory();
  const [subscriberInfo, setSubscriberInfo] = useState(
    {} as SubscriberInvitationDetails,
  );
  const { subscriber_id: subscriberId } = useParams<{
    subscriber_id: string;
  }>();

  const [isError, setIsError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [
    getSubscriberInfo,
    {
      error: subscriberError,
      loading: subscriberLoading,
      called: subscriberCalled,
    },
  ] = useLazyQuery<SubscriberInvitationProps, SubscriberVar>(
    GET_SUBSCRIBER_INFO,
    {
      onCompleted: (result) => {
        setSubscriberInfo(result.subscriberInfo);
        setIsDataLoading(false);
      },
      onError: () => {
        setSubscriberInfo({} as SubscriberInvitationDetails);
        setIsDataLoading(false);
      },
    },
  );

  const [
    acceptSubscriberInvitation,
    { loading: isAcceptLoading },
  ] = useMutation(ACCEPT_SUBSCRIBER_INVITATION);

  const [
    rejectSubscriberInvitation,
    { loading: isRejectLoading },
  ] = useMutation(REJECT_SUBSCRIBER_INVITATION);

  useEffect(() => {
    getSubscriberInfo({
      variables: {
        subscriberId,
      },
    });
  }, []);

  const handleDecision = (decisionHandler: any) => {
    decisionHandler({
      variables: {
        subscriberId,
      },
    })
      .then(() => {
        history.push(
          `/subscriber/company/${subscriberInfo.companyId}/dashboard`,
        );
      })
      .catch((err: ApolloError) => {
        setIsError(true);
      });
  };
  if (isDataLoading) return <div></div>;

  return !Object.values(subscriberInfo).length && subscriberCalled ? (
    <div className={`${primaryContainer} py-36 h-auto`}>
      <div className="text-center h-full flex flex-col justify-center mt-0 xl:mt-12">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-fifth-color uppercase tracking-wide">
                FEIL
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Gammel invitasjon.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Invitasjonen din er gått ut på dato eller allerede brukt.
                <br />
                Be om å få tilsendt ny invitasjon
              </p>
              <div className="mt-6">
                <div
                  onClick={() => history.push('/')}
                  className="text-base font-medium text-fifth-color hover:opacity-90 cursor-pointer"
                >
                  Til hjemmesiden<span aria-hidden="true"> &rarr;</span>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  ) : (
    <div className={`${primaryContainer} py-36 h-auto`}>
      <div className="text-center h-full flex flex-col justify-center mt-0 xl:mt-12">
        <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
          <span className="block xl:inline font-hind">
            Du har blitt invitert til en
            <br /> emisjon for
          </span>{' '}
          <span className="block text-fifth-color xl:inline font-hind">
            {subscriberInfo?.companyName}
          </span>
        </h1>
        <div className="flex justify-center w-full">
          <div className="bg-white shadow overflow-hidden rounded-md w-full lg:w-1/2 text-left ">
            <ul className="divide-y divide-gray-200 text-base xl:text-lg">
              <li className="px-6 py-4">
                <span className="text-gray-900 font-roboto">
                  {subscriberInfo?.eventName}
                </span>
              </li>
              <li className="px-6 py-4">
                <pre className="text-gray-900 font-roboto">
                  {subscriberInfo?.eventDescription}
                </pre>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <button
              type="button"
              onClick={() => handleDecision(acceptSubscriberInvitation)}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sixth-color hover:opacity-90 md:py-4 md:text-lg md:px-16 font-roboto"
            >
              {isAcceptLoading ? (
                <ReactLoading
                  type="bars"
                  height={'30px'}
                  width={'30px'}
                  color="#fff"
                />
              ) : (
                'Ja'
              )}
            </button>
          </div>
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <button
              type="button"
              onClick={() => handleDecision(rejectSubscriberInvitation)}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-sixth-color bg-white hover:opacity-90 md:py-4 md:text-lg md:px-16"
            >
              {isRejectLoading ? (
                <ReactLoading
                  type="bars"
                  height={'30px'}
                  width={'30px'}
                  color="#4B2A58"
                />
              ) : (
                'Nei'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriberInvitation;
