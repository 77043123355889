import React, { useState, useEffect } from 'react';
import '../../../styles/sidebar.scss';
import SidebarNavItem from './sidebarNavItem';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { useLayout } from '../../../hooks/layout';
import { getAbsoluteHeight } from '../../../utils/miscellaneous';

interface SidebarNavItemsInterface {
  onSelectMenuItem?: any;
  isMobile?: boolean;
}

const SidebarNavItems = (props: SidebarNavItemsInterface): JSX.Element => {
  const { onSelectMenuItem, isMobile = false } = props;
  const {
    sidebarCollapsed,
    openedMenuSectionIndex,
    setOpenedMenuSectionIndex,
    menu,
    firstSectionInitialOpen,
    setFirstSectionInitialOpen,
    allSidebarSectionsPopulated,
    setAllSidebarSectionsPopulated,
  } = useLayout();

  const onToggle = (index: number) => {
    index === openedMenuSectionIndex
      ? setOpenedMenuSectionIndex(-1)
      : setOpenedMenuSectionIndex(index);
  };

  useEffect(() => {
    if (!firstSectionInitialOpen && allSidebarSectionsPopulated) {
      setOpenedMenuSectionIndex(0);
      setFirstSectionInitialOpen(true);
    }
  }, [allSidebarSectionsPopulated]);

  return (
    <div className="bg-inherit">
      <div className="max-w-7xl mx-auto px-2">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <dl className="mt-6 space-y-2">
            {menu.map((item, index, array) => {
              const opened = index == openedMenuSectionIndex;
              if (array.length - 1 == index && !allSidebarSectionsPopulated)
                setAllSidebarSectionsPopulated(true);
              return (
                <div key={index}>
                  <div
                    className={`flex ${
                      sidebarCollapsed && !isMobile ? 'flex-col items-center' : ''
                    }`}
                    onClick={() => onToggle(index)}
                  >
                    <span
                      className={`flex-1 text-left font-medium text-lg cursor-pointer text-seven-color font-hind ${
                        sidebarCollapsed && !isMobile ? 'label-sidebar-collapsed' : ''
                      }`}
                    >
                      {item.label}
                    </span>
                    <span
                      className={`h-7 flex items-center ${
                        sidebarCollapsed && !isMobile ? 'ml-0' : 'ml-6'
                      }`}
                    >
                      <ChevronDownIcon
                        className={`text-first-text-color ${
                          opened
                            ? '-rotate-180 h-6 w-6 transform'
                            : 'rotate-0 h-6 w-6 transform'
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  </div>

                  <div
                    className="section-wrapper overflow-hidden"
                    style={{
                      height: opened
                        ? getAbsoluteHeight(`#menuSection${index}`)
                        : 0,
                    }}
                  >
                    <div
                      id={`menuSection${index}`}
                      className={`bg-nav-groupping  p-2 rounded-3xl ${
                        sidebarCollapsed && !isMobile
                          ? 'rounded-full  inline-block px-2'
                          : null
                      }`}
                    >
                      {item.menus.map((navItem: any, idx: number) => {
                        return (
                          <div className="px-2" key={idx}>
                            <SidebarNavItem
                              {...navItem}
                              onClickNavItem={onSelectMenuItem}
                              isMobile={isMobile}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default SidebarNavItems;
