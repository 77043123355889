import React, { useLayoutEffect, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import ClientSession from '../utils/clientSession';
import { useQuery } from '@apollo/client';
import { GET_SIGNED_IN_USER } from '../services/auth';
import { SignedInUser } from '../model/user';
import { setCallBackLink } from '../store/cache';
import { useUrlBuilder } from '../hooks/urlBuilder'

interface ProtectedRoute {
  path: string;
  key: number;
  exact: boolean;
  render: (props: unknown) => JSX.Element;
}

const ProtectedRoute = ({ path, ...rest }: ProtectedRoute): JSX.Element => {
  const { buildAdminUrl } = useUrlBuilder()
  const { data } = useQuery<SignedInUser>(GET_SIGNED_IN_USER, {
    fetchPolicy: 'cache-first',
  });
  const oldPath = window.location.pathname;

  useEffect(() => {
    if (!ClientSession.isLoggedIn()) {
      setCallBackLink(oldPath);
    }
  }, [oldPath]);

  const history = useHistory();
  // TODO: Refactor into custom hook
  useLayoutEffect(() => {
    if (
      ClientSession.isLoggedIn() &&
      data &&
      data.signedInUser.managedCompanies.length === 0 &&
      path != buildAdminUrl('/create-company', true)
    ) {
      return history.push(buildAdminUrl('/find-company', true));
    }
  }, [data, history]);

  return ClientSession.isLoggedIn() ? (
    <Route path={path} {...rest} />
  ) : (
    <Redirect to="/login" />
  );
};

export default ProtectedRoute;
