import React, { useState } from 'react';
import { Label } from '../../../label';
import IcCalendar from '../../../../assets/icons/ic-calendar.svg';
import IcPerson from '../../../../assets/ic-person.svg';
import IcBookmark from '../../../../assets/icons/ic-bookmark.svg';
import { useHistory } from 'react-router-dom';
import { CREATE_CONVERT_TRANSACTION } from '../../../../services/transaction';
import { useMutation, ApolloError } from '@apollo/client';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { DATE_TIME_FORMAT, GENERIC_FORM_ERROR_MESSAGE } from '../../../../utils/constants';
import { ConvertTransaction } from '../../../../model/transaction';
import { ApiValidationError } from '../../../../model/common';
import { toLocaleDateTime } from '../../../../utils/formatTime';
import { useNotifications } from "../../../../hooks/notification";
import { NOTIFICATION } from '../../../../context/notificationContext';
import { useUrlBuilder } from '../../../../hooks/urlBuilder';

interface ConfirmConvertTransactionProps {
  formData: ConvertTransaction;
  onClickBack: any;
}

const ConfirmConvertTransaction = (
  props: ConfirmConvertTransactionProps,
): JSX.Element => {
  const { onClickBack } = props;
  const { amount, dateTime, fromShareClass, toShareClass, fromShareholder } = props.formData;

  const history = useHistory();
  const { buildAdminUrl, selectedCompanyId } = useUrlBuilder();

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const transactionAt = moment(dateTime, DATE_TIME_FORMAT);
  const [apiErrorMessages, setApiErrorMessages] = useState<ApiValidationError[]>([]);
  const [createConvertTransaction] = useMutation(CREATE_CONVERT_TRANSACTION);
  const { setNotification } = useNotifications();

  const onConfirm = () => {
    setSubmitting(true);
    createConvertTransaction({
      variables: {
        companyId: selectedCompanyId,
        amount,
        fromShareClass,
        toShareClass,
        fromShareholderId: fromShareholder.id,
        transactionAt
      },
    })
      .then((res) => {
        setSubmitting(false);
        setNotification({ title: 'Transaksjon lagret!', type: NOTIFICATION.SUCCESS });
        history.push(buildAdminUrl('/transactions'));
      })
      .catch((err: ApolloError) => {
        setSubmitting(false);
        const apiErrorResponse: ApiValidationError[] = err.graphQLErrors.map((error) => {
          const path = error.path;
          return {
            fieldName: path ? path[1].toString() : '',
            message: error.message
          };

        });
        setNotification({ title: GENERIC_FORM_ERROR_MESSAGE, type: NOTIFICATION.ERROR });
        setApiErrorMessages(apiErrorResponse);
        onClickBack(props.formData, apiErrorResponse);
      });
  };

  return (
    <div className="space-y-4">
      <Label label="Konvertering" presetStyle="font-bold" />
      <Label prefix="Fra: " label={fromShareholder.name} icon={IcPerson} />

      <div className="grid grid-cols-2 gap-2.5">
        <Label label={fromShareClass} icon={IcBookmark} />
        <Label label={toShareClass} icon={IcBookmark} />
      </div>

      <Label label={toLocaleDateTime(moment(dateTime, DATE_TIME_FORMAT).format())} icon={IcCalendar} />

      <Label label={amount.toString()} />

      <button
        className="btn btn-block outline-none bg-first-btn-color text-second-text-color"
        onClick={() => { onClickBack(props.formData, apiErrorMessages); }}
      >Tilbake</button>
      <button
        className="btn btn-primary btn-block outline-none bg-orange-light"
        onClick={onConfirm}
      >
        {submitting ? (
          <ReactLoading
            type="bars"
            height="30px"
            width="30px"
            color="#4B2A58"
          />
        ) : (
          <p className="text-white">Lagre endringer</p>
        )}
      </button>
      <div className="flex justify-center">
        {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ConfirmConvertTransaction;
