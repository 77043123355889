import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Role } from '../utils/constants'

export const useUrlBuilder = () => {
  const { companyId:  selectedCompanyId } = useParams<{ companyId: string }>()
  const location = useLocation()

  const getRole = ()  => {
    const path = location.pathname
    if(path.startsWith("/admin"))
      return Role.ADMIN
    else if(path.startsWith('/shareholder'))
      return Role.SHAREHOLDER
    else if(path.startsWith('/subscriber'))
      return Role.SUBSCRIBER
  }

  const buildAdminUrl = (url: string, skipCompanyId?: boolean, companyId?: string) => {
    return `/admin${skipCompanyId ? '' : `/company/${ companyId ?? selectedCompanyId}`}${url}`
  }

  const buildShareholderUrl = (url: string, skipCompanyId?: boolean, companyId?: string) => {
    return `/shareholder${skipCompanyId ? '' : `/company/${ companyId ?? selectedCompanyId}`}${url}`
  }

  const buildSubscriberUrl = (url: string, skipCompanyId?: boolean, companyId?: string) => {
    return `/subscriber${skipCompanyId ? '' : `/company/${ companyId ?? selectedCompanyId}`}${url}`
  }

  return { buildAdminUrl, buildShareholderUrl, buildSubscriberUrl, selectedCompanyId, getRole }
}
