/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { TextInput, Modal, Select } from '../../components';
import { useHistory, useParams } from 'react-router-dom';
import { NewIndividualSubscriberErrors, Shareholder, ShareholderTypes } from '../../model';
import ReactLoading from 'react-loading';
import { ADD_INDIVIDUAL_SUBSCRIBER } from '../../services/subscriber';
import { ApolloError, useMutation } from '@apollo/client';
import {
  newIndividualSubscriberErrorMessages,
  INTERNAL_SERVER_ERROR,
} from '../../utils/constants';
import { isEmailValid } from '../../utils/validators';
import { useNotifications } from '../../hooks/notification';
import { NOTIFICATION } from '../../context/notificationContext';
import { useLazyQuery } from '@apollo/client'
import { DataList } from '../../components/dataList';
import { FundingEventSubscribersVar, SEARCH_SHAREHOLDERS } from '../../services/events'
import { useUrlBuilder } from '../../hooks/urlBuilder'
import _ from 'lodash'
import { BackendTable } from '../../components';
import { ActionButton, Button } from '../../components/button';
import { GET_EVENT_SUBSCRIBERS } from '../../services/events'
import { GET_FOUNDING_EVENT_SUBSCRIBERS, SEARCH_SHAREHOLDERS_FOUNDING_EVENT } from '../../services/foundingEvents'
import { SingleFundingEventProps } from '../../model/fundingEvent'
import { SubscribersCollection, PagingMetadata, Subscriber } from '../../model'
import { paginationElements, dashboardContainer } from '../../utils/constants';
import { getFormData } from '../../utils/form';
import { Alert } from '../../components/alert';
import { DELETE_SUBSCRIBER } from '../../services/subscriber'
import AddUpdateIndividualSubscriber from '../subscriber/addUpdateIndividualSubscriber';
import IcSave from '../../assets/icons/ic-classic-save.svg'
import { SwitchButton } from '../../components/switchButon'
import { UPDATE_SUBSCRIBER } from '../../services/subscriber';
import { FundingEventAndSubscribers, FundingEventAndSubscribersVar, FoundingEventAndSubscribers } from '../../model/subscriber'
import { UserIcon } from '@heroicons/react/outline';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import IsFundingEvent from '../../hooks/isFundingEvent';

const EventSubscribers = (): JSX.Element => {
  const { selectedCompanyId } = useUrlBuilder()
  const { eventId } = useParams<{ eventId: string }>()
  const isFundingEvent = IsFundingEvent();
  const { setNotification } = useNotifications();
  const [subscribersList, setSubscribers] = useState([] as Subscriber[])
  const [shareholdersSearchResultByPhone, setShareholdersSearchResultByPhone] = useState([] as Shareholder[])
  const [shareholdersSearchResultByName, setShareholdersSearchResultByName] = useState([] as Shareholder[])
  const [shareholdersSearchResultByEmail, setShareholdersSearchResultByEmail] = useState([] as Shareholder[])
  const [shareholdersSearchedBy, setShareholdersSearchedBy] = useState('')
  const [shareholderType, setShareholderType] = useState(ShareholderTypes.PERSON.toString())

  const [selectedSubscriber, setSelectedSubscriber] = useState({} as Subscriber);

  const [pageMetaData, setPageMetaData] = useState({} as PagingMetadata);
  const [sort, setSort] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [name, setName] = useState('')
  const [email,setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [amount, setAmount] = useState('')

  const [
    deleteConfirmationModalVisibility,
    setDeleteConfirmationModalVisibility,
  ] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = [
    {
      Header: 'Navn',
      accessor: 'name',
      Cell: (props: any) => {
        return (
          <div className="flex items-center">
            {props.cell.row.original.shareholderType === ShareholderTypes.COMPANY ? (
              <OfficeBuildingIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            ) : (
              <UserIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            )}
            <span className="ml-2">{props.cell.row.original.name}</span>
          </div>
        );
      },
    },
    {
      Header: 'Epost',
      accessor: 'email',
    },
    {
      Header: 'Telefon',
      accessor: 'phone',
      Cell: (props: any) => {

        return props.cell.row.original.phone || "-"
      }
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Antall aksjer',
      accessor: 'amount',
      Cell: (props: any) => {
        return props.cell.row.original.amount ?? "-"
      }
    },
    {
      Header: 'Betalt',
      accessor: 'paid',
      Cell: (props: any) => {
        const isChecked = props.cell.row.original.paid
        return (
          <div className={`toggle-wrapper-${props.cell.row.original.id}`} onClick={() => onTogglePaid(props.data,props.cell.row.original)}>
            <div className="pointer-events-none">
              <SwitchButton isChecked={isChecked} label="" onClick={(e) => {e.stopPropagation() }} />
            </div>
          </div>
        )
      }
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: (props: any) => {
        return (
          <div className="flex">
            <Button
              label={<i className="uc-icon text-grey">&#xe972;</i>}
              presetStyle="mr-4"
              type="button-5"
              toExecute={() => {
                setSelectedSubscriber(props.cell.row.original);
                setIsModalOpen(true)
              }}
            />

            <Button
              label={<i className="uc-icon text-grey">&#xeb97;</i>}
              presetStyle="mr-4"
              type="button-5"
              toExecute={() => {
                setSelectedSubscriber(props.cell.row.original);
                setDeleteConfirmationModalVisibility(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const [updateIndividualSubscriber] = useMutation(
    UPDATE_SUBSCRIBER
  );

  const onTogglePaid = (subscribersList: Subscriber[], subscriber: Subscriber) => {
    const { id, amount, paid } = subscriber
    const paidToggle: HTMLInputElement | null = document.querySelector(`.toggle-wrapper-${id} input`)

    if(!amount) {
      // subscriber not allowed
      setNotification({
        title: 'Aksjonæren mangler antall aksjer',
        type: NOTIFICATION.ERROR,
      });
      return
    } else {
      paidToggle?.click()
    }

    // update the subscriber with paid attribute only
    updateIndividualSubscriber({
      variables: {
        attributes: {
          id: id,
          paid: !paid
        },
      },
    }).then((res) => {
      // update the subscribers table
      const { paid } = res.data.updateSubscriber

      const copy = [...subscribersList]
      const index = _.findIndex(subscribersList, { id })

      if(index !== -1) {
        copy.splice(index, 1, {...copy[index], paid })
        setSubscribers(copy)
      }

    })
      .catch((err: ApolloError) => {
        setNotification({
          title: err.message ?? INTERNAL_SERVER_ERROR,
          type: NOTIFICATION.ERROR,
        });

        // revert back the switch button
        paidToggle?.click()
      });
  }
  const [addIndividualSubscriber, { data: individualSubscriber, loading: loadingAddingSubscriber }] = useMutation(
    ADD_INDIVIDUAL_SUBSCRIBER,
  );

  const checkValidation = (name: string, email: string, amount: string) => {
    let response = true;
    const errors: NewIndividualSubscriberErrors = {
      name: '',
      phone: '',
      email: '',
      amount: ''
    };

    if (!name) {
      response = false;
      errors.name = newIndividualSubscriberErrorMessages.NAME_REQUIRED;
    }

    if (email && !isEmailValid(email)) {
      response = false;
      errors.email = newIndividualSubscriberErrorMessages.INVALID_EMAIL;
    }

    if(amount && Number(amount) < 0) {
      response = false;
      errors.amount = newIndividualSubscriberErrorMessages.INVALID_AMOUNT
    }

    setValidationsErrors(errors);
    return response;
  }

  const onUpdateSuccess = (subscriber: Subscriber) => {
    const copy = [...subscribersList]
    const oldSubscriberIndex = _.findIndex(subscribersList, {id: subscriber.id})
    copy.splice(oldSubscriberIndex, 1, subscriber)
    setSubscribers(copy)
  }

  const addSubscriber = (shareIssuanceEventId: string, name: string, email: string, shareholderType: string, phone?: string, amount?: number) => {
    addIndividualSubscriber({
      variables: {
        attributes: {
          name,
          shareIssuanceEventId,
          phone,
          email,
          amount,
          shareholderType
        },
      },
    })
      .then((res) => {
        setNotification({
          title: 'Tegningsrettighet utdelt',
          type: NOTIFICATION.SUCCESS,
        });
        // clear the form
        setEmail('')
        setName('')
        setPhone('')
        setAmount('')

        // update the subscribers list and paging data
        const { currentPage, limitValue, totalCount, totalPages } = pageMetaData
        const newSubscriber = {name, email, phone, amount, shareholderType, id: res.data.addSubscriberToEvent.id} as Subscriber

        if(sort) {
          setPageMetaData({ ...pageMetaData, skipRefetch: false });
        } else if(totalPages * limitValue <  totalCount + 1) {
          // if there is no space to add the newly added item to the last page, then create a new page and show the newly created page.
          setPageMetaData({
            ...pageMetaData,
            totalCount: totalCount + 1,
            totalPages: totalPages + 1,
            currentPage: totalPages + 1,
            skipRefetch: true,
          });
          setSubscribers([newSubscriber])
        } else {
          setPageMetaData({
            ...pageMetaData,
            totalCount: totalCount + 1,
            skipRefetch: true,
          });
          setSubscribers([...subscribersList, newSubscriber])
        }

        // make the current selected shareholder disabled in the search result
        updateShareholdersSearchResults(email, true)

        // if either of the search results are empty get shareholders to populate the search input. (same as initial load)
        if(!shareholdersSearchResultByEmail.length || !shareholdersSearchResultByName.length || !shareholdersSearchResultByPhone) {
          setShareholdersSearchedBy('')
          getShareholders({
            variables: {
              companyId: selectedCompanyId,
              search: '',
              fundingEventId: eventId
            },
          });
        }

      })
      .catch((err: ApolloError) => {
        setNotification({
          title: err.message ?? INTERNAL_SERVER_ERROR,
          type: NOTIFICATION.ERROR,
        });
      });
  }

  const updateShareholdersSearchResults = (email: string, alreadySubscribed: boolean) => {

    const indexInSearchResultByName = _.findIndex(shareholdersSearchResultByName, {email})
    const indexInSearchResultByEmail = _.findIndex(shareholdersSearchResultByEmail, {email})
    const indexInSearchResultByPhone = _.findIndex(shareholdersSearchResultByPhone, {email})

    if(indexInSearchResultByName !== -1) {
      const shareholdersCopy = [...shareholdersSearchResultByName]
      shareholdersCopy.splice(indexInSearchResultByName, 1, {...shareholdersCopy[indexInSearchResultByName], alreadySubscribed } as Shareholder)
      setShareholdersSearchResultByName(shareholdersCopy)
    }

    if(indexInSearchResultByEmail !== -1) {
      const shareholdersCopy = [...shareholdersSearchResultByEmail]
      shareholdersCopy.splice(indexInSearchResultByEmail, 1, {...shareholdersCopy[indexInSearchResultByEmail], alreadySubscribed } as Shareholder)
      setShareholdersSearchResultByEmail(shareholdersCopy)
    }

    if(indexInSearchResultByPhone !== -1) {
      const shareholdersCopy = [...shareholdersSearchResultByPhone]
      shareholdersCopy.splice(indexInSearchResultByPhone, 1, {...shareholdersCopy[indexInSearchResultByPhone], alreadySubscribed } as Shareholder)
      setShareholdersSearchResultByPhone(shareholdersCopy)
    }
  }

  const onAddSubscriber = (event: any) => {
    event.preventDefault();
    const { name, email, phone, amount } = getFormData(event.target.elements)

    if (checkValidation(name, email, amount)) {
      setValidationsErrors({} as NewIndividualSubscriberErrors)
      addSubscriber(eventId, name, email, shareholderType, phone, Number(amount))
    }
  };

  const [
    getSubscribers,
    { error: subscribersError, refetch },
  ] = useLazyQuery<any, FundingEventSubscribersVar>(
    isFundingEvent ? GET_EVENT_SUBSCRIBERS : GET_FOUNDING_EVENT_SUBSCRIBERS, {
      fetchPolicy: 'network-only',
      onCompleted: (fundingEvent) => {
        if(fundingEvent) {
          setPageMetaData(
            isFundingEvent ?
              fundingEvent?.fundingEvent.subscribers.metadata
              : fundingEvent?.foundingEvent.subscribers.metadata
          );
          setSubscribers(
            isFundingEvent ?
              fundingEvent?.fundingEvent.subscribers.collection
              :fundingEvent?.foundingEvent.subscribers.collection
          )
        }

      },
      onError: () => setSubscribers([]),
    });

  useEffect(() => {
    if (eventId && !subscribersError) {
      getSubscribers({
        variables: {
          id: eventId,
          page: pageMetaData.currentPage ?? paginationElements.INITIAL_PAGE,
          limit: pageMetaData.limitValue ?? paginationElements.LIMIT,
          sort,
          search: searchValue,
        },
      });
    }
  }, [eventId]);

  // get all shareholders once when the component is mounted
  useEffect(() => {
    getShareholders({
      variables: {
        companyId: selectedCompanyId,
        search: '',
        fundingEventId: eventId
      },
    });
  }, [])

  const [getShareholders, { loading, data, error }] = useLazyQuery<
    any,
    FundingEventAndSubscribersVar
  >(isFundingEvent ? SEARCH_SHAREHOLDERS : SEARCH_SHAREHOLDERS_FOUNDING_EVENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const subscribedShareholders = isFundingEvent ? data.fundingEvent.subscribers.collection : data.foundingEvent.subscribers.collection

      const shareholders = data.managedCompany.shareholders.collection.map((shareholder: Shareholder, idx: number) => {
        return {...shareholder, alreadySubscribed: _.find(subscribedShareholders, {email: shareholder.email}) ? true : false }
      })
      setShareholdersSearchResult(shareholders)
    },
  });

  const setShareholdersSearchResult = (shareholders: Shareholder[]) => {
    switch(shareholdersSearchedBy) {
    case 'email':
      setShareholdersSearchResultByEmail(shareholders)
      break
    case 'phone':
      setShareholdersSearchResultByPhone(shareholders)
      break
    case 'name':
      setShareholdersSearchResultByName(shareholders)
      break
    default: // set shareholders result for all search result, if searched initially with empty string.
      setShareholdersSearchResultByName(shareholders)
      setShareholdersSearchResultByEmail(shareholders)
      setShareholdersSearchResultByPhone(shareholders)
    }
  }

  const [deleteSubscriber, { error: deleteSubscriberError }] = useMutation(DELETE_SUBSCRIBER);

  const [validationErrors, setValidationsErrors] = useState(
    {} as NewIndividualSubscriberErrors,
  );

  const setInput = (setter: React.Dispatch<React.SetStateAction<string>>) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {

    setter(event.target.value);
    setSearchedBy(event.target.name)

    setValidationsErrors({...validationErrors, [event.target.name]: '' });

    getShareholders({
      variables: {
        companyId: selectedCompanyId,
        search: event.target.value,
        fundingEventId: eventId
      },
    });

  };

  const setSearchedBy = (fieldName: string) => {
    if(fieldName !== shareholdersSearchedBy)
      setShareholdersSearchedBy(fieldName)
  }

  const setSelected = (selected: string, searchResult: Shareholder[], setter: React.Dispatch<React.SetStateAction<string>>)  => {
    setter(selected)

    const shareholder = _.find(searchResult, { id: selected })

    if(shareholder) {
      setEmail(shareholder?.email);
      setName(shareholder?.name)
      setPhone(shareholder?.phone)
      console.log('=====>shareholder', shareholder)

      // call add subscriber query
      addSubscriber(eventId, shareholder.name, shareholder.email, shareholder.shareholderType, shareholder.phone)
    }

  };

  const generateShareholdersSelect = (data: any) => {

    const selectedData: { value: string; label: JSX.Element, disabled: boolean }[] = [];

    data.map((el: any, index: number) =>
      selectedData.push({
        value: el.id,
        label: (
          <div className={`flex overflow-hidden -ml-2 ${el.alreadySubscribed ? 'opacity-50 cursor-not-allowed': '' }`}>
            <div className="flex mr-2 items-center justify-center">
              {el.shareholderType === ShareholderTypes.COMPANY ? (
                <OfficeBuildingIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <UserIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </div>
            <div className="min-w-0">
              <span className={`one-line-clamp font-bold text-bold`}>{`${el.name}`}</span>
              <span className={`one-line-clamp break-words`}>{`${el.email}`}</span>
              { el.phone && <span className={`one-line-clamp`}>{`${el.phone}`}</span> }
            </div>
          </div>
        ),
        disabled: el.alreadySubscribed
      }),
    );
    return selectedData;
  };

  const removeSubscriber = (subscriber: Subscriber) => {
    deleteSubscriber({
      variables: {
        id: subscriber.id
      },
    })
      .then((res) => {
        setNotification({ title: 'Slettet', type: NOTIFICATION.SUCCESS });

        // remove from the state.
        setSubscribers(subscribersList.filter((item) => item.id !== subscriber.id));

        // make the item as clickable in the search result
        updateShareholdersSearchResults(subscriber.email, false)

        const { currentPage, limitValue, totalCount, totalPages } = pageMetaData

        if(totalPages === currentPage && totalCount - 1 <= limitValue * (currentPage -1) ) {
          setPageMetaData({
            ...pageMetaData,
            currentPage: currentPage - 1,
            totalCount: totalCount - 1,
            totalPages: totalPages - 1,
            skipRefetch: false,
          });
        }else {
          setPageMetaData({ ...pageMetaData, totalCount: totalCount - 1 });
        }

      })
      .catch((err: ApolloError) => {
        if (refetch) refetch();
        setNotification({
          title: 'Noe gikk galt, prøv igjen',
          type: NOTIFICATION.ERROR,
        });
      });
  }

  return (
    <>
      <Alert
        visible={deleteConfirmationModalVisibility}
        title={'Slett'}
        message={'Er du sikker på at du vil slette ?'}
        onCancel={() => setDeleteConfirmationModalVisibility(false)}
        okText="Slett"
        onOk={() => {
          setDeleteConfirmationModalVisibility(false);
          removeSubscriber(selectedSubscriber);
        }}
      />
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="lg"
      >
        <AddUpdateIndividualSubscriber subscriber={selectedSubscriber} onUpdateSuccess={onUpdateSuccess} closeModal={() => setIsModalOpen(false)} />
      </Modal>
      <div>
        <form onSubmit={onAddSubscriber}>
          <div className="flex flex-col xl:flex-row gap-4 ">
            <div className="flex-1 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-x-4">
              <div className="md:col-span-2 xl:col-span-1">
                <Select
                  name={'shareholderType'}
                  presetStyle="formSelect"
                  label="Aksjonærtype"
                  handleChange={(e) => {
                    setShareholderType(e.target.value)
                  }}
                >
                  <option value={ShareholderTypes.PERSON}>Person</option>
                  <option value={ShareholderTypes.COMPANY}>Organisasjon</option>
                </Select>
              </div>
              <DataList
                name="name"
                data={shareholdersSearchResultByName}
                dataGenerator={generateShareholdersSelect}
                handleSelect={ (selected: string) => { setSelected(selected, shareholdersSearchResultByName , setName) } }
                handleTextChange={setInput(setName)}
                loading={loading && shareholdersSearchedBy === 'name'}
                placeHolder="Navn"
                value={name}
                errorMessage={validationErrors.name}
                label={"Navn"}
                presetStyle="flex bg-text-input p-4 w-full border-2"
                presetStyleInput="bg-inherit flex flex-1 ml-1 focus:outline-none placeholder-default disabled:opacity-60 w-inherit"
                presetStyleOptionsWrapper="!mt-0 bg-text-input z-10"
              />

              <DataList
                name="email"
                data={shareholdersSearchResultByEmail}
                dataGenerator={generateShareholdersSelect}
                handleSelect={ (selected: string) => { setSelected(selected, shareholdersSearchResultByEmail ,setEmail) } }
                handleTextChange={setInput(setEmail)}
                loading={loading && shareholdersSearchedBy === 'email'}
                placeHolder="Epost"
                value={email}
                errorMessage={validationErrors.email}
                label={"Epost"}
                presetStyle="flex bg-text-input p-4 w-full border-2"
                presetStyleInput="bg-inherit flex flex-1 ml-1 focus:outline-none placeholder-default disabled:opacity-60 w-inherit"
                presetStyleOptionsWrapper="!mt-0 bg-text-input z-10"
              />

              <DataList
                name="phone"
                data={shareholdersSearchResultByPhone}
                dataGenerator={generateShareholdersSelect}
                handleSelect={ (selected: string) => { setSelected(selected, shareholdersSearchResultByPhone ,setPhone) } }
                handleTextChange={setInput(setPhone)}
                loading={loading && shareholdersSearchedBy === 'phone'}
                placeHolder="Telefon"
                value={phone}
                errorMessage={validationErrors.phone}
                label={"Telefon"}
                presetStyle="flex bg-text-input p-4 w-full border-2"
                presetStyleInput="bg-inherit flex flex-1 ml-1 focus:outline-none placeholder-default disabled:opacity-60 w-inherit"
                presetStyleOptionsWrapper="!mt-0 bg-text-input z-10"
              />

              <TextInput
                name="amount"
                placeholder="Antall"
                type="number"
                value={amount}
                onChange={setInput(setAmount)}
                errorMessage={validationErrors.amount}
                label="Antall"
              />

            </div>
            <div className={`md:col-span-2 xl:col-span-1 flex mt-2 xl:mt-16`}>
              <div className="hidden xl:block">
                <Button
                  label={<img className="w-4 h-4 inline text-gray" src={IcSave} />}
                  presetStyle=""
                  type="button-5"
                  buttonType="submit"
                />
              </div>

              <button
                className="xl:hidden btn btn-primary btn-block outline-none bg-orange-light" type="submit"
              >
                {loadingAddingSubscriber ? (
                  <ReactLoading
                    type="bars"
                    height={'30px'}
                    width={'30px'}
                    color="#4B2A58"
                  />
                ) : (
                  <p className="text-white">Legg til tegningsrettig investor</p>
                )}
              </button>
              <button id="reset-btn" type="reset" className="hidden" />
            </div>
          </div>
        </form>

        <div className="mb-4 mt-4 xl:mt-0">
          <span className="font-hind font-bold text-2xl">Tegningsliste</span>
        </div>
        <div className="overflow-auto">
          <BackendTable
            columns={columns}
            data={subscribersList}
            searchable
            paginate
            tableHeader
            onTableChange={() =>
              getSubscribers({
                variables: {
                  id: eventId,
                  page: pageMetaData.currentPage,
                  limit: pageMetaData.limitValue,
                  sort,
                  search: searchValue,
                },
              })
            }
            setPageMetaData={setPageMetaData}
            pageMetaData={pageMetaData}
            setSortValue={setSort}
            sortValue={sort}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
      </div>
    </>
  );
};

export default EventSubscribers;
