import React from 'react'

interface SnakeRadialBarInterface {
  barPercentage: number
  barRadius: number
  barWidth: number
  style: string
}

const SnakeRadialBar = (props: SnakeRadialBarInterface) => {
  const { barPercentage, barRadius, barWidth, style } = props
  const headRadius = barWidth / 2
  const strokeDasharray = barRadius * 565.48 / 90

  const eyeRadius= barWidth / 10 + 0.5
  const eyeSpacing= eyeRadius -1

  const angle = barPercentage * 360 /100
  const radian = angle * Math.PI / 180

  // calculate eye position
  const eye1CX = 100 - Math.sin(radian) * (barRadius + headRadius/ eyeSpacing)
  const eye1CY = 100 + Math.cos(radian) * (barRadius + headRadius/ eyeSpacing)

  const eye2CX = 100 - Math.sin(radian) * (barRadius - headRadius/ eyeSpacing)
  const eye2CY = 100 + Math.cos(radian) * (barRadius - headRadius/ eyeSpacing)

  // calculate stroke dash offset
  const area = Math.PI*(barRadius*2);
  let  strokeDashoffset

  if(barPercentage < 0) {
    strokeDashoffset = 0
  }else if ( barPercentage > 100) {
    strokeDashoffset = 100
  }else {
    strokeDashoffset =  ((100-barPercentage)/100)* area;
  }
  // recalculate bar head position
  const barHeadCX = 100 - barRadius * Math.sin(radian)
  const barHeadCY = 100 + barRadius * Math.cos(radian)
  return (
    <div id="snake-wrapper" className={`${style}`}>
      <svg id="svg" width="200" height="200"  version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle
          className="body"
          r={barRadius}
          cx="100" cy="100"
          strokeDasharray={`${strokeDasharray}`}
          strokeWidth={barWidth}
          style={{ strokeDashoffset: `${strokeDashoffset}`}}
          transform="rotate(90 100 100)"/>
        <circle className="head" cx={`${barHeadCX}`} cy={`${barHeadCY}`} r={headRadius} style={{ strokeWidth: 0}} />
        <circle className="eye" cx={`${eye1CX }`} cy={`${eye1CY}`} r={eyeRadius}  style={{ strokeWidth: 0}} />
        <circle className="eye" cx={`${ eye2CX}`} cy={`${eye2CY}`} r={eyeRadius} style={{ strokeWidth: 0}}  />

      </svg>
    </div>
  )
}

export default SnakeRadialBar
