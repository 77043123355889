import { gql } from '@apollo/client';

export const USER_SIGN_UP = gql`
  mutation UserSignUp(
    $name: String!,
    $email: String!,
    $password: String!,
    $passwordConfirmation: String!,
    $confirmSuccessUrl: String
  ) {
    userSignUp(
      name: $name,
      email: $email,
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      confirmSuccessUrl: $confirmSuccessUrl
    ) {
      authenticatable {
        id
      }
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
  }
`;

export const USER_LOGIN = gql`
  mutation UserLogin(
    $email: String!,
    $password: String!
  ) {
    userLogin(
      email: $email,
      password: $password
    ) {
      authenticatable {
        id
        managedCompanies{id}
      }
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
  }
`;

export const USER_UPDATE_PASSWORD = gql`
  mutation UserUpdatePassword(
    $password: String!,
    $passwordConfirmation: String!,
    $currentPassword: String,
  ) {
    userUpdatePassword(
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      currentPassword: $currentPassword
    )
  }
`;

export const USER_UPDATE_PASSWORD_WITH_TOKEN = gql`
  mutation UserUpdatePasswordWithToken(
    $password: String!,
    $passwordConfirmation: String!,
    $resetPasswordToken: String,
  ) {
    userUpdatePassword(
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      resetPasswordToken: $resetPasswordToken
    )
  }
`;

export const USER_CONFIRM_ACCOUNT = gql`
query userConfirmAccount($confirmationToken: String!) {
  userConfirmAccount(confirmationToken: $confirmationToken)
}
`;

export const GET_SIGNED_IN_USER = gql`
  query getSignedInUser {
    signedInUser {
      id
      email
      name
      managedCompanies{
        id
        name
        color
        registrationDate
        admins {
          id
        }
        isEmpty
        canInitialize
      }
      companies{
        id
        name
        color
        registrationDate
      }
      shareholders {
        company {
          id
        }
      }
    }
  }
`;

export const UPDATE_SIGNED_IN_USER = gql`
  mutation editCurrentUser($name: String!) {
    editCurrentUser(name: $name){
      id
      name
      email
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logoutUser {
    userLogout {
      authenticatable {
        id
      }
    }
  }
`;

export const CHECK_ACCESS = gql`
  query checkAccess($paths: [String!]!) {
    checkAccess(paths: $paths)
  }
`;

export const GET_ACCESS_CHECK_STATUS = gql`
query getAccessCheckStatus {
    isCheckingAccessCompleted @client
}
`;

export const GET_LOADING_STATUS = gql`
query getLoadingStatus {
    isLoading @client
}
`;

export const GET_SHOW_SETUP_COMPANY = gql`
    query getShowSetupCompany {
        showSetupCompany @client
    }
`;
