import React from 'react';
import GlobalFilter from './filter';
import CarretArrow from '../../assets/icons/carret.svg';
import { HeaderGroup, Row } from 'react-table';

interface TableHeaderProps {
  headerGroups: HeaderGroup<Record<string, unknown>>[];
  searchable?: boolean;
  preGlobalFilteredRows: Row<Record<string, unknown>>[];
  globalFilter: any;
  setGlobalFilter: (filterValue: unknown) => void;
  filterValue: any;
  setFilterValue: React.Dispatch<any>;
}

const TableHeader = ({
  headerGroups,
  searchable,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  filterValue,
  setFilterValue,
}: TableHeaderProps): JSX.Element => {
  return (
    <thead className="text-second-text-color font-hind text-left rounded-2xl w-full">
      {headerGroups.map((headerGroup, trIndex) => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          key={trIndex}
          className="rounded-2xl"
        >
          {headerGroup.headers.map((column, thIndex) => (
            <th
              {...(column.id !== 'search' && {
                ...column.getHeaderProps(column.getSortByToggleProps()),
              })}
              className={`p-4 first:rounded-l-2xl last:rounded-r-2xl cursor-pointer group bg-seven-color ${column.id}-header-column`}
              key={thIndex}
            >
              {searchable && column.id === 'search' && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  presetStyle="group-hover:opacity-100 filter-txt-box rounded-br-2xl rounded-bl-2xl"
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                />
              )}
              {column.render('Header')}

              {column.id !== 'search' && (
                <img
                  src={CarretArrow}
                  className={`group-hover:opacity-100 ml-4 inline transition duration-700 ${
                    column.isSortedDesc && 'transform rotate-180'
                  } ${!column.isSorted && 'opacity-0'}`}
                />
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
