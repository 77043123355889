import React from 'react';

interface TextAreaProps {
  errorMessage?: string;
  onChange?: any;
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  rows: number;
}

const TextArea = (props: TextAreaProps) => {
  const {
    id,
    name,
    label,
    placeholder,
    defaultValue,
    errorMessage,
    disabled,
    value,
    rows,
    onChange,
  } = props;

  return (
    <div className="mt-8 relative">
      <div className={`pl-4 mb-1 font-bold ${label ? 'block' : 'hidden'}`}>
        {label}
      </div>
      <div
        key={`${defaultValue}${name}`}
        className={`flex rounded-xl bg-text-input p-4 w-full  ${
          errorMessage ? 'border-primary-red border-2' : null
        }`}
      >
        <textarea
          rows={rows}
          className="bg-text-input flex flex-1 ml-1 focus:outline-none placeholder-default disabled:opacity-60 w-inherit"
          id={id}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          value={value}
          onChange={onChange}
        />
      </div>
      <div className="text-right pr-4 pt-2 form-group">
        {errorMessage && <p className="form-error">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default TextArea;
