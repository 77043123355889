import React from 'react'

interface RadioButtonInterface {
  id?: string
  name: string
  label?: string
  onChange?: any
  checked?: boolean
}
const RadioButton = (props: RadioButtonInterface) => {
  const { id, name, onChange, label, checked } = props

  return (
    <div>
      <input type="radio" id={id}  onChange={onChange} name={name} checked={checked}  />
      <label className="ml-2" htmlFor={id} >{label}</label>
    </div>
  )
}

export default RadioButton
