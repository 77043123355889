import React, { useEffect, useState } from 'react';
import PageHead from '../pageHead/pageHead';
import { LoadingPage } from './../../views/loadingPage';
import { useRouteCheck } from '../../hooks/routeCheck';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import {
  GET_LOADING_STATUS,
  GET_ACCESS_CHECK_STATUS,
} from '../../services/auth';
import {
  setIsCheckingAccessCompleted,
  setGraphQLQueryError,
  setPreviousPath,
} from '../../store/cache';
import { Error404, Error500 } from '../../views/errorPages';
import { GET_GRAPHQL_QUERY_ERROR } from '../../services/networkError';
import { GraphqlQueryError } from '../../model/common';

const Wrapper = (props: any) => {
  const location = useLocation();
  setPreviousPath(location.pathname);
  const [error, setError] = useState(false);

  const { data: loading } = useQuery(GET_LOADING_STATUS);
  const { data: accessCheck } = useQuery(GET_ACCESS_CHECK_STATUS);
  const { data: graphQLQueryError } = useQuery(GET_GRAPHQL_QUERY_ERROR);

  const { checkCurrentRoute } = useRouteCheck((checkAccess) => {
    const currentUrl = location.pathname;

    if (!(checkAccess.checkAccess as any)[currentUrl]) {
      setGraphQLQueryError({
        status: 404,
        message: 'Not Found',
      });
    } else {
      setIsCheckingAccessCompleted(true);

      if(graphQLQueryError.graphQLQueryError.status)
        setGraphQLQueryError({} as GraphqlQueryError)
    }
  });

  useEffect(() => {
    checkCurrentRoute();
  }, [location.pathname]);

  const mountComponents = () => {
    if (graphQLQueryError?.graphQLQueryError?.status === 404) return <Error404 />;
    if (graphQLQueryError?.graphQLQueryError?.status === 500) return <Error500 />;

    if (!accessCheck.isCheckingAccessCompleted) {
      return <LoadingPage />;
    }

    return (
      <>
        <div className={`h-full ${loading.isLoading ? 'block' : 'hidden'}`}>
          <LoadingPage />
        </div>
        <div className={loading.isLoading ? 'hidden' : 'block'}>
          {props.layout == null ? (
            <props.component />
          ) : (
            <props.layout {...props} />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <PageHead
        title={props.pageTitle}
        description={props.pageDescription}
        noFollow={props.private}
      />
      {props.private ? (
        mountComponents()
      ) : props.layout == null ? (
        <props.component />
      ) : (
        <props.layout {...props} />
      )}
    </>
  );
};

export default Wrapper;
