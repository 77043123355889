import React from 'react';
import { Message } from '../../../components/message';
import { useHistory } from 'react-router';
import NetworkIcon from '../../../assets/error.svg';

const Error500 = (): JSX.Element => {
  const history = useHistory();
  return (
    <div className="flex h-full items-center">
      <Message
        title="Intern server feil"
        description="Vi driver med vedlikehold. Prøv igjen senere"
        toExecute={() => history.push('/')}
        icon={<img src={NetworkIcon} />}
        btnLabel="Til forsiden"
      />
    </div>
  );
};

export default Error500;
