import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { BackendTable, Button } from '../../components';
import { dashboardContainer, paginationElements } from '../../utils/constants';
import {
  ManagedCompany,
  TransactionVar,
  TransactionsCollection,
  Transaction,
  PagingMetadata,
  SubscribedCompany,
  OwnedCompany,
} from '../../model';
import { GET_MANAGED_COMPANY_TRANSACTIONS, GET_OWNED_COMPANY_TRANSACTIONS, GET_SELECTED_COMPANY, GET_SUBSCRIBED_COMPANY_TRANSACTIONS, IMPORT_COMBINED_DATA } from '../../services/company';
import { addRowNum } from '../../utils/formatData';
import { toLocaleDateTime } from '../../utils/formatTime';
import { useUrlBuilder } from '../../hooks/urlBuilder'
import { client } from '../../services/apollo-client';
import { Role } from '../../utils/constants'

const TransactionsList = (): JSX.Element => {
  const [transactions, setTransactions] = useState([]);
  const { selectedCompanyId, getRole } = useUrlBuilder()

  const [pageMetaData, setPageMetaData] = useState({} as PagingMetadata);
  const [sort, setSort] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [getManagedCompanyTransactions, { error: managedCompanyTransactionsError, loading: isManagedTransactionLoading }] = useLazyQuery<
    ManagedCompany,
    TransactionVar
  >(GET_MANAGED_COMPANY_TRANSACTIONS, {
    onCompleted: (result) => {
      setPageMetaData(result.managedCompany.transactions.metadata);
      setTransactions(
        addRowNum(
          result.managedCompany.transactions.collection.map((obj) => ({
            ...obj,
            'fromShareholder.name': obj.fromShareholder?.name ?? '',
            'toShareholder.name': obj.toShareholder?.name ?? '',
            transactionAt: toLocaleDateTime(obj.transactionAt),
          })),
        ),
      );
    },
    onError: () => setTransactions([]),
  });

  const [getOwnedCompanyTransactions, { error: ownedCompanyTransactionsError, loading: isOwnedTransactionLoading }] = useLazyQuery<
    OwnedCompany,
    TransactionVar
  >(GET_OWNED_COMPANY_TRANSACTIONS, {
    onCompleted: (result) => {
      setPageMetaData(result.ownedCompany.transactions.metadata);
      setTransactions(
        addRowNum(
          result.ownedCompany.transactions.collection.map((obj) => ({
            ...obj,
            'fromShareholder.name': obj.fromShareholder?.name ?? '',
            'toShareholder.name': obj.toShareholder?.name ?? '',
            transactionAt: toLocaleDateTime(obj.transactionAt),
          })),
        ),
      );
    },
    onError: () => setTransactions([]),
  });

  const [getSubscribedCompanyTransactions, { error: subscribedCompanyTransactionsError, loading: isSubscribedTransactionLoading }] = useLazyQuery<
    SubscribedCompany,
    TransactionVar
  >(GET_SUBSCRIBED_COMPANY_TRANSACTIONS, {
    onCompleted: (result) => {
      setPageMetaData(result.subscribedCompany.transactions.metadata);
      setTransactions(
        addRowNum(
          result.subscribedCompany.transactions.collection.map((obj) => ({
            ...obj,
            'fromShareholder.name': obj.fromShareholder?.name ?? '',
            'toShareholder.name': obj.toShareholder?.name ?? '',
            transactionAt: toLocaleDateTime(obj.transactionAt),
          })),
        ),
      );
    },
    onError: () => setTransactions([]),
  });

  const getTransactions = () => {
    const role = getRole()

    switch (role) {
    case Role.ADMIN:
      getManagedCompanyTransactions({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage,
          limit: pageMetaData.limitValue,
          sort,
          search: searchValue,
        },
      });
      break
    case Role.SUBSCRIBER:
      getSubscribedCompanyTransactions({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage,
          limit: pageMetaData.limitValue,
          sort,
          search: searchValue,
        },
      });
      break
    case Role.SHAREHOLDER:
      getOwnedCompanyTransactions({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage,
          limit: pageMetaData.limitValue,
          sort,
          search: searchValue,
        },
      });
      break
    }
  }

  useEffect(() => {
    setPageMetaData(
      {
        ...pageMetaData,
        currentPage:paginationElements.INITIAL_PAGE,
        limitValue: paginationElements.LIMIT
      }
    )
    if (selectedCompanyId && !managedCompanyTransactionsError && !ownedCompanyTransactionsError && !subscribedCompanyTransactionsError) {
      getTransactions()
    }
  }, [selectedCompanyId]);

  const columns = [
    {
      Header: 'Dato',
      accessor: 'transactionAt',
      isExported: true,
    },
    {
      Header: 'Fra',
      accessor: 'fromShareholder.name',
      isExported: true,
    },
    {
      Header: 'Til',
      accessor: 'toShareholder.name',
      isExported: true,
    },
    {
      Header: 'Antall',
      accessor: 'amount',
      isExported: true,
    },
    {
      Header: 'Pris per aksje',
      accessor: 'pricePerShare',
      isExported: true,
    },
    {
      Header: 'Type',
      accessor: 'transactionType',
      disableSortBy: true,
      isExported: true,
    },
  ];

  const inputFile = useRef({} as HTMLInputElement);
  const buttonDiv = useRef({} as HTMLDivElement);
  const uploadingDiv = useRef({} as HTMLDivElement);

  const [uploadFileMutation] = useMutation(IMPORT_COMBINED_DATA);

  const onChange =
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if(e.target.files == null || e.target.files.length == 0) {
        return;
      }

      buttonDiv.current.className = "hidden"
      uploadingDiv.current.className = ""

      uploadFileMutation({
        variables: {
          file: e.target.files[0],
          companyId: selectedCompanyId
        }
      }).then(() => {
        client.resetStore();
        inputFile.current.value = ''
        getTransactions()

        buttonDiv.current.className = ""
        uploadingDiv.current.className = "hidden"
      });
    }

  return (
    <div className={dashboardContainer}>
      <div className="mb-4 py-4 pl-4 flex justify-between">
        <span className="font-hind font-bold text-5xl">Transaksjoner</span>
        <div>
          <input
            type="file"
            className="hidden"
            ref={inputFile}
            onChange={onChange}
          />
          <div ref={buttonDiv}>
            <Button label="Upload" toExecute={
              () => {
                inputFile.current.click();
              }
            }></Button>
          </div>
          <div className="hidden" ref={uploadingDiv}>
            <p>Uploading...</p>
          </div>
        </div>
      </div>
      <BackendTable
        columns={columns}
        data={transactions}
        searchable
        paginate
        tableHeader
        onTableChange={() =>
          getTransactions()
        }
        setPageMetaData={setPageMetaData}
        pageMetaData={pageMetaData}
        setSortValue={setSort}
        sortValue={sort}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isLoading={isManagedTransactionLoading || isOwnedTransactionLoading || isSubscribedTransactionLoading}
        fileName="transactions"
      />
    </div>
  );
};

export default TransactionsList;
