import React from 'react';
import { useState, useLayoutEffect } from 'react';
import './Register.scss';
import { SignUpForm } from '../../../components/Auth/SignUp';
import { Confirm } from '../../../components/Auth/Confirm';
import ImgInvestingBook from '../../../assets/images/investingbooks.png';
import { primaryContainer } from '../../../utils/constants';
import { FindCompanyForm } from '../../../components/Auth/company';

const RegisterPage = (): JSX.Element => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');

  const goNext = (email: string) => {
    setEmail(email);
    setStep(step + 1);
  };

  useLayoutEffect(() => {
    if (window.location.pathname === '/admin/find-company') setStep(3);
  }, []);

  return (
    <section className="h-full">
      <div className="bg-eighth-color h-full">
        <div className={`${primaryContainer} py-36`}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="hidden lg:flex justify-center items-center">
              <img
                src={ImgInvestingBook}
                className="w-60 lg:w-96 mt-8 lg:mt-0"
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <>
                <div className="stepper justify-around flex mb-12">
                  <div
                    className={`text-center step flex items-center md:mr-4 flex-col xl:flex-row ${step > 0 ? 'active' : ''
                    }`}
                  >
                    <div className="circle">1</div>
                    <span className="font-roboto">Registrer din konto</span>
                  </div>
                  <div
                    className={`text-center step flex items-center md:mr-4 flex-col xl:flex-row ${step > 1 ? 'active' : ''
                    }`}
                  >
                    <div className="circle">2</div>
                    <span className="font-roboto">Bekreftelse</span>
                  </div>
                  <div
                    className={`text-center step flex items-center md:mr-4 flex-col xl:flex-row ${step > 2 ? 'active' : ''
                    }`}
                  >
                    <div className="circle">3</div>
                    <span className="font-roboto">Legg til ditt selskap</span>
                  </div>
                </div>
                <div className="mt-auto px-5">
                  {step === 1 ? (
                    <SignUpForm
                      className="register-form mx-auto"
                      onSubmit={goNext}
                    />
                  ) : step === 2 ? (
                    <Confirm email={email} />
                  ) : (
                    <FindCompanyForm />
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterPage;
