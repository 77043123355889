import React, { useState, useEffect } from 'react';
import IcMoney from '../../../../assets/menuIcons/ic-money.svg';
import { TextInput } from '../../../textInput'
import IcCalendar from '../../../../assets/icons/ic-calendar.svg';
import { getFormData } from '../../../../utils/form';
import { CreateSplitTransactionErrors, SplitTransaction } from '../../../../model/transaction'
import { ApiValidationError } from '../../../../model/common'
import { useHistory } from 'react-router';
import { createTransactionErrorMessages, TIME_FORMAT, transactionTypes } from '../../../../utils/constants';
import { DateTimePicker } from '../../../dateTimePicker'
import moment from 'moment'
import { DATE_TIME_FORMAT } from '../../../../utils/constants'
import { useUrlBuilder } from '../../../../hooks/urlBuilder'

interface SplitTransactionFormInterface {
  formData: SplitTransaction
  apiErrorsMessages: ApiValidationError[]
}

const SplitTransactionForm = (props: SplitTransactionFormInterface): JSX.Element => {
  const history = useHistory()
  const { buildAdminUrl } = useUrlBuilder()
  const [errorMessage, setErrorMessage] = useState('')
  const [validationError, setValidationError] = useState({} as CreateSplitTransactionErrors)
  const [selectedDateTime, setSelectedDateTime] = useState(moment().format(DATE_TIME_FORMAT))

  const { amount } = props.formData

  useEffect(() => {
    if(props.formData.dateTime && selectedDateTime !== props.formData.dateTime) {
      setSelectedDateTime(props.formData.dateTime)
    }

    const apiErrorMessages = props.apiErrorsMessages
    const apiValidationErrors: CreateSplitTransactionErrors = validationError

    if(apiErrorMessages) {
      apiErrorMessages.forEach((error: ApiValidationError) => {
        (apiValidationErrors as any )[error.fieldName] = error.message
      })
      setValidationError({...apiValidationErrors})
    }
  }, [])

  const onSubmitForm = (event: any) => {
    event.preventDefault()
    const formData = getFormData(event.target.elements)
    const { date, time, amount } = formData
    formData['amount'] = Number(amount)
    formData['transactionType'] = transactionTypes[1]
    formData['dateTime'] = selectedDateTime

    if (checkValidation(amount, selectedDateTime)) {
      setErrorMessage('');
      history.push({
        pathname: buildAdminUrl('/confirm-transaction'),
        state: { formData }
      })
    }
  }

  const checkValidation = (amount: number, dateTime: string) => {
    let response = true
    const errors: CreateSplitTransactionErrors = {
      amount: '',
      dateTime: ''
    };

    if (!amount) {
      response = false
      errors.amount = createTransactionErrorMessages.SHARE_AMOUNT_REQUIRED
    } else if (amount <= 0) {
      response = false
      errors.amount = createTransactionErrorMessages.SHARE_AMOUNT_GREATER_THAN_ZERO
    }

    if (!dateTime) {
      response = false
      errors.dateTime = createTransactionErrorMessages.DATE_AND_TIME_REQUIRED
    }

    setValidationError(errors);
    return response;
  }

  return (
    <form onSubmit={onSubmitForm}>
      <TextInput
        name="amount"
        placeholder="Antall"
        type="number"
        errorMessage={validationError.amount}
        defaultValue={amount?.toString()}
        icon={IcMoney} />

      <DateTimePicker
        presetStyle="flex rounded-full bg-text-input p-4 w-full"
        options={{enableTime: true}}
        icon={IcCalendar}
        defaultValue={moment(selectedDateTime, DATE_TIME_FORMAT)}
        onChange={ (date: any) => setSelectedDateTime( moment(date[0]).format(DATE_TIME_FORMAT) ) }
        errorMessage={validationError.dateTime}
      />
      <div className="form-group justify-center mt-8">
        {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
        <button
          className="btn btn-primary btn-block outline-none bg-orange-light"
          type="submit"
        >
          <p className="text-white">Lagre endringer</p>
        </button>
      </div>
    </form>
  );
};

export default SplitTransactionForm;
