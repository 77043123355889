import React, { useEffect, useState } from 'react';
import Logo from '../../../assets/logo.svg';
import { homeMenuItems } from '../../../utils/constants';
import { Button } from '../../button';
import HamIllustration from '../../../assets/ham-menu.svg';
import MobileNav from './mobileNav';
import { primaryContainer } from '../../../utils/constants';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SIGNED_IN_USER } from '../../../services/auth';
import { SignedInUser } from '../../../model/user';
import { useRouteCheck } from '../../../hooks/routeCheck'
import { useUrlBuilder } from '../../../hooks/urlBuilder'

const HomeNavComponent = (): JSX.Element => {
  const { buildAdminUrl } = useUrlBuilder()

  const { checkAccess } = useRouteCheck(
    (checkAccess) => {
      // Redirect to the first allowed path.
      const firstAllowedPath = Object.keys(checkAccess.checkAccess).find((key) => checkAccess.checkAccess[key] === true )
      firstAllowedPath && history.push(firstAllowedPath)

      if(openMobileNav)
        handleMenu()
    }
  )

  const { data } = useQuery<SignedInUser>(GET_SIGNED_IN_USER, {
    fetchPolicy: 'cache-first',
  });

  const history = useHistory();
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const handleMenu = (): void => {
    const bodyContent = document.querySelector('body');
    if (openMobileNav) {
      bodyContent?.classList.remove('lock-section');
    } else {
      bodyContent?.classList.add('lock-section');
    }
    setOpenMobileNav(!openMobileNav);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (): void => {
    const nav = document.querySelector('#inv-nav');
    if (nav) {
      if (window.scrollY > 20) {
        nav.classList.add('bg-second-color');
      } else {
        nav.classList.remove('bg-second-color');
      }
    }
  };

  const onClickLogin = () => {
    // Because selected company is not set before the user sign in,
    // we have to redirect to dashboard upon successful login
    // with the first company in the companies list as the selected one.

    if (data?.signedInUser) {
      const selectedCompany = data.signedInUser.companies[0]
      if(selectedCompany)
        checkAccess(selectedCompany.id, true)
      else
        history.push(buildAdminUrl('/find-company', true))
    } else {
      history.push('/login')
    }
  }

  return (
    <div
      id="inv-nav"
      className="w-full z-20 bg-transparent fixed transition duration-1000"
    >
      <div
        className={`${primaryContainer} py-5 flex justify-between items-center`}
      >
        <a href={process.env.REACT_APP_INVESTORBOK_HOMEPAGE_URL}>
          <img
            className="w-36 cursor-pointer"
            src={Logo}
            alt="inverstorbok"
          />
        </a>
        <div
          id="open-btn"
          onClick={handleMenu}
          className={`block lg:hidden xl:hidden ${!homeMenuItems.length ? 'hidden': ''}`}
        >
          <img src={HamIllustration} alt="menu" />
        </div>
      </div>
      {openMobileNav && (
        <MobileNav
          openMobileNav={openMobileNav}
          closeMobileNav={handleMenu}
          onClickLogin={onClickLogin}
        />
      )}
    </div>
  );
};

export default HomeNavComponent;
