import { RegisterPage } from './views/Auth/Register';
import { LoginPage } from './views/Auth/Login';
import { CompanyDetails } from './views/Auth';
import { ConfirmPage } from './views/Auth/Confirm';
import DefaultLayout from './layouts/default/defaultLayout';
import DashboardLayout from './layouts/dashboard/dashboardLayout';
import { Dashboard } from './views/dashboard';
import { PersonalAccountSettings } from './views/personalAccountSettings';
import { CompanySettings } from './views/companySettings';
import { CreateTransaction } from './views/createTransaction';
import { ConfirmTransaction } from './views/confirmTransaction';
import { BoardMeetingAgendasPage } from './views/boardMeetingAgendas';
import FundingEvent from './views/fundingEvent/fundingEvent';
import {
  Shareholders,
  TransactionsList,
  FundingEvents,
  SingleFundingEvent,
  NewBoardMeeting,
  BoardMeetingAgendaVoting,
  EventSubscribers,
  FoundingEvents,
  CreateFoundingEvent
} from './containers';
import { Role, boardMeetingEditRoute } from './utils/constants';
import { Error404 } from './views/errorPages';
import { SubscriberInvitation } from './views/subscriberInvitation';

const publicRoutes = [
  {
    path: '/',
    exact: true,
    private: false,
    component: LoginPage,
    layout: DefaultLayout,
  },
  {
    path: '/login',
    exact: true,
    private: false,
    component: LoginPage,
    layout: DefaultLayout,
  },
  {
    path: '/signup',
    exact: true,
    private: false,
    component: RegisterPage,
    layout: DefaultLayout,
  },
]

const adminRoutes = [
  {
    path: '/admin/find-company',
    exact: true,
    private: true,
    component: RegisterPage,
    layout: DefaultLayout,
  },
  {
    path: '/admin/create-company',
    exact: true,
    private: true,
    component: CompanyDetails,
    layout: DefaultLayout,
  },
  {
    path: '/admin/confirm/:token',
    exact: true,
    private: false,
    component: ConfirmPage,
    layout: DefaultLayout,
  },
  {
    path: '/admin/company/:companyId/dashboard',
    exact: true,
    private: true,
    component: Dashboard, // TODO change the dashboard component
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/company-settings',
    exact: true,
    private: true,
    component: CompanySettings,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/shareholders',
    exact: true,
    private: true,
    component: Shareholders,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/transactions',
    exact: true,
    private: true,
    component: TransactionsList,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/create-transaction',
    exact: true,
    private: true,
    component: CreateTransaction,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/confirm-transaction',
    exact: true,
    private: true,
    component: ConfirmTransaction,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/funding-events/:eventId/board-meetings/new',
    exact: true,
    private: true,
    component: NewBoardMeeting,
    layout: DashboardLayout
  },
  {
    path: '/admin/company/:companyId/founding-events/:eventId/board-meetings/new',
    exact: true,
    private: true,
    component: NewBoardMeeting,
    layout: DashboardLayout
  },
  {
    path: boardMeetingEditRoute,
    exact: true,
    private: true,
    component: NewBoardMeeting,
    layout: DashboardLayout
  },
  {
    path: '/admin/company/:companyId/board-meetings/:id/agendas',
    exact: true,
    private: true,
    component: BoardMeetingAgendasPage,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/funding-events/:id/edit',
    exact: false,
    private: true,
    component: FundingEvent,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/founding-events/:id/edit',
    exact: false,
    private: true,
    component: CreateFoundingEvent,
    layout: DashboardLayout,
  },
  { path: '/admin/company/:companyId/funding-events',
    exact: true,
    private: true,
    component: FundingEvents,
    layout: DashboardLayout,
  },
  { path: '/admin/company/:companyId/founding-events',
    exact: true,
    private: true,
    component: FoundingEvents,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/funding-events/new',
    exact: false,
    private: true,
    component: FundingEvent,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/founding-events/new',
    exact: false,
    private: true,
    component: CreateFoundingEvent,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/funding-events/:eventId',
    exact: true,
    private: true,
    component: SingleFundingEvent,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/founding-events/:eventId',
    exact: true,
    private: true,
    component: SingleFundingEvent,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/funding-events/:eventId/subscribers',
    exact: true,
    private: true,
    component: EventSubscribers,
    layout: DashboardLayout,
  },
  {
    path: '/admin/company/:companyId/founding-events/:eventId/subscribers',
    exact: true,
    private: true,
    component: EventSubscribers,
    layout: DashboardLayout,
  }
]

const shareholderRoutes = [
  {
    path: '/shareholder/company/:companyId/dashboard',
    exact: true,
    private: true,
    component: Dashboard, // TODO change the dashboard component
    layout: DashboardLayout,
  },
  {
    path: '/shareholder/company/:companyId/shareholders',
    exact: true,
    private: true,
    component: Shareholders,
    layout: DashboardLayout,
  },
  {
    path: '/shareholder/company/:companyId/transactions',
    exact: true,
    private: true,
    component: TransactionsList,
    layout: DashboardLayout,
  },
  {
    path: '/shareholder/company/:companyId/board-meetings/:id/agendas',
    exact: true,
    private: true,
    component: BoardMeetingAgendasPage,
    layout: DashboardLayout,
  },
  { path: '/shareholder/company/:companyId/funding-events',
    exact: true,
    private: true,
    component: FundingEvents,
    layout: DashboardLayout,
  },
  { path: '/shareholder/company/:companyId/founding-events',
    exact: true,
    private: true,
    component: FoundingEvents,
    layout: DashboardLayout,
  },
  {
    path: '/shareholder/company/:companyId/funding-events/:eventId',
    exact: true,
    private: true,
    component: SingleFundingEvent,
    layout: DashboardLayout,
  },
  {
    path: '/shareholder/company/:companyId/funding-events/board-meetings/:eventId/agendas-voting',
    exact: true,
    private: true,
    component: BoardMeetingAgendaVoting,
    layout: DashboardLayout,
  },
  {
    path: '/shareholder/company/:companyId/founding-events/board-meetings/:eventId/agendas-voting',
    exact: true,
    private: true,
    component: BoardMeetingAgendaVoting,
    layout: DashboardLayout,
  }
]

const subscriberRoutes = [
  {
    path: '/subscriber/company/:companyId/funding-events/',
    exact: true,
    private: true,
    component: FundingEvents,
    layout: DashboardLayout,
  },
  {
    path: '/subscriber/company/:companyId/founding-events/',
    exact: true,
    private: true,
    component: FoundingEvents,
    layout: DashboardLayout,
  },
  {
    path: '/subscriber/company/:companyId/funding-events/:eventId',
    exact: true,
    private: true,
    component: SingleFundingEvent,
    layout: DashboardLayout,
  }
]

const withoutRole = [
  {
    path: '/settings',
    exact: true,
    private: false,
    component: PersonalAccountSettings,
    layout: DashboardLayout,
  },
  {
    path: '/subscriber/company/:company_id/invitation/:subscriber_id',
    exact: true,
    private: true,
    component: SubscriberInvitation,
    layout: DefaultLayout,
  },
  {
    path: '*',
    exact: false,
    private: false,
    component: Error404
  },
]

export const routes = [...adminRoutes, ...shareholderRoutes, ...subscriberRoutes, ...publicRoutes, ...withoutRole]

export default routes;
