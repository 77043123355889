import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import useQuery from '../../hooks/searchParam';

interface Tabs {
  tabs: Array<singleTab>;
  children: any;
}

type singleTab = {
  name: string;
  href: string;
  identifier: string;
};

const Tabs = ({ tabs, children }: Tabs): JSX.Element => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(tabs[0].identifier);

  const queryTab = useQuery().get('tab');

  useEffect(() => {
    queryTab
      ? setSelectedTab(queryTab)
      : history.replace({ search: `tab=${tabs[0].identifier}` });
  }, [history, queryTab, tabs]);

  return (
    <div>
      <div id="tab-header">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <span
                key={tab.name}
                className={classNames(
                  tab.identifier === selectedTab
                    ? 'border-seven-color text-seven font-bold'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 text-base cursor-pointer font-hind',
                )}
                aria-current={
                  tab.identifier === selectedTab ? 'page' : undefined
                }
                onClick={() => {
                  setSelectedTab(tab.identifier);
                  history.push({ search: `tab=${tab.identifier}` });
                }}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
      <div id="tab-body" className="pt-4">
        {children.filter((el: any) => el.key === selectedTab)}
      </div>
    </div>
  );
};

export default Tabs;

export const TabPanel = ({
  key,
  children,
}: {
  children: any;
  key: string;
}): JSX.Element => {
  return (
    <div key={key} className="pt-2">
      {children}
    </div>
  );
};
