import React from 'react';

interface LabelProps {
  prefix?: string;
  label: string;
  icon?: any;
  presetStyle?: string;
}

const Label = (props: LabelProps) => {
  const { prefix, label, icon, presetStyle } = props;
  return (
    <div
      className={`flex rounded-full bg-text-input p-4 w-full ${presetStyle}`}
    >
      <img src={icon} className="mr-2" />
      <label>
        <span className="font-bold">{prefix}</span>
        {label}
      </label>
    </div>
  );
};

export default Label;
