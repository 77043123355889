import { ApolloError, useMutation } from '@apollo/client';
import { subscribe } from 'graphql';
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useHistory, useParams } from 'react-router-dom';
import { TextInput, Select } from '../../components';
import { NOTIFICATION } from '../../context/notificationContext';
import { useNotifications } from '../../hooks/notification';
import { NewIndividualSubscriberErrors, Subscriber, ShareholderTypes } from '../../model';
import { ADD_INDIVIDUAL_SUBSCRIBER, UPDATE_SUBSCRIBER } from '../../services/subscriber';
import {
  INTERNAL_SERVER_ERROR, newIndividualSubscriberErrorMessages
} from '../../utils/constants';
import { isEmailValid } from '../../utils/validators';

interface AddUpdateIndividualSubscriberProps {
  closeModal?: () => void;
  subscriber?: Subscriber,
  onUpdateSuccess?: (subscriber: Subscriber) => void
}

const AddUpdateIndividualSubscriber = ({
  closeModal,
  subscriber,
  onUpdateSuccess
}: AddUpdateIndividualSubscriberProps): JSX.Element => {
  const { eventId } = useParams<{ eventId: string }>();
  const { setNotification } = useNotifications();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('');
  const [shareholderType, setShareholderType] = useState(ShareholderTypes.PERSON.toString())

  const [validationErrors, setValidationsErrors] = useState(
    {} as NewIndividualSubscriberErrors,
  );

  useEffect(() => {
    if(subscriber) {
      setName(subscriber.name)
      setEmail(subscriber.email)
      setPhone(subscriber.phone)
      setAmount(subscriber.amount?.toString())
      setShareholderType(subscriber.shareholderType)
    }
  },[subscriber])
  const setInput = (setter: React.Dispatch<React.SetStateAction<string>>) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setter(event.target.value);
    setValidationsErrors({
      name: '',
      phone: '',
      email: '',
      amount: '',
    });
  };

  const [addIndividualSubscriber, { data, loading }] = useMutation(
    ADD_INDIVIDUAL_SUBSCRIBER,
  );

  const [updateIndividualSubscriber, {  loading: updateInProgress }] = useMutation(
    UPDATE_SUBSCRIBER
  );

  const addSubscriber = () => {
    addIndividualSubscriber({
      variables: {
        attributes: {
          name,
          shareIssuanceEventId: eventId,
          phone,
          email,
          amount: Number(amount),
          shareholderType
        },
      },
    })
      .then(() => {
        setNotification({
          title: 'Investor lagt til',
          type: NOTIFICATION.SUCCESS,
        });
        closeModal && closeModal();
      })
      .catch((err: ApolloError) => {
        setNotification({
          title: err.message ?? INTERNAL_SERVER_ERROR,
          type: NOTIFICATION.ERROR,
        });
      });
  }

  const updateSubscriber = (id: string) => {
    updateIndividualSubscriber({
      variables: {
        attributes: {
          id,
          name,
          phone,
          email,
          amount: Number(amount),
          shareholderType
        },
      },
    })
      .then(() => {
        setNotification({
          title: 'Subscriber updated successfully',
          type: NOTIFICATION.SUCCESS,
        });
        const updatedSubscriber = {...subscriber, name, phone, email, shareholderType, amount: amount ? Number(amount) : null } as Subscriber

        onUpdateSuccess && onUpdateSuccess(updatedSubscriber)
        closeModal && closeModal();
      })
      .catch((err: ApolloError) => {
        setNotification({
          title: err.message ?? INTERNAL_SERVER_ERROR,
          type: NOTIFICATION.ERROR,
        });
      });
  }

  const onSubmitForm = (event: React.FormEvent) => {
    event.preventDefault();
    if (checkValidation()) {
      if(subscriber)
        updateSubscriber(subscriber.id)
      else
        addSubscriber()
    }
  };

  const checkValidation = () => {
    let response = true;
    const errors: NewIndividualSubscriberErrors = {
      name: '',
      phone: '',
      email: '',
      amount: ''
    };

    if (!name) {
      response = false;
      errors.name = newIndividualSubscriberErrorMessages.NAME_REQUIRED;
    }

    if (email && !isEmailValid(email)) {
      response = false;
      errors.email = newIndividualSubscriberErrorMessages.INVALID_EMAIL;
    }

    if(amount && Number(amount) < 0) {
      response = false;
      errors.amount = newIndividualSubscriberErrorMessages.INVALID_AMOUNT
    }

    setValidationsErrors(errors);
    return response;
  };

  return (
    <div>
      <div className="flex justify-between items-center border-gray-200 border-b-1">
        <div>
          <h2 className="font-hind font-bold text-2xl">Legg til en investor</h2>
        </div>
        <button
          type="button"
          className="focus:outline-none"
          onClick={closeModal}
        >
          <i className="uc-icon text-2xl text-low-gray-color">&#xeb8e;</i>
        </button>
      </div>
      <form onSubmit={onSubmitForm}>
        <Select
          name={'shareholderType'}
          presetStyle="formSelect"
          label="Aksjonærtype"
          value={shareholderType}
          handleChange={(e) => {
            setShareholderType(e.target.value)
          }}
        >
          <option value={ShareholderTypes.PERSON}>Person</option>
          <option value={ShareholderTypes.COMPANY}>Organisasjon</option>
        </Select>
        <TextInput
          name="name"
          placeholder="Navn"
          type="text"
          errorMessage={validationErrors.name}
          label="Navn"
          onChange={setInput(setName)}
          value={name}
        />
        <TextInput
          name="email"
          placeholder="Epost"
          type="text"
          errorMessage={validationErrors.email}
          label="Epost"
          onChange={setInput(setEmail)}
          value={email}
        />
        <TextInput
          name="phone"
          placeholder="Telefonnummer"
          type="text"
          errorMessage={validationErrors.phone}
          label="Telefonnummer"
          onChange={setInput(setPhone)}
          value={phone}
        />
        <TextInput
          name="amount"
          placeholder="Antall"
          type="number"
          errorMessage={validationErrors.amount}
          label="Antall"
          onChange={setInput(setAmount)}
          value={amount}
        />
        <div className="mt-12">
          <button
            className="btn btn-primary btn-block outline-none bg-orange-light mr-0 xl:mr-4 mb-4 xl:mb-0"
            type="submit"
          >
            {loading || updateInProgress ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#4B2A58"
              />
            ) : (
              <p className="text-white">Lagre</p>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUpdateIndividualSubscriber;
