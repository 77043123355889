/* eslint-disable react/display-name */
import React, { useState, useEffect, useMemo } from 'react';
import {
  dashboardContainer,
  fundingEventTabs,
  fundingEventsRoutePrefix,
} from '../../utils/constants';
import {
  ActionButton,
  Pill,
  SecondaryEmptyIcon,
  Message,
  Skeleton,
  Tabs,
  TabPanel,
  Alert,
  Modal,
  SelectDropDown,
} from '../../components';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  useLazyQuery,
  useMutation,
  Reference,
  StoreObject,
  ApolloError,
  useQuery,
} from '@apollo/client';
import { GET_SINGLE_FUNDING_EVENT } from '../../services/company';
import {
  GET_SINGLE_FOUNDING_EVENT,
  DELETE_FOUNDING_EVENT,
} from '../../services/foundingEvents';
import { toLocaleDateTime } from '../../utils/formatTime';
import { Button } from '../../components/button';
import {
  SingleFundingEventVar,
  SingleFundingEventProps,
  FundingEvent,
  BoardMeeting,
  PublicTransitionEvents,
  FoundingEvent,
  SingleFoundingEventProps,
} from '../../model';
import { useNotifications } from '../../hooks/notification';
import {
  DELETE_FUNDING_EVENT,
  TRANSITION_FUNDING_EVENT_STATUS,
} from '../../services/fundingEvent';
import { NOTIFICATION } from '../../context/notificationContext';
import { useUrlBuilder } from '../../hooks/urlBuilder';
import { handleEventStatus } from '../../utils/events';
import ReactLoading from 'react-loading';
import { EventSubscribers } from '../subscribers';
import IsFundingEvent from '../../hooks/isFundingEvent';
import { toReadableEventStatus } from '../../utils/toReadableEventStatus';
import { GET_MANAGED_COMPANY_DETAIL } from '../../services/company';

const SingleFundingEvent = (): JSX.Element => {
  const { buildAdminUrl, buildShareholderUrl, selectedCompanyId } = useUrlBuilder();
  const [boardMeetings, setBoardMeetings] = useState({} as BoardMeeting);
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();
  const isFundingEvent = IsFundingEvent();

  const [selectedEvent, setSelectedEvent] = useState({} as FundingEvent);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isCompleteDialogVisible, setIsCompleteDialogVisible] = useState(false);
  const [selectedAction, setSelectedAction] = useState(
    {} as PublicTransitionEvents,
  );
  const [transitionError, setTransitionError] = useState("");

  const { data: managedCompany } = useQuery(
    GET_MANAGED_COMPANY_DETAIL,
    {
      variables: {
        id: selectedCompanyId
      }
    },
  );

  const { setNotification } = useNotifications();

  const [deleteFundingEvent] = useMutation(DELETE_FUNDING_EVENT, {
    update(
      cache,
      {
        data: {
          deleteFundingEvent: { id: deletedFundingEventId },
        },
      },
    ) {
      cache.modify({
        fields: {
          fundingEvents(existingFundingEvents, { readField }) {
            return existingFundingEvents.filter(
              (fundingEventRef: Reference | StoreObject | undefined) =>
                deletedFundingEventId !== readField('id', fundingEventRef),
            );
          },
        },
      });
    },
  });

  const [deleteFoundingEvent] = useMutation(DELETE_FOUNDING_EVENT, {
    update(
      cache,
      {
        data: {
          deleteFoundingEvent: { id: deletedFoundingEventId },
        },
      },
    ) {
      cache.modify({
        fields: {
          foundingEvents(existingFoundingEvents, { readField }) {
            return existingFoundingEvents.filter(
              (foundingEventRef: Reference | StoreObject | undefined) =>
                deletedFoundingEventId !== readField('id', foundingEventRef),
            );
          },
        },
      });
    },
  });

  const [
    transitionFundingEventStatus,
    { data: completedFundingEvent },
  ] = useMutation(TRANSITION_FUNDING_EVENT_STATUS);

  const [
    getFundingEvent,
    { loading, startPolling, stopPolling, refetch, data: fundingEventData },
  ] = useLazyQuery<any, SingleFundingEventVar>(
    isFundingEvent ? GET_SINGLE_FUNDING_EVENT : GET_SINGLE_FOUNDING_EVENT,
    {
      onCompleted: (fundingEvent) => {
        setSelectedEvent(
          isFundingEvent
            ? fundingEvent.fundingEvent
            : fundingEvent.foundingEvent,
        );
        setBoardMeetings(
          isFundingEvent
            ? fundingEvent.fundingEvent.boardMeeting
            : fundingEvent.foundingEvent.boardMeeting,
        );
      },
      onError: () => setSelectedEvent({} as FundingEvent),
    },
  );

  useEffect(() => {
    if (selectedEvent && selectedEvent?.status?.background)
      startPolling && startPolling(3000);
    else stopPolling && stopPolling();
  }, [selectedEvent]);

  useEffect(() => {
    fundingEventData &&
      setSelectedEvent(
        isFundingEvent
          ? fundingEventData.fundingEvent
          : fundingEventData.foundingEvent,
      );
  }, [fundingEventData]);

  useEffect(() => {
    if (eventId) {
      getFundingEvent({
        variables: {
          id: eventId,
        },
      });
    }
  }, []);

  if (loading) return <Skeleton />;

  const removeFundingEvent = (deleteFunction: any, id: string) => {
    deleteFunction({
      variables: {
        id,
      },
    })
      .then((res: any) => {
        setNotification({ title: 'Slettet', type: NOTIFICATION.SUCCESS });
        history.push(
          buildAdminUrl(
            isFundingEvent ? '/funding-events' : '/founding-events',
          ),
        );
      })
      .catch((err: ApolloError) => {
        setNotification({
          title: 'Noe gikk galt, prøv igjen',
          type: NOTIFICATION.ERROR,
        });
      });
  };

  const handleEventDeletion = (id: string) => {
    isFundingEvent
      ? removeFundingEvent(deleteFundingEvent, id)
      : removeFundingEvent(deleteFoundingEvent, id);
  };

  const handletransitionFundingEventStatus = () => {
    transitionFundingEventStatus({
      variables: {
        shareIssuanceEventId: selectedEvent.id,
        transition: selectedAction.transition,
      },
    })
      .then((res) => {
        refetch &&
          refetch().then((res) => setSelectedEvent(res?.data?.fundingEvent));
        setNotification({
          title: selectedAction && selectedAction.translation,
          type: NOTIFICATION.SUCCESS,
        });
        setTransitionError("")
      })
      .catch((err: ApolloError) => {
        setTransitionError(err.message)
      });
  };

  return (
    <div className={dashboardContainer}>
      <Alert
        visible={isDialogVisible}
        title={'Slett Emisjon'}
        message={'Er du sikker på at du vil slette emisjon?'}
        onCancel={() => setIsDialogVisible(false)}
        okText="Slett"
        onOk={() => {
          setIsDialogVisible(false);
          handleEventDeletion(selectedEvent.id);
        }}
      />
      <Alert
        visible={isCompleteDialogVisible}
        title={selectedAction && selectedAction.translation}
        message={'Er du sikker på at du vil fortsette?'}
        onCancel={() => setIsCompleteDialogVisible(false)}
        okText={selectedAction && selectedAction.translation}
        onOk={() => {
          setIsCompleteDialogVisible(false);
          handletransitionFundingEventStatus();
        }}
        variant="success"
      />
      <div className="mb-4 p-4 flex w-full">
        <div className="flex flex-col w-full">
          <h2 className="font-hind font-bold text-5xl">
            {selectedEvent?.name}
          </h2>
          <div className="flex mb-4">
            <span className="font-roboto text-grey">Emisjon type:</span>
            <span className="ml-1 font-semibold">
              {toReadableEventStatus(selectedEvent?.type)}
            </span>
          </div>
          <div className="flex mb-4">
            <span className="font-roboto text-grey">Emisjon Dato:</span>
            <span className="ml-1 font-semibold">
              {toLocaleDateTime(selectedEvent.eventOn, 'LL')}
            </span>
          </div>
          { transitionError != "" && (
            <div className="my-4 border-1 border-red-500 p-4 rounded-2xl lg:w-96 text-center">
              {transitionError}
            </div>
          )}
          <div className="flex mb-2 lg:items-center flex-col lg:flex-row">
            <div className="flex">
              <span className="font-roboto text-grey">Status:</span>
              {selectedEvent?.status && (
                <Pill
                  variant={handleEventStatus(
                    selectedEvent?.status,
                    selectedEvent?.publicTransitionEvents,
                  )}
                  className="flex items-center justify-between normal-case"
                >
                  <span className="font-semibold">
                    {selectedEvent?.status?.label}
                  </span>
                  {selectedEvent?.status?.background && (
                    <ReactLoading
                      type="spin"
                      height={'10px'}
                      width={'10px'}
                      color="#4B2A58"
                    />
                  )}
                </Pill>
              )}
            </div>
            {selectedEvent?.publicTransitionEvents &&
              selectedEvent?.publicTransitionEvents.length !== 0 && (
              <div className="flex flex-col lg:flex-row items-center lg:ml-4 mt-4 lg:mt-0">
                {selectedEvent?.publicTransitionEvents.map((el, idx) => (
                  <Button
                    key={idx}
                    label={el.translation}
                    type="button-5"
                    presetLabelStyle="normal-case"
                    presetStyle="py-1 mr-2 w-full lg:w-auto mb-4 lg:mb-0"
                    toExecute={() => {
                      setIsCompleteDialogVisible(true);
                      setSelectedAction(el);
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {Object.values(selectedEvent).length ? (
        <div className="bg-white p-6 rounded-3xl flex flex-col">
          <Tabs tabs={fundingEventTabs}>
            <TabPanel key="event-description">
              <div className="flex flex-col">
                <div id="details-wrapper" className="">
                  <div className="flex flex-col lg:flex-row mb-8">
                    <Button
                      label="Rediger emisjon"
                      size="lg"
                      type="button-2"
                      presetStyle="mb-4"
                      presetLabelStyle="normal-case"
                      toExecute={() => {
                        history.push(
                          buildAdminUrl(
                            isFundingEvent
                              ? `/funding-events/${eventId}/edit`
                              : `/founding-events/${eventId}/edit`,
                          ),
                        );
                      }}
                    />
                    <div className="w-4" />
                    <Button
                      label="Slett emisjon"
                      size="lg"
                      type="button-2"
                      presetStyle="mb-4"
                      presetLabelStyle="normal-case"
                      toExecute={() => {
                        setIsDialogVisible(true);
                      }}
                    />
                  </div>
                  <div className="flex">
                    <div className="w-full">
                      <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                        <span className="text-grey font-roboto">
                          Antall nye aksjer
                        </span>
                        <span className="font-hind font-semibold">
                          {selectedEvent?.amount}
                        </span>
                      </div>
                      <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                        <span className="text-grey font-roboto">
                          Aksjeklasse
                        </span>
                        <div className="flex flex-row">
                          {selectedEvent?.shareClasses &&
                            selectedEvent?.shareClasses.map(
                              (el: string, index: number) => (
                                <Pill variant="success" key={index}>
                                  {el}
                                </Pill>
                              ),
                            )}
                        </div>
                      </div>
                      <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                        <span className="text-grey font-roboto">
                          Price per share
                        </span>
                        <span className="font-hind font-semibold">
                          {selectedEvent?.pricePerShare}
                        </span>
                      </div>
                      <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                        <span className="text-grey font-roboto">
                          Aksje premium
                        </span>
                        <span className="font-hind font-semibold">
                          {selectedEvent?.sharePremium}
                        </span>
                      </div>

                      <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                        <span className="text-grey font-roboto">
                          Egenkapital pr aksje
                        </span>
                        <span className="font-hind font-semibold">
                          {selectedEvent?.capitalPerShare}
                        </span>
                      </div>

                      <div className="flex flex-col justify-between border-b border-very-low-gray-color pb-2 mb-4 w-full">
                        <span className="text-grey font-roboto">
                          Beskrivelse
                        </span>
                        <pre className="font-hind pl-2">
                          {selectedEvent?.description}
                        </pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel key="board-meeting">
              {boardMeetings ? (
                <div className="flex flex-col">
                  <div className="flex flex-col lg:flex-row mb-8">
                    <Button
                      label="Legg til / Se sakspunkter"
                      size="lg"
                      type="button-2"
                      presetStyle="mb-4"
                      toExecute={() =>
                        history.push(
                          buildAdminUrl(
                            `/board-meetings/${boardMeetings?.id}/agendas`,
                          ),
                        )
                      }
                    />
                    <div className="w-4" />
                    <Button
                      toExecute={() =>
                        history.push(
                          buildShareholderUrl(
                            isFundingEvent
                              ? `/funding-events/board-meetings/${boardMeetings?.id}/agendas-voting`
                              : `/founding-events/board-meetings/${boardMeetings?.id}/agendas-voting`,
                          ),
                        )
                      }
                      label="Stem på sakspunktene"
                      size="lg"
                      type="button-2"
                      presetStyle="mb-4"
                    />
                    <div className="w-4" />
                    <Button
                      toExecute={() =>
                        history.push(
                          buildAdminUrl(
                            `/board-meetings/${boardMeetings?.id}/edit`,
                          ),
                        )
                      }
                      label="Rediger generalforsamling"
                      size="lg"
                      type="button-2"
                      presetStyle="mb-4"
                    />
                    <div className="w-4" />
                    <Button
                      toExecute={() => console.log('clicked')}
                      label="Slett generalforsamling"
                      size="lg"
                      type="button-2"
                      presetStyle="mb-4"
                    />
                  </div>
                  <div>
                    <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                      <span className="text-grey font-roboto">Dato</span>
                      <span className="font-hind font-semibold">
                        {toLocaleDateTime(boardMeetings?.meetingAt)}
                      </span>
                    </div>
                    <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                      <span className="text-grey font-roboto">Navn</span>
                      <span className="font-hind font-semibold">
                        {boardMeetings?.name}
                      </span>
                    </div>
                    <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                      <span className="text-grey font-roboto">Status</span>
                      <span className="font-hind font-semibold">
                        {boardMeetings?.status}
                      </span>
                    </div>
                    <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                      <span className="text-grey font-roboto">Videomøte</span>
                      <Pill
                        variant={boardMeetings?.online ? 'success' : 'danger'}
                      >
                        {boardMeetings?.online ? 'Ja' : 'Nei'}
                      </Pill>
                    </div>
                    <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                      <span className="text-grey font-roboto">
                        Fysisk møtested
                      </span>
                      <Pill
                        variant={boardMeetings?.offline ? 'success' : 'danger'}
                      >
                        {boardMeetings?.offline ? 'Ja' : 'Nei'}
                      </Pill>
                    </div>
                    <div className="flex flex-col justify-between border-b border-very-low-gray-color pb-2 mb-4 w-full">
                      <span className="text-grey font-roboto">Beskrivelse</span>
                      <pre className="font-hind pl-2">
                        {boardMeetings?.description}
                      </pre>
                    </div>
                  </div>
                </div>
              ) : (
                <Message
                  title="Generalforsamling ikke funnet"
                  description="Lag en Generalforsamling her"
                  toExecute={() =>
                    history.push(
                      buildAdminUrl(
                        isFundingEvent
                          ? `/funding-events/${eventId}/board-meetings/new`
                          : `/founding-events/${eventId}/board-meetings/new`,
                      ),
                    )
                  }
                  icon={<SecondaryEmptyIcon />}
                  btnIcon={<i className="uc-icon text-grey ml-4">&#xead5;</i>}
                  btnLabel="Lag Generalforsamlings invitasjon"
                />
              )}
            </TabPanel>
            <TabPanel key="subscribers">
              <EventSubscribers />
            </TabPanel>
            <TabPanel key="payment-details">
              <div className="w-full">
                <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                  <span className="text-grey font-roboto">
                    Bank navn
                  </span>
                  <span className="font-hind font-semibold">
                    {managedCompany?.managedCompany.bankName}
                  </span>
                </div>
                <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                  <span className="text-grey font-roboto">
                    Bankadresse
                  </span>
                  <span className="font-hind font-semibold">
                    {managedCompany?.managedCompany.bankAddress}
                  </span>
                </div>
                <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                  <span className="text-grey font-roboto">
                    Kontonummer
                  </span>
                  <span className="font-hind font-semibold">
                    {managedCompany?.managedCompany.accountNumber}
                  </span>
                </div>
                <div className="flex flex-col xl:flex-row justify-between border-b border-very-low-gray-color pb-2 mb-4">
                  <span className="text-grey font-roboto">
                    Bic -kode
                  </span>
                  <span className="font-hind font-semibold">
                    {managedCompany?.managedCompany.bicCode}
                  </span>
                </div>
                <div className="flex flex-col justify-between border-b border-very-low-gray-color pb-2 mb-4 w-full">
                  <span className="text-grey font-roboto">
                    Referansetekst
                  </span>
                  <pre className="font-hind pl-2">
                    {selectedEvent?.referenceText || "-"}
                  </pre>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      ) : (
        <div className="bg-white p-6 rounded-3xl h-full flex justify-center items-center">
          <Message
            title="Funding Event ikke funnet"
            description="Lag en Funding Event her"
            toExecute={() =>
              history.push(
                buildAdminUrl(
                  isFundingEvent
                    ? '/funding-events/new'
                    : '/founding-events/new',
                ),
              )
            }
            icon={<SecondaryEmptyIcon />}
            btnIcon={<i className="uc-icon text-grey ml-4">&#xead5;</i>}
            btnLabel="Ny emisjon"
          />
        </div>
      )}
    </div>
  );
};

export default SingleFundingEvent;
