import React from 'react';
import styled from 'styled-components';

interface MessageProps {
  title: string;
  description: string;
  toExecute: () => void;
  icon: JSX.Element;
  btnIcon?: JSX.Element;
  btnLabel: string;
}

export default function Message({
  title,
  description,
  toExecute,
  icon,
  btnIcon,
  btnLabel,
}: MessageProps) {
  return (
    <div className="flex flex-col w-full items-center justify-center">
      {icon}
      <h1 className="font-hind font-bold text-xl">{title}</h1>
      <p className="mb-6 font-roboto text-lg">{description}</p>
      {btnLabel && (
        <div className="">
          <button
            className="btn btn-primary btn-block font-hind font-bold outline-none"
            onClick={toExecute}
            id="login-btn"
            type="submit"
          >
            <span>{btnLabel}</span>
            {btnIcon}
          </button>
        </div>
      )}
    </div>
  );
}
