import { gql } from '@apollo/client';
import { PagingVars } from 'src/model';

export const CREATE_BOARD_MEETING = gql`
mutation CreateBoardMeeting($attributes: BoardMeetingInput!){
    createBoardMeeting(attributes: $attributes) {
      city
      createdAt
      description
      id
      name
      offline
      online
      postCode
      streetAddress
      videoconferenceLink
    }
  }
`;

export const GET_BOARD_MEETING_AGENDAS = gql`
query getBoardMeetingAgendas($boardMeetingId: ID!){
  boardMeetingAgendas(boardMeetingId: $boardMeetingId){
    createdAt
    description
    id
    name
    position
    updatedAt
    voteStats{
      no
      pass
      yes
    }
    votes{
      id
      status
    }
  }
}
`;

export const GET_BOARD_MEETING = gql`
query getBoardMeetingDetail($id: ID!){
  boardMeeting(id: $id){
    city
    createdAt
    description
    id
    meetingAt
    name
    offline
    online
    postCode
    status
    streetAddress
    updatedAt
    videoconferenceLink
  }
}
`;

export const VOTE_FOR_AGENDA = gql`
mutation voteForBoardMeetingAgenda($attributes: BoardMeetingAgendaVoteInput!){
  toggleBoardMeetingAgendaVote(attributes: $attributes){
    id
    voteStats{
      no
      pass
      yes
    }
    votes{
      id
      status
    }
    createdAt
  }
}
`;

export const UPDATE_BOARD_MEETING = gql`
mutation UpdateBoardMeeting($attributes: BoardMeetingInput!){
  updateBoardMeeting(attributes: $attributes){
    city
    createdAt
    description
    id
    meetingAt
    name
    offline
    online
    postCode
    status
    streetAddress
    updatedAt
    videoconferenceLink
    shareIssuanceEventId
  }
}
`;

export const SEARCH_SHAREHOLDERS = gql`
query shareholders($companyId: ID!, $fundingEventId: ID!, $search: String, $sort: String) {
  managedCompany(id: $companyId) {
    shareholders(search: $search, sort: $sort) {
      collection {
        id
        name
        email
        phone
        shareholderType
      }
    }
  }

  fundingEvent(id: $fundingEventId){
    subscribers(page: 1) {
      collection {
        email
      }
    }
  }
}
`;

export const GET_EVENT_SUBSCRIBERS = gql`
query EventSubscribers($id: ID!, $page: Int, $limit: Int, $sort: String, $search: String) {
  fundingEvent(id: $id){
    subscribers(page: $page, limit: $limit sort: $sort, search: $search) {
      collection {
        id
        name
        email
        phone
        status
        amount
        paid
        shareholderType
      }
      metadata {
        totalPages
        totalCount
        currentPage
        limitValue
      }
    }
  }
}
`;

export interface FundingEventSubscribersVar extends PagingVars {
  id: string;
};
