import React from 'react';

import ImgInvestingBook from '../../../assets/images/investingbooks.png';
import { primaryContainer } from '../../../utils/constants';
import { LoginForm } from '../../../components/Auth/login';

const LoginPage = (props: any): JSX.Element => (
  <section className="h-full">
    <div className="bg-eighth-color h-full">
      <div className={`${primaryContainer} py-36`}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="hidden lg:flex justify-center items-center">
            <img
              src={ImgInvestingBook}
              className="w-60 lg:w-96 mt-8 lg:mt-0"
              alt=""
            />
          </div>
          <div className="flex flex-col">
            {props.notice && <p className="register-form flex text-notice mb-2 justify-center">{props.notice}</p>}
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  </section>

);

export default LoginPage;
