import React, { Fragment, useState } from 'react';
import { TextBox } from '../../textBox';
import { Button } from '../../button';
import { useHistory } from 'react-router-dom';
import { UserCreateCompanyErrors } from '../../../model';
import { createCompanyMessages } from '../../../utils/constants';
import ReactLoading from 'react-loading';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { CREATE_COMPANY } from '../../../services/company';
import { DateTimePicker } from '../../dateTimePicker';
import { DATE_FORMAT_FLAT_PICKER_EQUIVALENT } from '../../../utils/constants';
import moment from 'moment';
import { GET_CALLBACK_LINK } from '../../../services/callBackLink';
import { setCallBackLink } from '../../../store/cache';
import { useUrlBuilder } from '../../../hooks/urlBuilder';
import { toLocaleDateTime } from '../../../utils/formatTime';

interface CompanyDetailsForm {
  data: {
    name: string;
    streetAddress?: string;
    registrationDate?: string;
    orgnr: string;
    postCode?: string;
    city?: string;
  };
}

const CompanyDetailsForm = (props: any): JSX.Element => {
  const { selectedCompanyId, buildAdminUrl } = useUrlBuilder()
  const { data } = props;

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(data.name);
  const [orgnr, setOrgnr] = useState(data.orgnr);
  const [registrationDate, setRegistrationDate] = useState(
    data.registrationDate,
  );
  const [postCode, setPostCode] = useState(data.postCode);
  const [streetAddress, setStreetAddress] = useState(data.streetAddress);
  const [city, setCity] = useState(data.city);
  const [errorMessage, setErrorMessage] = useState('');
  const [validationError, setValidationError] = useState(
    {} as UserCreateCompanyErrors,
  );

  const [createCompany] = useMutation(CREATE_COMPANY);

  const { data: callBackLink } = useQuery(GET_CALLBACK_LINK);

  const setInput =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        setter(event.target.value);
        setValidationError({ name: '' });
      };

  const checkValidation = () => {
    let response = true;
    const errors: UserCreateCompanyErrors = {
      name: '',
    };

    if (!name) {
      response = false;
      errors.name = createCompanyMessages.COMPANY_NAME_REQUIRED;
    }

    setValidationError(errors);
    return response;
  };

  const handleSubmit = () => {
    if (checkValidation()) {
      setLoading(true);
      setErrorMessage('');
      createCompany({
        variables: {
          name,
          orgnr,
          streetAddress,
          postCode,
          city,
          registrationDate,
        },
      })
        .then((res) => {
          setLoading(false);
          // history.push('/');
          // added in to refresh the whole application state instead of pushing
          window.location.href = callBackLink.callBackLink || buildAdminUrl('/dashboard', false, res.data.createCompany.id);
          setCallBackLink('');
        })
        .catch((err: ApolloError) => {
          setLoading(false);
          setErrorMessage(err.message);
        });
    }
  };

  return (
    <Fragment>
      <div className="rounded-2xl bg-tenth-color mt-8 lg:mt-0">
        <div className="bg-ninth-color rounded-2xl px-4 lg:px-20 py-8">
          <div className="mb-2">
            <span className="text-4xl leading-7 lg:leading-35 text-first-text-color font-hind tracking-normal font-bold not-italic">
              {name}
            </span>
          </div>
          <div>
            <div className="">
              <p className="font-roboto">Org nr {orgnr}</p>
            </div>
            <div>
              <p className="font-roboto">
                {`${streetAddress}, ${postCode} ${city}`}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-tenth-color px-4 lg:px-20 py-8 rounded-2xl">
          <div className="flex flex-col">
            <div
              id="row-1"
              className="flex flex-col lg:grid lg:grid-flow-col lg:grid-cols-2 lg:gap-x-20 lg:gap-y-4"
            >
              <div id="txt-box-holder-1" className="flex flex-col">
                <span className="mb-2 font-roboto text-first-text-color font-bold">
                  Selskapsnavn
                </span>
                <TextBox
                  id="company-name"
                  value={name}
                  txtType="text"
                  presetStyle="w-full"
                  handleTextChange={setInput(setName)}
                  errorMessage={validationError.name}
                />
              </div>
              <div id="txt-box-holder-2" className="flex flex-col">
                <span className="mb-2 font-roboto text-first-text-color font-bold">
                  Registreringsdato
                </span>
                <div className="-mt-8">
                  <DateTimePicker
                    presetStyle="flex rounded-full bg-white p-4 w-full"
                    options={{ enableTime: false, formatDate: () => toLocaleDateTime(registrationDate, 'L'), }}
                    defaultValue={moment(registrationDate)}
                    onChange={(date: any) =>
                      setRegistrationDate(moment(date[0]).format())
                    }
                    dateFormat={DATE_FORMAT_FLAT_PICKER_EQUIVALENT}
                  />
                </div>
              </div>
            </div>
            <div id="txt-box-holder-6" className="flex flex-col">
              <span className="mb-2 font-roboto text-first-text-color font-bold">
                Gatenavn og nummer
              </span>
              <TextBox
                value={streetAddress}
                txtType="text"
                presetStyle="w-full"
                handleTextChange={setInput(setStreetAddress)}
              />
            </div>
            <div
              id="row-1"
              className="flex flex-col lg:grid lg:grid-flow-col lg:grid-cols-2 lg:gap-x-20 lg:gap-y-4"
            >
              <div id="txt-box-holder-3" className="flex flex-col">
                <span className="mb-2 font-roboto text-first-text-color font-bold">
                  Postkode
                </span>
                <TextBox
                  value={postCode}
                  txtType="text"
                  presetStyle="w-full"
                  handleTextChange={setInput(setPostCode)}
                />
              </div>
              <div id="txt-box-holder-6" className="flex flex-col">
                <span className="mb-2 font-roboto text-first-text-color font-bold">
                  Sted
                </span>
                <TextBox
                  value={city}
                  txtType="text"
                  presetStyle="w-full"
                  handleTextChange={setInput(setCity)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
      </div>
      <div
        id="button-holder"
        className="flex flex-col-reverse lg:grid lg:grid-flow-col lg:grid-cols-2 lg:gap-16 lg:gap-20 mt-4 lg:px-20"
      >
        <Button
          label="Tilbake"
          toExecute={() => history.goBack()}
          type="button-5"
          size="lg"
          id="back-btn-company"
        />
        <Button
          label={
            loading ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#4B2A58"
              />
            ) : (
              'Fortsett'
            )
          }
          toExecute={handleSubmit}
          type="button-4"
          size="lg"
          presetStyle="mb-8 lg:mb-0"
          id="submit-btn-company"
        />
      </div>
    </Fragment>
  );
};

export default CompanyDetailsForm;
