import React from 'react'
import '../../../src/styles/notification.scss'
import { useNotifications } from "../../hooks/notification";
import { XIcon } from '@heroicons/react/outline'

type NotificationPositon = 'top' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

interface NotificationInterface {
  position: NotificationPositon,
  notificationShowTime: number
}

const Notification = (props: NotificationInterface) => {
  const { position } = props;

  const { notifications, deleteNotification } = useNotifications();

  return (
    <>
      <div className={`fixed z-999999 box-border ${position}`}>
        {notifications.map((notification, index) => (
          <div
            key={index} className={`notification ${position} w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}
          >
            <div className="flex">
              <div className="flex-shrink-0">
                { notification.icon }
              </div>
              <div className="ml-3 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900 one-line-clamp">{notification.title}</p>
                { notification.message && <p className="mt-1 text-sm text-gray-500 two-line-clamp">{notification.message}</p> }
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => {
                    deleteNotification(1)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Notification
