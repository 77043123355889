/* eslint-disable @typescript-eslint/no-empty-function */
import moment from 'moment';
import 'moment/locale/nb';
import { UNFORMATED_DATE_TIME } from '../utils/constants';

export const toLocaleDateTime = (stringDate: string | Date, format?: string) =>
  moment(
    stringDate,
    UNFORMATED_DATE_TIME,
  ).format(format ?? 'LLL')

