import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Wrapper } from '../components/wrapper';
import routes from '../routes';
import ProtectedRoute from './protectedRoute';
import PublicRoute from './publicRoute';
import _ from 'lodash';
import useUnsavedChangesWarning from '../hooks/unsavedChangesWarning';

const AppRoute = (): JSX.Element => {
  const { RouterPrompt } = useUnsavedChangesWarning({});
  return (
    <BrowserRouter>
      <RouterPrompt />
      <Switch>
        {routes.map((route: any, index: number) =>
          route.private ? (
            <ProtectedRoute
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => <Wrapper {...route} {...props} />}
            />
          ) : (
            <PublicRoute
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => <Wrapper {...route} {...props} />}
            />
          ),
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoute;
