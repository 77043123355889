/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import {
  useQuery,
  useLazyQuery,
  useMutation,
  ApolloError,
  Reference,
  StoreObject,
  gql,
} from '@apollo/client';
import { BackendTable, Pill } from '../../components';
import {
  dashboardContainer,
  FundingEventStatus,
  paginationElements,
  ShareIssuanceEventTypes,
} from '../../utils/constants';
import {
  ManagedCompany,
  FundingEventVar,
  FundingEvent,
  PagingMetadata,
  OwnedCompany,
  SubscribedCompany,
} from '../../model';
import {
  GET_MANAGED_COMPANY_SHARE_ISSUANCE_EVENTS,
  GET_OWNED_COMPANY_SHARE_ISSUANCE_EVENTS,
  GET_SUBSCRIBED_COMPANY_SHARE_ISSUANCE_EVENTS,
} from '../../services/shareIssuanceEvents';
import { addRowNum } from '../../utils/formatData';
import { toLocaleDateTime } from '../../utils/formatTime';
import { Button } from '../../components/button';
import { useHistory } from 'react-router-dom';
import { Alert } from '../../components/alert';
import { useNotifications } from '../../hooks/notification';
import { NOTIFICATION } from '../../context/notificationContext';
import { DELETE_FUNDING_EVENT } from '../../services/fundingEvent';
import { DELETE_FOUNDING_EVENT } from '../../services/foundingEvents';
import { useUrlBuilder } from '../../hooks/urlBuilder';
import { handleEventStatus } from '../../utils/events';
import ReactLoading from 'react-loading';
import { Role } from '../../utils/constants';
import { toReadableEventStatus } from '../../utils/toReadableEventStatus';

const FundingEvents = (): JSX.Element => {
  const { buildAdminUrl, selectedCompanyId, getRole } = useUrlBuilder();

  const { setNotification } = useNotifications();
  const [fundingEvents, setFundingEvents] = useState([] as FundingEvent[]);
  const [selectedEvent, setSelectedEvent] = useState({} as FundingEvent);
  const [
    deleteConfirmationModalVisibility,
    setDeleteConfirmationModalVisibility,
  ] = useState(false);

  const [deleteFundingEvent, { error: deleteFudingEventError }] = useMutation(
    DELETE_FUNDING_EVENT,
    {
      update(cache, { data: { deleteFundingEvent } }) {
        const { id } = deleteFundingEvent;

        cache.modify({
          fields: {
            fundingEvents(existingFundingEvents, { readField }) {
              return existingFundingEvents.filter(
                (fundingEventRef: Reference | StoreObject | undefined) =>
                  id !== readField('id', fundingEventRef),
              );
            },
          },
        });
      },
    },
  );

  const [deleteFoundingEvent] = useMutation(DELETE_FOUNDING_EVENT, {
    update(
      cache,
      {
        data: {
          deleteFoundingEvent: { id: deletedFoundingEventId },
        },
      },
    ) {
      cache.modify({
        fields: {
          foundingEvents(existingFoundingEvents, { readField }) {
            return existingFoundingEvents.filter(
              (foundingEventRef: Reference | StoreObject | undefined) =>
                deletedFoundingEventId !== readField('id', foundingEventRef),
            );
          },
        },
      });
    },
  });

  const [pageMetaData, setPageMetaData] = useState({} as PagingMetadata);
  const [sort, setSort] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const history = useHistory();

  const [
    getManagedCompanyFundingEvents,
    {
      error: managedCompanyFundingEventsError,
      refetch: refetchManagedCompanyFundingEvents,
    },
  ] = useLazyQuery<ManagedCompany, FundingEventVar>(
    GET_MANAGED_COMPANY_SHARE_ISSUANCE_EVENTS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (companyFundingEvents) => {
        setPageMetaData(
          companyFundingEvents.managedCompany.shareIssuanceEvents.metadata,
        );
        setFundingEvents(
          addRowNum(
            companyFundingEvents.managedCompany.shareIssuanceEvents.collection.map(
              (obj) => ({
                ...obj,
                eventOn: toLocaleDateTime(obj.eventOn, 'LL'),
              }),
            ),
          ),
        );
      },
      onError: () => setFundingEvents([]),
    },
  );

  const [
    getOwnedCompanyFundingEvents,
    {
      error: ownedCompanyFundingEventsError,
      refetch: refetchOwnedCompanyFundingEvents,
    },
  ] = useLazyQuery<OwnedCompany, FundingEventVar>(
    GET_OWNED_COMPANY_SHARE_ISSUANCE_EVENTS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (companyFundingEvents) => {
        setPageMetaData(
          companyFundingEvents.ownedCompany.shareIssuanceEvents.metadata,
        );
        setFundingEvents(
          addRowNum(
            companyFundingEvents.ownedCompany.shareIssuanceEvents.collection.map(
              (obj) => ({
                ...obj,
                eventOn: toLocaleDateTime(obj.eventOn),
              }),
            ),
          ),
        );
      },
      onError: () => setFundingEvents([]),
    },
  );

  const [
    getSubscribedCompanyFundingEvents,
    {
      error: subscribedCompanyFundingEventsError,
      refetch: refetchSubscribedCompanyFundingEvents,
    },
  ] = useLazyQuery<SubscribedCompany, FundingEventVar>(
    GET_SUBSCRIBED_COMPANY_SHARE_ISSUANCE_EVENTS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (companyFundingEvents) => {
        setPageMetaData(
          companyFundingEvents.subscribedCompany.shareIssuanceEvents.metadata,
        );
        setFundingEvents(
          addRowNum(
            companyFundingEvents.subscribedCompany.shareIssuanceEvents.collection.map(
              (obj) => ({
                ...obj,
                eventOn: toLocaleDateTime(obj.eventOn),
              }),
            ),
          ),
        );
      },
      onError: () => setFundingEvents([]),
    },
  );

  const getFundingEvents = () => {
    const role = getRole();

    switch (role) {
    case Role.ADMIN:
      getManagedCompanyFundingEvents({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage ?? paginationElements.INITIAL_PAGE,
          limit: pageMetaData.limitValue ?? paginationElements.LIMIT,
          sort,
          search: searchValue,
        },
      });
      break;
    case Role.SUBSCRIBER:
      getSubscribedCompanyFundingEvents({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage ?? paginationElements.INITIAL_PAGE,
          limit: pageMetaData.limitValue ?? paginationElements.LIMIT,
          sort,
          search: searchValue,
        },
      });
      break;
    case Role.SHAREHOLDER:
      getOwnedCompanyFundingEvents({
        variables: {
          companyId: selectedCompanyId,
          page: pageMetaData.currentPage ?? paginationElements.INITIAL_PAGE,
          limit: pageMetaData.limitValue ?? paginationElements.LIMIT,
          sort,
          search: searchValue,
        },
      });
      break;
    }
  };

  useEffect(() => {
    if (
      selectedCompanyId &&
      !managedCompanyFundingEventsError &&
      !ownedCompanyFundingEventsError &&
      !subscribedCompanyFundingEventsError
    ) {
      getFundingEvents();
    }
  }, [selectedCompanyId, fundingEvents]);

  const columns = [
    {
      Header: 'Navn',
      accessor: 'name',
    },
    {
      Header: 'Dato',
      accessor: 'eventOn',
    },
    {
      Header: 'Antall nye aksjer',
      accessor: 'amount',
    },
    {
      Header: 'Egenkapital pr aksje',
      accessor: 'capitalPerShare',
    },
    {
      Header: 'Pris per aksje',
      accessor: 'pricePerShare',
    },
    {
      Header: 'Aksje premium',
      accessor: 'sharePremium',
    },
    {
      Header: 'Emisjon',
      accessor: 'type',
      Cell: (props: any) => {
        return (
          <span>
            {toReadableEventStatus(props.cell.row.original.type ?? '')}
          </span>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 400,
      Cell: (props: any) => {
        return (
          <Pill
            variant={handleEventStatus(
              props.cell.row.original.status,
              props.cell.row.original.publicTransitionEvents,
            )}
            className="flex items-center justify-between normal-case"
          >
            <span className="mr-4">{props.cell.row.original.status.label}</span>
            {props.cell.row.original.status.background && (
              <ReactLoading
                type="spin"
                height={'10px'}
                width={'10px'}
                color="#4B2A58"
              />
            )}
          </Pill>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      Cell: (props: any) => {
        return (
          <div className="flex">
            <Button
              label={<i className="uc-icon text-grey">&#xe972;</i>}
              presetStyle="mr-4"
              type="button-5"
              toExecute={() => {
                history.push(
                  buildAdminUrl(
                    props.cell.row.original.type ===
                      ShareIssuanceEventTypes.FundingEvent
                      ? `/funding-events/${props.cell.row.original.id}/edit`
                      : `/founding-events/${props.cell.row.original.id}/edit`,
                  ),
                );
              }}
            />
            <Button
              toExecute={() =>
                history.push(
                  buildAdminUrl(
                    props.cell.row.original.type ===
                      ShareIssuanceEventTypes.FundingEvent
                      ? `/funding-events/${props.cell.row.original.id}`
                      : `/founding-events/${props.cell.row.original.id}`,
                  ),
                )
              }
              label={<i className="uc-icon text-grey">&#xe9a8;</i>}
              type="button-5"
            />
            {props.cell.row.original.status.label ===
              FundingEventStatus.DRAFT && (
              <Button
                label={<i className="uc-icon text-grey">&#xeb97;</i>}
                presetStyle="ml-4"
                type="button-5"
                toExecute={() => {
                  setSelectedEvent(props.cell.row.original);
                  setDeleteConfirmationModalVisibility(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const removeFundingEvent = (deleteFunction: any, id: string) => {
    deleteFunction({
      variables: {
        id,
      },
    })
      .then((res: any) => {
        setNotification({ title: 'Slettet', type: NOTIFICATION.SUCCESS });
        // remove from the state.
        setFundingEvents(fundingEvents.filter((item) => item.id !== id));
      })
      .catch((err: ApolloError) => {
        const role = getRole();
        if (role === Role.ADMIN && refetchManagedCompanyFundingEvents)
          refetchManagedCompanyFundingEvents();
        if (role === Role.SUBSCRIBER && refetchSubscribedCompanyFundingEvents)
          refetchSubscribedCompanyFundingEvents();
        if (role === Role.SHAREHOLDER && refetchOwnedCompanyFundingEvents)
          refetchOwnedCompanyFundingEvents();

        setNotification({
          title: 'Noe gikk galt, prøv igjen',
          type: NOTIFICATION.ERROR,
        });
      });
  };

  const handleEventDeletion = () => {
    selectedEvent.type === ShareIssuanceEventTypes.FundingEvent
      ? removeFundingEvent(deleteFundingEvent, selectedEvent.id)
      : removeFundingEvent(deleteFoundingEvent, selectedEvent.id);
  };

  return (
    <>
      <Alert
        visible={deleteConfirmationModalVisibility}
        title={'Slett Emisjon'}
        message={'Er du sikker på at du vil slette emisjon?'}
        onCancel={() => setDeleteConfirmationModalVisibility(false)}
        okText="Slett"
        onOk={() => {
          setDeleteConfirmationModalVisibility(false);
          handleEventDeletion();
        }}
      />
      <div className={dashboardContainer}>
        <div className="mb-4 p-4 flex justify-between items-center">
          <div>
            <span className="font-hind font-bold text-5xl">Emisjon</span>
          </div>
          <div>
            <Button
              label={
                <>
                  Ny emisjon
                  <i className="uc-icon text-white ml-2">&#xead5;</i>
                </>
              }
              toExecute={() =>
                history.push(buildAdminUrl('/funding-events/new'))
              }
              type="button-2"
              presetStyle="mb-4"
            />
          </div>
        </div>
        <BackendTable
          columns={columns}
          data={fundingEvents}
          searchable
          paginate
          tableHeader
          onTableChange={() => getFundingEvents()}
          setPageMetaData={setPageMetaData}
          pageMetaData={pageMetaData}
          setSortValue={setSort}
          sortValue={sort}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </div>
    </>
  );
};

export default FundingEvents;
