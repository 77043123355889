import { Button } from '../../../components/button'
import React, { useState } from 'react'
import { BoardMeetingAgenda, BoardMeetingAgendaErrors } from '../../../model/boardMeetingAgenda'
import { getFormData } from '../../../utils/form'
import { TextInput } from '../../textInput'
import { XIcon } from '@heroicons/react/outline'

interface BoardMeetingAgendaFormInterface {
  boardMeetingAgenda?: BoardMeetingAgenda
  onSaveAgenda: (name: string, description: string, isNewItem?: boolean) => void
  onCancel: () => void
}
const BoardMeetingAgendaForm = (props: BoardMeetingAgendaFormInterface) => {
  const [validationErrors, setValidationErrors] = useState({} as BoardMeetingAgendaErrors)
  const { boardMeetingAgenda, onCancel, onSaveAgenda } = props

  const checkValidation = (title: string) => {
    let response = true
    const validationErrors = {} as BoardMeetingAgendaErrors

    if (!title) {
      validationErrors.title = 'Tittle må skrives inn'
      response = false
    }
    setValidationErrors(validationErrors)
    return response
  }

  const save = (event: any) => {
    event.preventDefault()

    const formData = getFormData(event.target.elements)
    const title = formData[`title`]
    const description = formData[`description`]

    if (checkValidation(title)) {
      if(boardMeetingAgenda)
        onSaveAgenda(title, description)
      else
        onSaveAgenda(title, description, true)
    }

  }

  return (
    <div className="mx-2">
      <div className="flex">
        <p className="text-md font-bold flex-1">{ boardMeetingAgenda ? 'Rediger agenda' : 'Lage agenda' }</p>
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none align-right"
          onClick={onCancel}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <form onSubmit={save} className="flex flex-1">
        <div className="flex-1 flex flex-col">
          <TextInput
            name={`title`}
            placeholder={'Tittel'}
            type="text"
            errorMessage={validationErrors.title}
            defaultValue={boardMeetingAgenda?.name}
            presetStyle="bg-white border-1 rounded-xl"
          />
          {/* TODO replace this with the text area component  */}
          <textarea placeholder={'Beskrivelse'} defaultValue={boardMeetingAgenda?.description} name={`description`} className="flex-1 p-4 rounded-xl focus:outline-none border-1" rows={4} />
          <div className="flex gap-5 mt-5">
            <div className="flex-1"/>
            <button type="button" onClick={onCancel} className="px-4 py-2 border border-gray-300 shadow-sm text-sm rounded-md bg-white outline-none">Avbryt</button>
            <button type="submit" className="px-4 py-2 shadow-sm text-sm rounded-md bg-orange-light outline-none" >Lagre</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BoardMeetingAgendaForm
