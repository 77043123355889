import React from 'react';

interface SelectComponentProps {
  errorMessage?: string;
  handleChange?: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
  placeHolder?: string;
  id?: string;
  value?: string;
  presetStyle?: string;
  children?: JSX.Element | any;
}

const Select = ({
  errorMessage,
  handleChange,
  id,
  value,
  presetStyle,
  children,
  placeHolder,
}: SelectComponentProps): JSX.Element => {
  return (
    <div
      className={`form-group  ${presetStyle} ${errorMessage && 'has-error'}`}
    >
      <select
        value={value}
        onChange={handleChange}
        id={id}
        className="form-control outline-none rounded-full px-8 select-box"
      >
        {placeHolder && (
          <option value="" disabled selected>
            {placeHolder}
          </option>
        )}

        {children}
      </select>
    </div>
  );
};

export default Select;
