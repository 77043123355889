import { Dividend, ShareholderDividend } from './dividend';
import {
  TransactionsCollection,
  ShareholdersCollection,
  SubscribersCollection
} from './company'
import { FundingEventsCollection } from './fundingEvent'
import { FoundingEventsCollection } from './foundingEvent'
import { ShareIssuanceEventsCollection } from './shareIssuanceEvents';

export interface Transaction {
  id: string;
  amount: number;
  company: Company;
  fromShareholder: Shareholder;
  priceperShare: string;
  sharePremium: string;
  toShareholder: Shareholder;
  transactionAt: string;
  transactionType: string;
}

export interface Company {
  admins: User[];
  color: string;
  devidends: Dividend[];
  id: string;
  name: string;
  registrationDate: string,
  city: string
  streetAddress: string,
  postCode: string,
  orgnr: string;
  shareClasses: string[];
  defaultShareClass: string;
  shareholders: ShareholdersCollection;
  shares: Share[];
  transactions: TransactionsCollection;
  fundingEvents: FundingEventsCollection;
  subscribers: SubscribersCollection;
  totalShares: number;
  canInitialize: boolean;
  isEmpty: boolean;
  foundingEvents: FoundingEventsCollection;
  shareIssuanceEvents: ShareIssuanceEventsCollection
  bankName?: string
  bankAddress?: string
  accountNumber?: string
  bicCode?: string
}

export enum ShareholderTypes {
  PERSON = 'Person',
  COMPANY = 'Company'
}

export interface NewIndividualShareholderErrors {
  name: string,
  phone: string,
  email: string,
  bankAccount: string,
  streetAddress: string,
  postCode: string,
  city: string,
  contact: string,
}

export interface Shareholder {
  id: string;
  bankAccount: string;
  company: Company;
  email: string;
  identifier: string;
  indirectlyOwnedShares: IndirectlyOwnedShares[];
  name: string;
  shareholderDividends: ShareholderDividend[];
  shares: Share[];
  transactions: Transaction[];
  type: string
  shareholderType: ShareholderTypes;
  phone: string;
  city: string;
  postCode: number;
  streetAddress: string;
  contact: string;
}

export interface Share {
  id: string;
  amount: number;
  shareClass: string;
}

export interface IndirectlyOwnedShares {
  company: Company;
  shareholders: Shareholder[];
  shares: number;
}

export interface User {
  email: string;
  id: string;
  name: string;
  managedCompanies: Company[];
  companies: Company[]
  shareholders: Shareholder[];
}

export interface UserSignUpPayload {
  authenticatable: User;
  credentials: Credential;
}

export interface SignedInUser {
  signedInUser: User
}

export interface UserAccountSettingErrors {
  name: string,
  email: string,
}

export interface RouteAccess {
  path: string,
  allowed: string
}

export interface RouteAccessResult {
  checkAccess: RouteAccess[]
}

export interface CheckAccessVar {
  paths: string[]
}
