import React, { useLayoutEffect } from 'react';
import { primaryContainer } from '../../../utils/constants';
import { CompanyDetailsForm } from '../../../components/Auth/company';
import { useHistory } from 'react-router-dom';
import { useUrlBuilder } from '../../../hooks/urlBuilder'

const CompanyDetails = (props: any): JSX.Element => {
  const history = useHistory();
  const { buildAdminUrl } = useUrlBuilder()

  const {
    location: { state },
  }: any = history;

  useLayoutEffect(() => {
    if (!state || !state?.companyDetails) history.push(buildAdminUrl('/find-company', true));
  }, [state]);

  return (
    <section className="h-full">
      <div className="bg-eighth-color h-auto pt-0 lg:pt-16">
        <div className={`${primaryContainer} h-full py-16 lg:py-24 2xl:py-32`}>
          {state?.companyDetails && (
            <CompanyDetailsForm data={state?.companyDetails} />
          )}
        </div>
      </div>
    </section>
  );
};

export default CompanyDetails;
