import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
query BrregCompanies($search: String!) {
    searchBrregCompanies(search: $search) {
      name
      orgnr
      registrationDate
      taken
      streetAddress
      city
      postCode
    }
  }
`;

export const CREATE_COMPANY = gql`
mutation CreateCompany(
  $name: String!
  $color:String
  $orgnr: String!
  $streetAddress:String
  $postCode: String
  $city:String
  $registrationDate:String
    ) {
      createCompany(
        name: $name
        orgnr:$orgnr
        streetAddress:$streetAddress
        postCode:$postCode
        city:$city
        registrationDate:$registrationDate
        color:$color
      ) {
        id
        canInitialize
        isEmpty
      }
    }
`;

export const GET_MANAGED_COMPANY_SHAREHOLDERS = gql`
  query ManagedCompany(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    managedCompany(id: $companyId) {
      shareClasses
      totalShares
      shareholders(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          email
          name
          phone
          city
          streetAddress
          postCode
          bankAccount
          shareholderType
          contact
          shares {
            id
            amount
            shareClass
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_OWNED_COMPANY_SHAREHOLDERS = gql`
  query OwnedCompany(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    ownedCompany(id: $companyId) {
      shareClasses
      totalShares
      shareholders(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          email
          name
          bankAccount
          shareholderType
          contact
          phone
          shares {
            id
            amount
            shareClass
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_SUBSCRIBED_COMPANY_SHAREHOLDERS = gql`
  query SubscribedCompany(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    subscribedCompany(id: $companyId) {
      shareClasses
      totalShares
      shareholders(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          email
          name
          bankAccount
          shareholderType
          phone
          contact
          shares {
            id
            amount
            shareClass
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_MANAGED_COMPANY_TRANSACTIONS = gql`
  query ManagedCompany(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    managedCompany(id: $companyId) {
      transactions(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          pricePerShare
          sharePremium
          transactionAt
          transactionType
          fromShareholder {
            id
            name
            type
          }
          toShareholder {
            id
            name
            type
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_OWNED_COMPANY_TRANSACTIONS = gql`
  query OwnedCompany(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    ownedCompany(id: $companyId) {
      transactions(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          pricePerShare
          sharePremium
          transactionAt
          transactionType
          fromShareholder {
            id
            name
            type
          }
          toShareholder {
            id
            name
            type
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_SUBSCRIBED_COMPANY_TRANSACTIONS = gql`
  query SubscribedCompany(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    subscribedCompany(id: $companyId) {
      transactions(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          pricePerShare
          sharePremium
          transactionAt
          transactionType
          fromShareholder {
            id
            name
            type
          }
          toShareholder {
            id
            name
            type
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_SUBSCRIBERS = gql`
query ManagedCompany($companyId: ID!, $page: Int, $limit: Int, $sort: String, $search: String) {
  managedCompany(id: $companyId){
    subscribers(page: $page, limit: $limit sort: $sort, search: $search) {
      collection {
        id
        name
        email
        phone
        toShareholder {
          id
          name
          type
        }
      }
      metadata {
        totalPages
        totalCount
        currentPage
        limitValue
      }
    }
  }
}
`;

export const GET_SELECTED_COMPANY = gql`
query GetSelectedCompany {
  selectedCompany @client {
    id
    name
    registrationDate
  }
}
`;

export const GET_SUBSCRIBED_COMPANY_DETAIL = gql`
query getSubscribedCompanyDetail ($id: ID!) {
  subscribedCompany (id: $id){
    id
    color
    name
    orgnr
    registrationDate
    streetAddress
    postCode
    city
  }
}
`;

export const GET_MANAGED_COMPANY_DETAIL = gql`
query getManagedCompanyDetail($id: ID!) {
  managedCompany(id: $id){
    id
    color
    name
    orgnr
    registrationDate
    streetAddress
    postCode
    city
    canInitialize
    isEmpty
    bankName
    bankAddress
    accountNumber
    bicCode
  }
}
`;

export const GET_OWNED_COMPANY_DETAIL = gql`
query getOwnedCompanyDetail($id: ID!) {
  ownedCompany(id: $id){
    id
    color
    name
    orgnr
    registrationDate
    streetAddress
    postCode
    city
  }
}
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $companyId: String!
    $name: String!
    $streetAddress: String
    $postCode: String
    $city: String
    $color: String!
    $bankName: String
    $accountNumber: String
    $bankAddress: String
    $bicCode: String
  ) {
    editCompany(
      companyId: $companyId
      name: $name
      streetAddress: $streetAddress
      postCode: $postCode
      city: $city
      color: $color
      bankName: $bankName
      accountNumber: $accountNumber
      bankAddress: $bankAddress
      bicCode: $bicCode
    ) {
      id
      name
      streetAddress
      postCode
      city
      color
      bankName
      accountNumber
      bankAddress
      bicCode
    }
  }
`;

export const GET_MANAGED_COMPANY_FUNDING_EVENTS = gql`
  query ManagedCompanyFundingEvents(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    managedCompany(id: $companyId) {
      fundingEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          capitalPerShare
          createdAt
          name
          pricePerShare
          shareClasses
          sharePremium
          eventOn
          publicTransitionEvents {
            transition
            translation
          }
          status {
            background
            label
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_OWNED_COMPANY_FUNDING_EVENTS = gql`
  query OwnedCompanyFundingEvents(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    ownedCompany(id: $companyId) {
      fundingEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          capitalPerShare
          createdAt
          name
          pricePerShare
          shareClasses
          sharePremium
          eventOn
          publicTransitionEvents {
            transition
            translation
          }
          status {
            background
            label
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_SUBSCRIBED_COMPANY_FUNDING_EVENTS = gql`
  query SubscribedCompanyFundingEvents(
    $companyId: ID!
    $page: Int
    $limit: Int
    $sort: String
    $search: String
  ) {
    subscribedCompany(id: $companyId) {
      fundingEvents(page: $page, limit: $limit, sort: $sort, search: $search) {
        collection {
          id
          amount
          capitalPerShare
          createdAt
          name
          pricePerShare
          shareClasses
          sharePremium
          eventOn
          publicTransitionEvents {
            transition
            translation
          }
          status {
            background
            label
          }
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }
  }
`;

export const GET_SINGLE_FUNDING_EVENT = gql`
query FundingEvent($id: ID!){
  fundingEvent(id: $id){
    amount
    name
    description
    type
    boardMeeting{
      id
      city
      description
      name
      offline
      online
      postCode
      status
      streetAddress
      videoconferenceLink
      createdAt
      meetingAt
      boardMeetingAgendas{
        id
        name
        position
        description
        createdAt
      }
    }
    publicTransitionEvents{
      transition
      translation
    }
    status{
      background
      label
    }
    capitalPerShare
    createdAt
    eventOn
    id
    pricePerShare
    shareClasses
    sharePremium
    payableBy
    reparation
    referenceText
  }
}
`;

export const IMPORT_COMBINED_DATA = gql`
mutation importCombined($file: Upload!, $companyId: ID!) {
  importCombined(file: $file, companyId: $companyId) {
    id
  }
}
`;

export const CLEANUP_MANAGED_COMPANY = gql`
  mutation cleanupCompany($companyId: ID!) {
    cleanUpManagedCompany(companyId: $companyId){
      id
      isEmpty
      canInitialize
    }
  }
`;

export const INITIALIZE_MANAGED_COMPANY_FROM_REGISTRY = gql`
mutation initializeManagedCompanyFromRegistry($companyId: ID!) {
  initializeManagedCompanyFromRegistry(companyId: $companyId) {
    id
  }
}
`;

