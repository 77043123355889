import React from 'react';
import { homeMenuItems } from '../../../utils/constants';
import CloseIllustration from '../../../assets/close.svg';
import { MobileNavWrapper } from './styles';
import { useHistory } from 'react-router-dom';

interface MobileNavProps {
  openMobileNav: boolean;
  closeMobileNav: () => void;
  onClickLogin: () => void
}

const MobileNav = (props: MobileNavProps): JSX.Element => {
  const onClickLogin = () => {
    props.closeMobileNav()
    props.onClickLogin()
  }

  return (
    <MobileNavWrapper
      openMobileNav={props.openMobileNav}
      className="w-screen fixed bg-first-btn-color top-0 transition duration-1000"
    >
      <div className="p-4">
        <div className="flex flex-row-reverse w-screen pr-6">
          <img
            src={CloseIllustration}
            onClick={props.closeMobileNav}
            alt="close-btn"
            id="close-btn"
          />
        </div>
      </div>
      <div className="flex flex-col items-center space-y-10 pt-12 justify-around">
        {homeMenuItems.map((menuItem: {label: string, url:string}, index) => (
          <a key={index} href={menuItem.url}>
            <div
              className="text-4xl not-italic font-bold leading-8 tracking-normal font-hind text-first-card-box-color"
              onClick={ () => props.closeMobileNav()}
            >
              <span>{menuItem.label}</span>
            </div>
          </a>
        ))}
        <div
          className="text-4xl not-italic font-bold leading-8 tracking-normal font-hind text-first-card-box-color"
          onClick={onClickLogin}
        >
          <span>Login</span>
        </div>
      </div>
    </MobileNavWrapper>
  );
};

export default MobileNav;
