import { gql } from '@apollo/client';

export const UPDATE_SHAREHOLDER = gql`
  mutation updateSubscriber($attributes: ShareholderInput!) {
    updateShareholder(attributes: $attributes) {
      name
      email
      phone
      bankAccount
      streetAddress
      postCode
      city
      contact
    }
  }
`;
