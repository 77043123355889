import { ShareIssuanceEventTypes } from './constants'

export const toReadableEventStatus = (status: string): string => {
  switch (status) {
  case ShareIssuanceEventTypes.FoundingEvent:
    return 'Founding Event'
  case ShareIssuanceEventTypes.FundingEvent:
    return 'Funding Event'
  default:
    return '-'
  }
}
