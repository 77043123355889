import styled from 'styled-components';

interface MobileNavProps {
    openMobileNav: boolean;
}

export const MobileNavWrapper = styled.div`${(props: MobileNavProps) => `
height: ${props.openMobileNav ? '100%' : '0'};
z-index: ${props.openMobileNav ? '11' : '-1'};
`}
`;
