import React, { useLayoutEffect } from 'react';
import { ConfirmTransferTransaction } from '../../components/transaction/confirm/transfer';
import { useHistory } from 'react-router-dom'
import { ConfirmSplitTransaction } from '../../components/transaction/confirm/split';
import { ConfirmConvertTransaction } from '../../components/transaction/confirm/convert';
import { useUrlBuilder } from '../../hooks/urlBuilder'

const ConfirmTransaction = (): JSX.Element => {
  const history = useHistory();
  const { buildAdminUrl } = useUrlBuilder()

  const {
    location: { state }
  }: any = history;

  useLayoutEffect(() => {
    if (!state || !state?.formData) history.push(buildAdminUrl('/create-transaction'));
  }, [state]);

  const onClickBack = (formData: any, apiErrorsMessages: any) => {
    history.push({
      pathname: buildAdminUrl('/create-transaction'),
      state: {formData: formData, apiErrorsMessages: apiErrorsMessages }
    })
  }

  const loadContent = () => {
    const { transactionType } = state?.formData
    switch(transactionType) {
    case 'Aksjetransaksjon':
      return <ConfirmTransferTransaction
        formData={state.formData}
        onClickBack={onClickBack} />
    case 'Splitt':
      return <ConfirmSplitTransaction
        formData={state.formData}
        onClickBack={onClickBack} />
    case 'Konvertering':
      return <ConfirmConvertTransaction
        formData={state.formData}
        onClickBack={onClickBack} />
    }
  }

  return (
    <div className="p-8" >
      <h1 className="ml-4 text-left text-4xl">Bekreft transaksjonen</h1>
      { state?.formData && loadContent() }
    </div>
  );
};

export default ConfirmTransaction;
