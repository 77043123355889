import { TextInput } from '../../components/textInput';
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { CompanySettingErrors, CompanyVar } from '../../model/company';
import { Company } from '../../model/user';
import { ManagedCompany } from '../../model/company';
import {
  ApolloError,
  useMutation,
  useQuery,
  useLazyQuery,
} from '@apollo/client';
import { getFormData } from '../../utils/form';
import {
  GET_SELECTED_COMPANY,
  GET_MANAGED_COMPANY_DETAIL,
  UPDATE_COMPANY,
  CLEANUP_MANAGED_COMPANY,
} from '../../services/company';
import {
  DATE_TIME_FORMAT,
  updateCompanyErrorMessages,
} from '../../utils/constants';
import { SketchPicker } from 'react-color';
import { DATE_FORMAT, dashboardContainer } from '../../utils/constants';
import moment from 'moment';
import { ApiValidationError } from '../../model/common';
import { useUrlBuilder } from '../../hooks/urlBuilder';
import { Button } from '../../components/button';
import { useNotifications } from '../../hooks/notification';
import { NOTIFICATION } from '../../context/notificationContext';
import { Modal } from '../../components/modal';
import { setShowSetupCompany } from '../../store/cache';
import { useHistory } from 'react-router';

const CompanySettings = (): JSX.Element => {
  const history = useHistory()
  const { setNotification } = useNotifications();
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(
    {} as CompanySettingErrors,
  );
  const [selectedColor, setSelectedColor] = useState('');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [
    cleanupCompanyModalVisibility,
    setCleanupCompanyModalVisibility,
  ] = useState(false);
  const [isCleanupButtonActive, setCleanupButtonStatus] = useState(false);

  const [companyDetail, setCompanyDetail] = useState({} as Company);
  const { selectedCompanyId, buildAdminUrl } = useUrlBuilder();

  const [getCompanyDetail] = useLazyQuery<ManagedCompany, CompanyVar>(
    GET_MANAGED_COMPANY_DETAIL,
    {
      onCompleted: (manageCompanyDetail) => {
        setCompanyDetail(manageCompanyDetail.managedCompany);
        setSelectedColor(manageCompanyDetail.managedCompany.color);
      },
      onError: () => setCompanyDetail({} as Company),
    },
  );

  useEffect(() => {
    if (selectedCompanyId) {
      getCompanyDetail({
        variables: {
          id: selectedCompanyId,
        },
      });
    }
  }, [getCompanyDetail, selectedCompanyId, companyDetail]);

  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const [cleanupManagedCompany] = useMutation(CLEANUP_MANAGED_COMPANY);

  const cleanupCompany = () => {
    cleanupManagedCompany({
      variables: {
        companyId: selectedCompanyId,
      },
    })
      .then((res) => {
        setNotification({
          title: 'Success',
          message: 'Aksjeboken ble fjernet',
          type: NOTIFICATION.SUCCESS,
        });
        setCleanupCompanyModalVisibility(false)
        setShowSetupCompany(true)
        history.push(buildAdminUrl("/dashboard"))
      })
      .catch((err) => {
        setNotification({
          title: 'Error',
          message: 'Vi fikk ikke til det der, prøv igjen',
          type: NOTIFICATION.ERROR,
        });
      });
  };

  const onSaveChanges = (event: any) => {
    event.preventDefault();
    const formData = getFormData(event.target.elements);

    const { name, streetAddress, postCode, city, color, bankName, bankAddress, accountNumber, bicCode } = formData;
    if (checkValidation(name, color, postCode)) {
      setLoading(true);
      setErrorMessage('');
      updateCompany({
        variables: {
          companyId: companyDetail.id,
          name,
          streetAddress,
          postCode,
          city,
          color,
          bankName,
          bankAddress,
          accountNumber,
          bicCode
        },
      })
        .then((res) => {
          setLoading(false);
          setCompanyDetail(res.data.editCompany);
        })
        .catch((err: ApolloError) => {
          setLoading(false);
          const apiErrorMessages: ApiValidationError[] = err.graphQLErrors.map(
            (error) => {
              const path = error.path;
              return {
                fieldName: path ? path[1].toString() : '',
                message: error.message,
              };
            },
          );

          const apiValidationErrors: CompanySettingErrors = validationError;

          if (apiErrorMessages) {
            apiErrorMessages.forEach((error: ApiValidationError) => {
              (apiValidationErrors as any)[error.fieldName] = error.message;
            });
            setValidationError({ ...apiValidationErrors });
          }
        });
    }
  };

  const checkValidation = (name: string, color: string, postCode: string) => {
    let response = true;
    const errors: CompanySettingErrors = {
      name: '',
      orgnr: '',
      registrationDate: '',
      streetAddress: '',
      postCode: '',
      city: '',
      color: '',
    };

    if (!name) {
      response = false;
      errors.name = updateCompanyErrorMessages.NAME_REQUIRED;
    }

    if (!color) {
      response = false;
      errors.color = updateCompanyErrorMessages.COLOR_REQUIRED;
    }

    // post code is 4 digits long and have a range from 0001 to 9999
    if ((postCode && postCode.length !== 4) || postCode === '0000') {
      response = false;
      errors.postCode = updateCompanyErrorMessages.INVALID_POST_CODE;
    }

    setValidationError(errors);
    return response;
  };

  return (
    <div className={dashboardContainer}>
      <Modal
        visible={cleanupCompanyModalVisibility}
        onClose={() => setCleanupCompanyModalVisibility(false)}
        size="lg"
      >
        <div>
          <div className="flex justify-between items-center border-gray-200 border-b-1 mb-6">
            <div>
              <h2 className="font-hind font-bold text-2xl">
                Er du helt sikker?
              </h2>
            </div>
            <button
              type="button"
              className="focus:outline-none"
              onClick={() => setCleanupCompanyModalVisibility(false)}
            >
              <i className="uc-icon text-2xl text-low-gray-color">&#xeb8e;</i>
            </button>
          </div>

          <p>
            Dette er permanent! Vi sletter alle aksjonærer, transaksjoner og emisjoner fra selskapet.
          </p>
          <p className="my-2">
            Skriv inn <span className="font-bold">{companyDetail.name}</span>{' '}
            for å bekrefte
          </p>
          <input
            className="w-full border-1 border-gray-200 outline-none rounded-xl px-4 py-2"
            onChange={(e) => {
              e.target.value === companyDetail.name
                ? setCleanupButtonStatus(true)
                : setCleanupButtonStatus(false);
            }}
          />
          <button
            disabled={!isCleanupButtonActive}
            className={`mt-6 w-full outline-none rounded-xl py-2 ${
              isCleanupButtonActive
                ? 'bg-orange-light text-white font-bold'
                : 'border-gray-200 bg-gray-100'
            } `}
            onClick={() => cleanupCompany()}
          >
            {submitting ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#4B2A58"
              />
            ) : (
              <p>Confirm</p>
            )}
          </button>
        </div>
      </Modal>
      <h1 className="ml-4 text-left text-4xl">{companyDetail?.name}</h1>
      <form onSubmit={onSaveChanges}>
        <div className="grid grid-cols-2 gap-2.5">
          <TextInput
            label={'Selskapsnavn'}
            name="name"
            type="text"
            errorMessage={validationError.name}
            defaultValue={companyDetail?.name}
            placeholder={'Selskapsnavn'}
          />
          <TextInput
            label={'Registreringsdato'}
            name="registrationDate"
            type="text"
            defaultValue={
              companyDetail?.registrationDate
                ? moment(companyDetail?.registrationDate).format(DATE_FORMAT)
                : ''
            }
            disabled={true}
            errorMessage={validationError.registrationDate}
            placeholder={'Registreringsdato'}
          />
        </div>
        <TextInput
          label={'Organisasjonsnummer'}
          name="orgnr"
          type="number"
          errorMessage={validationError.orgnr}
          defaultValue={companyDetail?.orgnr}
          placeholder={'Organisasjonsnummer'}
          disabled={true}
        />
        <TextInput
          label={'Gatenavn og nummer'}
          name="streetAddress"
          type="text"
          errorMessage={validationError.streetAddress}
          defaultValue={companyDetail?.streetAddress}
          placeholder={'Gatenavn og nummer'}
        />
        <div className="grid grid-cols-2 gap-2.5">
          <TextInput
            label={'Postkode'}
            name="postCode"
            type="number"
            errorMessage={validationError.postCode}
            defaultValue={companyDetail?.postCode}
            placeholder={'Postkode'}
          />
          <TextInput
            label={'Sted'}
            name="city"
            type="text"
            errorMessage={validationError.city}
            defaultValue={companyDetail?.city}
            placeholder={'Sted'}
          />
        </div>
        <div className="grid grid-cols-2 gap-2.5">
          <TextInput
            label={'Bank'}
            name="bankName"
            type="text"
            errorMessage={validationError.bankName}
            defaultValue={companyDetail?.bankName}
            placeholder={'Bank'}
          />
          <TextInput
            label={'Bankadresse'}
            name="bankAddress"
            type="text"
            errorMessage={validationError.bankAddress}
            defaultValue={companyDetail?.bankAddress}
            placeholder={'Bankadresse'}
          />
        </div>
        <div className="grid grid-cols-2 gap-2.5">
          <TextInput
            label={'Kontonummer'}
            name="accountNumber"
            type="text"
            errorMessage={validationError.accountNumber}
            defaultValue={companyDetail?.accountNumber}
            placeholder={'Kontonummer'}
          />
          <TextInput
            label={'BIC kode'}
            name="bicCode"
            type="text"
            errorMessage={validationError.bicCode}
            defaultValue={companyDetail?.bicCode}
            placeholder={'BIC kode'}
          />
        </div>
        <div className="relative color-input-container">
          <div
            onClick={() => {
              setDisplayColorPicker(true);
            }}
          >
            <TextInput
              label={'Farge'}
              name="color"
              type="text"
              errorMessage={validationError.color}
              value={selectedColor}
              placeholder={selectedColor ? selectedColor : 'Farge'}
              disabled
            />
          </div>
          {displayColorPicker ? (
            <div className="absolute z-5 bottom-full">
              <div
                className="fixed inset-0"
                onClick={() => {
                  setDisplayColorPicker(false);
                }}
              />
              <SketchPicker
                onChange={(color) => {
                  setSelectedColor(color.hex);
                }}
                color={selectedColor ?? '#fff'}
              />
            </div>
          ) : null}
        </div>
        <div className="form-group justify-center mt-8">
          {errorMessage && <p className="text-error mb-2">{errorMessage}</p>}
          <button
            className="btn btn-primary btn-block outline-none bg-orange-light"
            type="submit"
            id="login-btn"
          >
            {submitting ? (
              <ReactLoading
                type="bars"
                height={'30px'}
                width={'30px'}
                color="#4B2A58"
              />
            ) : (
              <p className="text-white">Lagre endringer</p>
            )}
          </button>
        </div>
      </form>
      <div className="mt-5">
        <span className="text-2xl">Farlig sone</span>
        <div className="border-1 border-red-500 p-6 rounded-2xl">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-bold">Reset aksjeboken</p>
              <p>
                Når vi har reset aksjeboken er det ingen vei tilbake.
                Vi sletter alt permanent. Du må være sikker før du gjennomfører.
              </p>
            </div>
            <Button
              type="button-2"
              toExecute={() => {
                setCleanupCompanyModalVisibility(true);
              }}
              label="Cleanup"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySettings;
