/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { TableBodyPropGetter, TableBodyProps, Row } from 'react-table';

interface TableBody {
  data: Array<Record<string, string | any>>;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<Record<string, any>> | undefined,
  ) => TableBodyProps;
  page: Row<Record<string, any>>[];
  prepareRow: (row: Row<Record<string, any>>) => void;
  presetStyle?: string;
}

const TableBody = ({
  data,
  getTableBodyProps,
  page,
  prepareRow,
  presetStyle,
}: TableBody): JSX.Element => {
  return data && data.length !== 0 ? (
    <tbody
      {...getTableBodyProps()}
      className="font-roboto text-fourth-text-color text-left"
    >
      {page.map((row, i) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            className="rounded-2xl even:bg-table-row-color rounded-2xl"
            key={i}
          >
            {row.cells.map((cell, cellIndex) => {
              return (
                // @ts-ignore
                !cell.column.isHidden && (
                  <td
                    // @ts-ignore
                    // This line was ignored because it is throwing 'key' is specified more than once, so this usage will be overwritten.ts(2783)
                    // An issue found to be part of typescript
                    key={cellIndex}
                    className={`defaultRowStyle ${presetStyle} ${cell.column.id}Column`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              );
            })}
          </tr>
        );
      })}
    </tbody>
  ) : (
    <></>
  );
};

export default TableBody;
