/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, useEffect, useState } from 'react';
import {
  useTable,
  Column,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from 'react-table';
import CarretArrow from '../../assets/icons/carret.svg';
import TablePagination from './pagination';
import IconSearch from '../../assets/icons/icon-search-2.svg';
import _ from 'lodash';
import { EmptyIcon } from '../icons';
import TableHeader from './header';
import TableBody from './tableBody';
import '../../styles/table.scss';
import MobileTable from './mobile';

interface TableComponentProps {
  columns: Column<Record<string, string>>[];
  data: Array<Record<string, string | any>>;
  searchable?: boolean;
  paginate?: boolean;
  initialPageSize?: number;
  tableHeader?: boolean;
  presetStyle?: string;
}

const TableComponent = ({
  columns,
  data,
  searchable,
  paginate,
  initialPageSize,
  tableHeader,
  presetStyle,
}: TableComponentProps): JSX.Element => {
  const [toUseColumns, setToUseColumns] = useState(
    [] as Column<Record<string, string>>[],
  );

  const DEFAULT_INITIAL_PAGE_SIZE = 5;

  useEffect(() => {
    let toUseColumns = columns;
    if (searchable) {
      toUseColumns = _.unionBy(
        columns,
        [
          {
            Header: <img src={IconSearch} className="absolute top-4 right-4 hidden lg:block" />,
            accessor: 'search',
            width: 50,
            maxWidth: 50,
          },
        ],
        'accessor',
      );
    }
    setToUseColumns(toUseColumns);
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    // filter
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns: useMemo(() => toUseColumns, [toUseColumns]),
      data: useMemo(() => data, [data]),
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize ?? DEFAULT_INITIAL_PAGE_SIZE,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const [filterValue, setFilterValue] = useState(globalFilter);

  return (
    <div className="w-full">
      <div className={`w-full ${tableHeader && 'hidden'} lg:block`}>
        <table {...getTableProps()} className="w-full">
          {tableHeader && (
            <TableHeader
              headerGroups={headerGroups}
              searchable={searchable}
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          )}

          <TableBody
            data={data}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            presetStyle={presetStyle}
          />
        </table>
      </div>
      <MobileTable
        headerGroups={headerGroups}
        tableHeader={tableHeader}
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        page={page}
        toUseColumns={toUseColumns}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        prepareRow={prepareRow}
      />

      {data.length === 0 && (
        <div className="w-full flex justify-center items-center mt-8 flex-col">
          <EmptyIcon />
          <div>
            <span className="font-roboto text-disabled">No Data</span>
          </div>
        </div>
      )}
      {paginate && data.length !== 0 && pageCount !== 0 && (
        <TablePagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          pageCount={pageCount}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
};

export default TableComponent;
