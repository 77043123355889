import React, { useState } from 'react';
import { useAsyncDebounce, Row } from 'react-table';

interface GlobalFilterProps {
  preGlobalFilteredRows: Row<Record<string, any>>[];
  globalFilter: string;
  setGlobalFilter: (globalFilter: string) => void;
  presetStyle: string;
  isMobile?: boolean;
  searchValue?: string;
  setSearchValue?: any;
  goToInitialPage: () => void;
}

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  presetStyle,
  isMobile = false,
  searchValue,
  setSearchValue,
  goToInitialPage,
}: GlobalFilterProps): JSX.Element => {
  return (
    <span>
      <input
        id="table-filter"
        value={searchValue || ''}
        onChange={(e) => {
          if (setSearchValue) {
            setSearchValue(e.target.value);
            goToInitialPage();
          }
        }}
        placeholder="type to search"
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
        className={`font-montserrat ${
          !isMobile && 'bg-seven-color absolute right-0 -bottom-10 focus:z-10'
        }  p-2 outline-none transition duration-700 text-base ${
          searchValue && searchValue.length === 0 && 'opacity-0'
        } focus:opacity-100 ${presetStyle}`}
      />
    </span>
  );
};

export default GlobalFilter;
